import React from "react";
import { RiSearchEyeLine } from "react-icons/ri";
function SearchBar(props) {
  const {
    label,
    name,
    placeholder,
    maxLength,
    disabled,
    type,
    className,
    onChange,
    value,
  } = props;

  return (
    <>
      <div className={`relative w-full mb-[.5rem]`}>
        {label && (
          <label
            htmlFor={name}
            className="text-[16px] font-[500] text-[#777777]"
          >
            {name}
          </label>
        )}
        <input
          id={name}
          className={`py-4  ps-[4rem] block text-[16px] border-[#00000086]  border  w-full rounded-[10px] outline-[#00000071] font-[700 `}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          type={type || "text"}
          onChange={onChange} // Pass onChange event handler
          value={value} // Pass input value
        />

        <div
          className={`absolute top-[.6rem] left-[1rem] opacity-70  w-fit h-fit`}
        >
          <button className="w-[40px] h-[40px] bg-primary rounded-[5px] flex justify-center items-center ">
            {" "}
            <RiSearchEyeLine size={24} color="white" />
          </button>
        </div>
      </div>
    </>
  );
}

export default SearchBar;
