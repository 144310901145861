import { Search } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const membersSlice = createSlice({
  name: "member",
  initialState: {},
  reducers: {
    getMemberSuccess: (state, action) => {
      state.getMember_success = action.payload;
      state.getMember_error = null;
    },
    getMemberFailure: (state, action) => {
      state.getMember_error = action.payload;
      state.getMember_success = null;
    },
    getDoctorScheduleSuccess: (state, action) => {
      state.getDoctorSchedule_success = action.payload[0];
      state.getDoctorSchedule_error = null;
    },
    getDoctorScheduleFailure: (state, action) => {
      state.getDoctorSchedule_error = action.payload[0];
      state.getDoctorSchedule_success = null;
    },
    AddMemberSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    AddMemberFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    SearchText: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
  },
});

export const {
  AddMemberSuccess,
  AddMemberFailure,
  getMemberSuccess,
  getMemberFailure
} = membersSlice.actions;

// Asynchronous thunk action for fetching member data
export const getMembers = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`family-members?verified=${params.is_verified}&name=${params.name}`);
    dispatch(getMemberSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getMemberFailure(errorMessage));
    throw errorMessage;
  }
};

export const getMember = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`family-members/${params}`);
    dispatch(getMemberSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getMemberFailure(errorMessage));
    throw errorMessage;
  }
};

export const UpdateMember = (params) => async (dispatch) => {
  try {
    const response = await axios.put(`family-members/${params.id}`, params);

    dispatch(AddMemberSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(AddMemberFailure(errorMessage));
    throw errorMessage;
  }
};


export const DeleteMember = (params) => async (dispatch) => {
  try {
    const response = await axios.delete(`family-members/${params.id}`, params);

    return response.data
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    // dispatch(AddMemberFailure(errorMessage));
    throw errorMessage;
  }
};



// Asynchronous thunk action for adding 
export const AddMember = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`family-members`, params);

    dispatch(AddMemberSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(AddMemberFailure(errorMessage));
    throw errorMessage;
  }
};




// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = membersSlice.name;

export default membersSlice.reducer;
