import { TextField } from "@mui/material";
import React, { useState, useRef } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { FiSend } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";

function ChatSendDiv({ onSave, addAudioElement, handleFileSelected }) {
  const [newChat, setNewChat] = useState("");
  const recorderControls = useAudioRecorder();
  const fileInputRef = useRef(null);

  const sendMessage = () => {
    onSave(newChat);

    setNewChat(""); // Clear the input after sending
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelected(file); // Pass the selected file to the handler
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click(); // Programmatically trigger a click on the hidden input
  };

  const imageTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
  const videoTypes = ['mp4', 'wmv', 'avi', 'rmvb', 'mkv'];
  const documentTypes = ['zip', 'txt', 'doc', 'pdf'];
  const audioTypes = ['mp3', 'amr', 'wmv', 'mpeg'];

  // Combine all file types into a single list
  const allowedFileTypes = [
    ...imageTypes.map(type => `.${type}`),
    ...videoTypes.map(type => `.${type}`),
    ...documentTypes.map(type => `.${type}`),
    ...audioTypes.map(type => `.${type}`),
  ].join(',');

  return (
    <div className="fixed w-full bottom-0 flex justify-center items-center gap-3 px-[1rem] z-[99] bg-white pt-2 pb-2">
      <div
        className="flex items-center gap-4  h-[50px]  rounded-full shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] bg-white"
        style={{
          transition: "width 0.3s ease", // Smooth transition effect
          width: recorderControls.isRecording ? "0px" : "100%", // Adjust width based on recording state
          padding: recorderControls.isRecording ? "0px" : "0px 16px",
          overflow: recorderControls.isRecording ? "hidden" : "visible" // Ensure content is hidden when width is 0
        }}
      >
        <TextField
          fullWidth
          value={newChat || ""}
          onChange={(e) => setNewChat(e.target.value)}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          placeholder="Type message..."
          style={{ display: recorderControls.isRecording ? "none" : "block" }} // Hide input when recording
        />

        {/* Hidden file input for multiple file types */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept={allowedFileTypes} // Dynamically constructed list of accepted types
          onChange={handleFileChange}
        />
        <button
          className="text-[22px] text-[#777777]"
          onClick={handleAttachmentClick}
          style={{ display: recorderControls.isRecording ? "none" : "block" }} // Hide attachment button when recording
        >
          <GrAttachment />
        </button>


      </div>


      <div className="min-w-[40px]">
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          recorderControls={recorderControls}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          onNotAllowedOrFound={(err) => console.table(err)}
          downloadFileExtension="webm"
          mediaRecorderOptions={{
            audioBitsPerSecond: 128000,
          }}
          showVisualizer={true}

        />
      </div>
      <div className="" style={{ display: recorderControls.isRecording ? "none" : "block" }}>
        <button
          onClick={() => sendMessage()}
          className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-primary"
        >
          <FiSend color="white" size={20} />
        </button>
      </div>

    </div>
  );
}

export default ChatSendDiv;
