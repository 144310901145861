import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { hideToast } from 'pages/Appointments/BookAppointment/store/bookAppointmentSlice';
import { Alert, Slide } from '@mui/material';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}
export default function ToastMessage() {
    const dispatch = useDispatch();

    const { toastOpen, toastMessage } = useSelector((state) => state.bookappointment.bookappointmentSlice);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(hideToast());
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={toastOpen}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleClose}

            TransitionComponent={SlideTransition}

            action={action}
        >
            <Alert
                severity={toastMessage?.type}
                onClose={handleClose}
                variant="filled">
                {toastMessage?.message}
            </Alert>
        </Snackbar>
    );
}
