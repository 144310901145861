import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk actions for fetching appointment data
export const getAppointments = createAsyncThunk(
  "appointment/getAppointments",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `medical-appointments?appointment_type=${params.appointment_type}&name=${params.name}`
      );
      return response.data; // Assumes response.data = { todays_appointments, Upcoming_appointments, completed_appointments }
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getPrescribedMedicines = createAsyncThunk(
  "appointment/getPrescribedMedicines",
  async ({ appointment_id, prescription_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `medical-prescription-medications/${appointment_id}/${prescription_id}`
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getAppointment = createAsyncThunk(
  "appointment/getAppointment",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`medical-appointments/${id}`);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getUpcomingAppointment = createAsyncThunk(
  "appointment/getUpcomingAppointment",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`patient-dashboard`);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

// Slice definition
export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    todays_appointments: [],
    upcoming_appointments: [],
    completed_appointments: [],
    prescribed_medicines: [],
    loading: false,
    error: null,
    appointment: null,
  },
  reducers: {
    resetAppointmentValues: (state) => {
      state.appointment = null;
      state.loading = false;
      state.error = null;
      state.todays_appointments = [];
      state.upcoming_appointments = [];
      state.completed_appointments = [];
      state.prescribed_medicines = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle getAppointments
      .addCase(getAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.todays_appointments = action.payload.todays;
        state.upcoming_appointments = action.payload.upcoming;
        state.completed_appointments = action.payload.completed;
        state.loading = false;
      })
      .addCase(getAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle getPrescribedMedicines
      .addCase(getPrescribedMedicines.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPrescribedMedicines.fulfilled, (state, action) => {
        state.loading = false;
        state.prescribed_medicines = action.payload;
      })
      .addCase(getPrescribedMedicines.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle getAppointment
      .addCase(getAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointment = action.payload; // Assuming this only fetches one appointment
      })
      .addCase(getAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Selectors
export const selectTodaysAppointments = (state) =>
  state.appointment.appointmentSlice.todays_appointments;
export const selectUpcomingAppointments = (state) =>
  state.appointment.appointmentSlice.upcoming_appointments;
export const selectCompletedAppointments = (state) =>
  state.appointment.appointmentSlice.completed_appointments;
export const selectPrescribedMedicines = (state) =>
  state.appointment.appointmentSlice.prescribed_medicines;
export const selectAppointmentLoading = (state) => state.appointment.appointmentSlice.loading;
export const selectError = (state) => state.appointment.appointmentSlice.error;
export const selectAppointment = (state) => state.appointment.appointmentSlice.appointment;

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = appointmentSlice.name;
export const { resetAppointmentValues } = appointmentSlice.actions;

export default appointmentSlice.reducer;
