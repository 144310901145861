import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePayment from "../StripePayment/StripePayment";

const stripePromise = loadStripe('pk_test_51PoG4nFxaZUa1Cs0CO7Vo14PZVWP7mYjKXJyciFOE3uvjUwZBZlfZP5Hc5ehRAgIngEvmHNOvWLTvkp4bcsCsE6y006zyTVNMi');



export default function Payment(props) {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment {...props} />
    </Elements>
  );
}
