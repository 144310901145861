import React, { useEffect, useState } from "react";

import { GoChevronLeft } from "react-icons/go";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { FaMinus, FaPlus } from "react-icons/fa";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import { showToast } from "pages/Appointments/BookAppointment/store/bookAppointmentSlice";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import InputField from "@medoczen/core/InputField/InputField";

// AddMedicineReminder is a component to add a medicine reminder
function AddMedicineReminder({
  dialogState,
  onClose,
  handleAddMedicine,
  handleUpdateDrug,
  handleRemoveDrug,
}) {
  const { open, edit, data } = dialogState;

  // State variables for the component
  const [TabCount, setTabCount] = useState(1);
  const [weekCount, setWeekCount] = useState(1);
  const [foodTiming, setFoodTiming] = useState("");
  const [repeat, setRepeat] = useState("");
  const [timeofday, setTimeOfDay] = useState([]);
  const [medicationName, setMedicationName] = useState("");

  // useEffect hook to set the state variables when the component is mounted or when the data prop changes
  useEffect(() => {
    if (edit && data) {
      setTabCount(Number(data?.dosage?.charAt(0)) || 1);
      setWeekCount(Number(data?.duration?.charAt(0)) || 1);
      setFoodTiming(data?.to_be_taken || "");
      setRepeat(data?.frequency || "");

      setMedicationName(data?.name || data?.medication_name || "");
    } else {
      // Reset medicationName when not editing
      setMedicationName("");
    }
  }, [data, edit]);

  // Function to handle the change in repeat state variable
  const handleRepeatChange = (value) => {
    setRepeat(value);
  };

  // Function to handle the change in foodTiming state variable
  const handleFoodTimingChange = (value) => {
    setFoodTiming(value);
  };

  // Function to handle the change in timeofday state variable
  const handleTimeOfDayChange = (value) => {
    if (timeofday.includes(value)) {
      setTimeOfDay(timeofday.filter((time) => time !== value));
    } else {
      setTimeOfDay((prev) => {
        let newTimeOfDay = [...prev, value];
        newTimeOfDay.sort((a, b) => {
          const order = ["Morning", "Noon", "Evening", "Night"];
          return order.indexOf(a) - order.indexOf(b);
        });
        return newTimeOfDay;
      });
    }
  };

  // Function to increment the TabCount state variable
  const IncTabNum = () => {
    setTabCount(TabCount + 1);
  };

  // Function to decrement the TabCount state variable
  const DecTabNum = () => {
    if (TabCount > 1) setTabCount(TabCount - 1);
    else {
      setTabCount(1);
    }
  };

  // Function to increment the weekCount state variable
  const IncWeekNum = () => {
    setWeekCount(weekCount + 1);
  };

  // Function to decrement the weekCount state variable
  const DecWeekNum = () => {
    if (weekCount > 1) setWeekCount(weekCount - 1);
    else {
      setWeekCount(1);
    }
  };

  // Function to handle the addition or update of a drug
  const HandleAddDrug = () => {
    if (!medicationName.trim()) {
      // Optionally, show a toast or error message if medication name is empty
      showToast("Please enter the medication name.", "error");
      return;
    }

    const body = {
      medication_name: medicationName.trim(),
      dosage: `${TabCount} ${TabCount === 1 ? "tablet" : "tablets"}`,
      duration: `${weekCount} ${weekCount === 1 ? "week" : "weeks"}`,
      time_of_day: timeofday,
      to_be_taken: foodTiming,
      frequency: repeat,
      item_id: edit ? data?.id : null,
    };

    if (edit) {
      handleUpdateDrug(body);
    } else {
      handleAddMedicine(body);
    }

    handleClose();
  };

  const handleClose = () => {
    onClose();
    setTabCount(1);
    setWeekCount(1);
    setFoodTiming("");
    setRepeat("");
    setTimeOfDay([]);
    setMedicationName("");
  };

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ padding: 0 }}>
        <>
          <HeaderWrapper title={edit ? "Edit Reminder" : "Add Medicine"} action={handleClose} />
          <div className=" ">
            <div className="relative w-[336px] text-wrap h-fit text-white text-[14px]  flex justify-start items-center">
              <span className="w-full text-justify  capitalize"></span>
            </div>
          </div>

          <div className="w-full px-[1.5rem] mb-[1rem] mt-[1rem]">
            {/* Medicine Name Input Field */}
            <div className="mb-4">
              <InputField
                value={medicationName}
                onChange={(e) => setMedicationName(e.target.value)}
                label="Medication Name"
                placeholder="Enter medicine name"
              />
            </div>

            {/* Dosage and Duration */}
            <div className="flex justify-between w-full ">
              <span className="text-[14px] font-[500] mb-3">Dosage</span>
              <span className="text-[14px] font-[500] mb-3">
                Duration <span className="text-primary">Week</span>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center  ">
                <button
                  onClick={DecTabNum}
                  className="w-[18px] h-[22px] flex justify-center items-center bg-[#D9D9D94D] rounded-full "
                >
                  <FaMinus color="#00000075" />
                </button>
                <span className="min-w-[50px] h-[13px] font-[500] text-[11px] text-center">
                  {" "}
                  {TabCount} {TabCount === 1 ? "Tablet" : "Tablets"}
                </span>
                <button
                  onClick={IncTabNum}
                  className="w-[18px] h-[22px] flex justify-center items-center bg-[#D9D9D94D] rounded-full "
                >
                  <FaPlus color="#00000075" />
                </button>
              </div>

              <div className="flex items-center gap-1 ">
                <button
                  onClick={DecWeekNum}
                  className="w-[18px] h-[22px] flex justify-center items-center bg-[#D9D9D94D] rounded-full "
                >
                  <FaMinus color="#00000075" />
                </button>
                <span className="min-w-[50px]  h-[13px] font-[500] text-[11px]">
                  {" "}
                  {weekCount} {weekCount === 1 ? "Week" : "Weeks"}
                </span>
                <button
                  onClick={IncWeekNum}
                  className="w-[18px] h-[22px] flex justify-center items-center bg-[#D9D9D94D] rounded-full "
                >
                  <FaPlus color="#00000075" />
                </button>
              </div>
            </div>
          </div>

          <div className="w-full px-[1.5rem] mb-[1rem] mt-[1rem]">
            <h1 className="text-[18px] font-semibold mb-3">Repeat</h1>
            <div className="grid grid-cols-3 gap-5">
              <button
                className={`w-[102px] h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  repeat === "Everyday" ? "bg-primary text-white" : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleRepeatChange("Everyday")}
              >
                Everyday
              </button>
              <button
                className={`w-[102px] h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  repeat === "Alternate Days"
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleRepeatChange("Alternate Days")}
              >
                Alternate days
              </button>
            </div>
          </div>

          <div className="w-full px-[1.5rem] mb-[1rem] mt-[1rem]">
            <h1 className="text-[18px] font-semibold mb-3">Time of the Day</h1>
            <div className="flex items-center justify-around">
              <button
                className={`w-fit px-3 h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  timeofday.includes("Morning")
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleTimeOfDayChange("Morning")}
              >
                Morning
              </button>
              <button
                className={`w-fit px-3 h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  timeofday.includes("Noon")
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleTimeOfDayChange("Noon")}
              >
                Noon
              </button>
              <button
                className={`w-fit px-3 h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  timeofday.includes("Evening")
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleTimeOfDayChange("Evening")}
              >
                Evening
              </button>
              <button
                className={`w-fit px-3 h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  timeofday.includes("Night")
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleTimeOfDayChange("Night")}
              >
                Night
              </button>
            </div>
          </div>

          <div className="w-full px-[1.5rem] mb-[1rem] mt-[1rem]">
            <h1 className="text-[18px] font-semibold mb-3">To be Taken</h1>
            <div className="flex  items-center justify-start gap-[1rem]">
              <button
                className={`w-[102px] h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  foodTiming === "After Food"
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleFoodTimingChange("After Food")}
              >
                After Food
              </button>
              <button
                className={`w-[102px] h-[32px] rounded-[5px] text-[12px] font-[400] ${
                  foodTiming === "Before Food"
                    ? "bg-primary text-white"
                    : "bg-[#F4F4F4] text-[#777777]"
                }`}
                onClick={() => handleFoodTimingChange("Before Food")}
              >
                Before Food
              </button>
            </div>
          </div>

          <div className="flex w-full justify-center mt-[2rem] mb-[1rem]">
            <PrimaryButton
              name={edit ? "Update Medicine" : "Add Medicine"}
              className="w-[244px] "
              fontSize="18px"
              active={
                repeat !== "" &&
                timeofday.length !== 0 &&
                foodTiming !== "" &&
                medicationName !== ""
              }
              disabled={
                repeat === "" ||
                timeofday.length === 0 ||
                foodTiming === "" ||
                medicationName === ""
              }
              action={HandleAddDrug}
            />
          </div>
          {edit && (
            <div className="flex w-full justify-center  mb-[1rem]">
              <PrimaryButton
                name="Remove"
                className="w-[244px] "
                fontSize="18px"
                action={() => handleRemoveDrug(data)}
              />
            </div>
          )}
        </>
      </DialogContent>
    </Dialog>
  );
}

export default AddMedicineReminder;
