import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authServiceInstance from "@services/jwtService/index";

export const doctorSpecialtiesSlice = createSlice({
  name: "doctorSpecialties",
  initialState: {
    full_name: "",
    country_code: "",
  },
  reducers: {
    doctorSpecialtiesSuccess: (state, action) => {
      // Directly mutate the state properties
      state.doctorSpecialties_success = action.payload;
      state.doctorSpecialties_error = null; // Reset error state on success
    },
    doctorSpecialtiesFailure: (state, action) => {
      // Directly mutate the state properties
      state.doctorSpecialties_error = action.payload;
      state.doctorSpecialties_success = null; // Reset success state on failure
    },
  },
});

export const { doctorSpecialtiesSuccess, doctorSpecialtiesFailure } =
  doctorSpecialtiesSlice.actions;

// Asynchronous thunk action for logging in
export const getDoctorSpecialties = () => async (dispatch) => {
  try {
    const response = await axios.get("doctor-specialties");
    dispatch(doctorSpecialtiesSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(doctorSpecialtiesFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = doctorSpecialtiesSlice.name;

export default doctorSpecialtiesSlice.reducer;
