import React, { useRef, useEffect } from "react";
import { FaBriefcaseMedical, FaRegCalendarPlus, FaQuestionCircle } from "react-icons/fa";
import { GiLifeBar } from "react-icons/gi";

import { RxCross1, RxDashboard } from "react-icons/rx";
import { PiNotepad } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import avatar2 from "@/../../public/assets/images/Dashboard/Avatar/avatar2.png";
import { Link, useLocation } from "react-router-dom";
import { getUser } from "@medoczen/core/MDZsessions";

const sidebarItems = [
  {
    path: "/dashboard",
    icon: <RxDashboard size={24} />,
    label: "Dashboard",
  },
  // {
  //   path: "/appointments",
  //   icon: <FaRegCalendarPlus size={24} />,
  //   label: "Appointments",
  // },
  {
    path: "/lab-reports",
    icon: <PiNotepad size={24} />,
    label: "Reports",
  },
  // {
  //   path: "/medicine-reminder",
  //   icon: <FaBriefcaseMedical size={24} />,
  //   label: "Medicine Reminder",
  // },
  {
    path: "/health-assessment",
    icon: <GiLifeBar size={24} />,
    label: "Health Assessment",
  },
  {
    path: "/help-support",
    icon: <FaQuestionCircle size={24} />,
    label: "Help & Support",
  },
];

function Sidebar(props) {
  const location = useLocation(); // Get the current location

  // Function to determine if the item is active
  const isActive = (path) => location.pathname === path;
  const user_data = getUser();
  const sidebarRef = useRef(); // Step 1: useRef to reference the sidebar

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        props.setSidebarOpen(false);
      }
    };

    // Attach the event listener only if the sidebar is open
    if (props.isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.isSidebarOpen, props.setSidebarOpen]); // Ensure the effect runs when `isSidebarOpen` changes

  return (
    <div
      style={{ zIndex: 500 }}
      className={`fixed top-0 flex  h-screen w-[100%]   transition-transform duration-300 ${
        props?.isSidebarOpen ? "" : "translate-x-[-100%]"
      } `}
    >
      <div
        ref={sidebarRef}
        className="relative w-[80%] h-full bg-white flex flex-col px-[1rem] py-[2rem]"
      >
        <div className="w-full flex items-center gap-2 justify-start  ">
          <img
            src={`${user_data?.selfie ? user_data.selfie : avatar2}?${new Date().getTime()}`}
            className="w-[65px] h-[65px] rounded-full"
            alt="Profile preview"
          />

          <div>
            <h1 className="text-[16px] font-semibold">{user_data?.full_name}</h1>
          </div>
        </div>

        <ul className="mt-[2rem] ms-[10px] flex flex-col gap-[1.5rem]">
          {sidebarItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex items-center gap-3 text-[18px] font-[500] ${
                  isActive(item.path) ? "text-activeLink" : "text-inactiveLink"
                }`}
              >
                {item.icon}
                <span>{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>

        <button
          className="absolute top-[1rem] right-[1rem]"
          onClick={() => props?.setSidebarOpen(false)}
        >
          <RxCross1 size={24} color="black" />
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
