import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import usaFlag from "@/../../public/assets/images/Doctors/usa.png";
import Box from "@mui/material/Box";
import { Chip, Rating, Skeleton } from "@mui/material";


import ReviewComponent from "./tabs/ReviewComponent";
import TabSkeleton from "./TabSkeleton";
import Placeholder from "@/../../public/assets/images/placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllReviews, getReviews } from "../AppointmentsList/reviews/store/reviewsSlice";
import { useParams } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className=" h-full w-full"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const { doctorId } = useParams();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [reviews, setReviews] = React.useState([]);
  const dispatch = useDispatch();
  const selectReview = useSelector((state) => state.review.reviewsSlice);


  React.useEffect(() => {
    setLoading(true);
    if (value === 3) {
      dispatch(getAllReviews({ doctor_id: doctorId })).then(() => {
        setLoading(false);
      })
    }
  }, [value])

  React.useEffect(() => {
    setReviews(selectReview.getReview_success)

  }, [selectReview])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function formatDateRange(is_active, startDate, endDate) {
    const start = new Date(startDate);
    const end = is_active ? new Date() : new Date(endDate);
    const options = { year: "numeric", month: "short" };

    // Calculate duration
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }

    const startStr = start.toLocaleDateString("en-US", options);
    const endStr = is_active
      ? "Present"
      : end.toLocaleDateString("en-US", options);
    const duration = `${years > 0 ? `${years} yrs ` : ""}${months > 0 ? `${months} mos` : ""
      }`;

    return `${startStr} - ${endStr} . ${duration}`;
  }

  return (
    <Box sx={{ width: "100%", height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", bgcolor: 'white', zIndex: 50, position: 'sticky', top: '11% ', paddingTop: '2.5rem' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{ textTransform: "none", color: "black" }}
            label="About"
            {...a11yProps(0)}
          />
          <Tab
            style={{ textTransform: "none", color: "black" }}
            label="Experince"
            {...a11yProps(1)}
          />
          <Tab
            style={{ textTransform: "none", color: "black" }}
            label="Education"
            {...a11yProps(2)}
          />
          <Tab
            style={{ textTransform: "none", color: "black" }}
            label="Reviews"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {props.loading ? (
          <div className="mb-5">
            <div className="flex items-center justify-between">
              <h1 className="font-[600] text-[23px]">About</h1>
            </div>
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={120} />
          </div>
        ) : (
          <div className="mb-5">
            <div className="flex items-center justify-start">
              <h1 className="font-[600] text-[23px]">About</h1>
            </div>
            <p className="font-[400] text-[14px]">{props?.bio}


            </p>
          </div>
        )}

        <hr className="border-[#00000050] w-full my-[10px]" />
        {props.loading ? (
          <div className="mb-5">
            <div className="flex items-center justify-between">
              <h1 className="text-[23px] font-semibold">Languages</h1>
            </div>

            <div className="flex flex-col gap-3 mt-2">
              <Skeleton variant="rounded" sx={{ width: "100%" }} height={120} />
            </div>

            <hr className="border-[#00000050] w-full my-[10px]" />
          </div>
        ) : (
          <div className="mb-5">
            <div className="flex items-center justify-start">
              <h1 className="text-[23px] font-semibold">Languages</h1>
            </div>

            <div className="flex flex-col gap-3 mt-4">
              {props?.languages.map((language, index) => (
                <div key={index} className="flex items-start justify-start">
                  <div className="flex flex-col">
                    <span className="font-bold block">{language?.name}</span>
                    <span className="block">{language?.proficiency}</span>
                  </div>
                </div>
              ))}
            </div>

            <hr className="border-[#00000050] w-full my-[10px]" />
          </div>
        )}

        <div className="mb-5">
          <div className="flex items-center justify-start">
            <h1 className="text-[23px] font-semibold">Skills</h1>
          </div>
          <div className="flex flex-col gap-3 mt-2">
            <div className="flex flex-wrap gap-1">
              {props?.skills.map((skill, index) => (
                <Chip key={index} label={skill} variant="outlined" />
              ))}
            </div>
          </div>

          <hr className="border-[#00000050] w-full my-[10px]" />
        </div>

        <div className="w-full  mb-[1rem]">
          <h1 className="text-[16px] font-semibold">Country</h1>
          <div className="flex gap-3 mt-2">
            <div className="w-[167px] h-[45px] bg-[#C7C7C72E] rounded-[6px] flex gap-2 items-center px-2">
              <img src={usaFlag} className="w-[36px] h-[27px]" alt="" />
              <span className="text-[14px] font-[500]">united states</span>
            </div>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {props.loading ? (
          <TabSkeleton />
        ) : (
          <div className="flex flex-col w-full gap-2">
            {props?.experiences?.filter((item) => item?.credentials?.status === "verified").map((experience, index) => (
              <div key={experience.id || index} className="flex flex-col gap-3">
                <div className="flex justify-between items-start">
                  <div className="flex gap-[10px]">
                    <img
                      src={experience.imageSrc || Placeholder}
                      className="w-[60px] h-[60px]"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <h1 className="font-[700] text-[16px]">
                        {experience.title}
                      </h1>
                      <span className="text-[13px] font-[400]">
                        {experience.company}, {experience.location_type}
                      </span>
                      <span className="text-[13px] font-[400]">
                        {formatDateRange(
                          false,
                          experience.start_date,
                          experience.end_date,
                        )}
                      </span>
                      <span className="text-[13px] font-[400]">
                        {experience.location}
                      </span>
                      <p className="text-[14px] font-[500] mt-2 text-black">
                        {experience.description}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Render <hr> conditionally, not for the last item */}
                {index !== props.experiences.length - 1 && (
                  <hr className="border-[#00000050] w-full my-[10px]" />
                )}
              </div>
            ))}
          </div>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        {props.loading ? (
          <TabSkeleton />
        ) : (
          <div className="flex flex-col w-full gap-2">
            {props?.doc_educations?.filter((item) => item?.credentials?.status === "verified").map((education, index) => (
              <div key={index} className="flex flex-col gap-3">
                <div className="flex justify-between items-start">
                  <div className="flex gap-[10px]">
                    <img
                      src={education.imageSrc || Placeholder}
                      className="w-[60px] h-[60px]"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <h1 className="font-[700] text-[16px]">
                        {education.school}
                      </h1>
                      <span className="text-[13px] font-[400]">
                        {education.degree}
                      </span>
                      <span className="text-[13px] font-[400]">
                        {education.field_of_study}
                      </span>
                      <span className="text-[13px] font-[400]">
                        {education.grade}
                      </span>
                      <p className="text-[14px] font-[400] mt-2">
                        {education.description}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Render <hr> conditionally, not for the last item */}
                {index !== props.doc_educations.length - 1 && (
                  <hr className="border-[#00000050] w-full my-[10px]" />
                )}
              </div>
            ))}
          </div>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div className="">
          <div className="w-full flex ">

            <div className="flex min-w-[120px] gap-3 flex-col items-center ps-[1rem] ">
              <div className="w-full flex flex-col items-start ">
                <span className="flex  items-center gap-1 ">
                  <Rating

                    defaultValue={4.5}
                    style={{ fontSize: "40px" }}
                    precision={0.5}
                    readOnly
                  />
                  <span className="text-[24px] font-[500]">4.5</span>{" "}

                </span>
                <span className="text-[#00000061] text-[14px] ps-[8px]">
                  273 Reviews
                </span>
              </div>

            </div>
          </div>
          {/* <hr className="border-[#00000050]  w-full my-[20px]" /> */}
          {loading ? <Skeleton sx={{ width: '100%', height: '100px' }} /> : <ReviewComponent reviews={reviews} />}
        </div>
      </CustomTabPanel>
    </Box>
  );
}
