import React from "react";
import { Link } from "react-router-dom";

function DoctorSpecialtiesBoxTab(props) {
  return (
    <Link
      key={props?.id}
      to={props?.path || "/doctors-list"}
      state={{ medical_category: props }}
      className="h-[123px] min-w-[170px] rounded-[14px] flex flex-col justify-center items-center shadow-[10px_10px_30px_0px_#cbd5e0]"
    >
      <div className="w-[60px] h-[60px] rounded-full bg-[#D9D9D94F] flex justify-center items-center mb-[1rem] ">
        <img src={props?.image} className="" alt={props?.name} />
      </div>

      <span key={props?.id} className="font-[700] text-[14px]">
        {props?.name}
      </span>
    </Link>
  );
}

export default DoctorSpecialtiesBoxTab;
