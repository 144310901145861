import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const healthAssessmentSlice = createSlice({
  name: "healthAssessment",
  initialState: {},
  reducers: {
    healthAssessmentSuccess: (state, action) => {
      // Directly mutate the state properties
      state.healthAssessment_success = action.payload;
      state.healthAssessment_error = null; // Reset error state on success
    },
    healthAssessmentFailure: (state, action) => {
      // Directly mutate the state properties
      state.healthAssessment_error = action.payload;
      state.healthAssessment_success = null; // Reset success state on failure
    },
  },
});

export const { healthAssessmentSuccess, healthAssessmentFailure } =
  healthAssessmentSlice.actions;

// Asynchronous thunk action for logging in
export const getHealthAssessment = () => async (dispatch) => {
  try {
    const response = await axios.get("doctor-specialties");
    dispatch(healthAssessmentSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(healthAssessmentFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Asynchronous thunk action for logging in
export const getAllQuestions = (params) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/health-assessment/questions?medical_category=${params.medical_category}&question_category=${params.question_category}`,
    );
    dispatch(healthAssessmentSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(healthAssessmentFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Asynchronous thunk action for logging in
export const getAllAnswers = (params) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/health-assessment/answers?medical_category=${params.medical_category}&question_category=${params.question_category}`,
    );
    dispatch(healthAssessmentSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(healthAssessmentFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Asynchronous thunk action for logging in
export const postAnswer = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`/health-assessment/answers`, params);
    // dispatch(healthAssessmentSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(healthAssessmentFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

export const DeleteAnswer = (params) => async (dispatch) => {
  try {
    const response = await axios.delete(
      `/health-assessment/answers/${params.question_id}`,
      params,
    );
    // dispatch(healthAssessmentSuccess(response.data));
  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(healthAssessmentFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = healthAssessmentSlice.name;

export default healthAssessmentSlice.reducer;
