import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import { HiOutlineBellAlert } from "react-icons/hi2"; // Corrected import
import { FaBars } from "react-icons/fa"; // Corrected import
import Sidebar from "../../../pages/Dashboard/Components/Sidebar";
import { getUser } from "../MDZsessions";
import { Avatar } from "@mui/material";

function HeaderWrapper(props) {
  const [sideBarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const user = getUser();

  return (
    <>
      {props?.left_nav && <Sidebar isSidebarOpen={sideBarOpen} setSidebarOpen={setSidebarOpen} />}

      <div className="inset-0 bg-primary w-full h-[50px] sticky flex items-center justify-center z-[99]">
        <div className="grid grid-cols-[auto_1fr_auto] w-full max-w-4xl px-4 items-center">
          {/* Left column for back navigation or sidebar toggle */}
          <div className="flex justify-start">
            {props.left_nav ? (
              <button
                className="p-[2px] rounded-full bg-white"
                onClick={() => setSidebarOpen(!sideBarOpen)}
              >
                <Avatar src={user?.selfie} sx={{ width: 32, height: 32 }} />
              </button>
            ) : props.path ? (
              <>
                <Link
                  to={`/${props.path || "dashboard"}`}
                  replace={true}
                  className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
                >
                  <GoChevronLeft color="white" size={26} />
                </Link>
              </>
            ) : (
              <button
                onClick={() => (props?.action ? props.action() : navigate(-1))}
                className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
              >
                <GoChevronLeft color="white" size={26} />
              </button>
            )}
          </div>

          {/* Center column for title */}
          <div className="flex justify-center">
            <h1 className="font-[600] text-lg text-white">{props.title}</h1>
          </div>

          {/* Right column for children and optional notification icon */}
          <div className="flex justify-end min-w-[1rem]">
            {props.notifications && <HiOutlineBellAlert size={24} color="white" />}
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderWrapper;
