import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authServiceInstance from "@services/jwtService/index";

export const verificationSlice = createSlice({
  name: "verification",
  initialState: {
    confirmation_code: "",
    username: "",
  },
  reducers: {
    verificationSuccess: (state, action) => {
      // Directly mutate the state properties
      state.success = action.payload;
      state.error = null; // Reset error state on success
    },
    verificationFailure: (state, action) => {
      // Directly mutate the state properties
      state.error = action.payload;
      state.success = null; // Reset success state on failure
    },
  },
});

export const { verificationSuccess, verificationFailure } =
  verificationSlice.actions;

// Asynchronous thunk action for logging in
export const verifyEmail = (credentials) => async (dispatch) => {
  try {
    const data_resp = await authServiceInstance.verifyEmail({ ...credentials });
    dispatch(verificationSuccess(data_resp));
  } catch (error) {
    dispatch(verificationFailure(error));
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = verificationSlice.name;

export default verificationSlice.reducer;
