import React from "react";
import { Skeleton, Box, Typography, Button } from "@mui/material";

const Skeletons = () => {
  return (
    <Box className="w-full text-center mt-4">
      {/* Title of the form */}
      <Typography variant="h6">
        <Skeleton width="40%" sx={{ mx: "auto" }} />
      </Typography>
      {/* Description */}
      <Typography variant="body2" color="textSecondary" className="mt-2">
        <Skeleton width="80%" sx={{ mx: "auto" }} />
      </Typography>

      {/* Feedback form skeleton */}
      <Box className="flex flex-col items-center w-full px-4 mt-4">
        <Box className="w-full max-w-lg">
          {/* Title field skeleton */}
          <Box mb={4}>
            <Skeleton variant="rectangular" height={56} />
          </Box>

          {/* Reviews field skeleton */}
          <Box mb={4}>
            <Skeleton variant="rectangular" height={140} />
          </Box>

          {/* Submit button skeleton */}
          <Box className="w-full flex justify-center mt-6">
            <Skeleton variant="rectangular" width="40%" height={36} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Skeletons;
