import React from 'react';
import { Skeleton } from '@mui/material';

function ChatSkeleton() {
    return (
        <div className="flex flex-col gap-2 w-full mt-[7rem] mb-[10rem] px-[1rem]">
            {/* Chat Header Skeleton */}
            <div className="fixed top-0 w-full flex items-center gap-4 pt-5">
                <Skeleton variant="text" width={20} height={10} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" width={100} height={30} />
            </div>

            {/* Chat Bubble Skeletons */}
            <div className="flex flex-col gap-2 w-full mt-4">
                <Skeleton variant="rectangular" height={50} width="60%" />
                <Skeleton variant="rectangular" sx={{ marginLeft: "auto" }} height={60} width="80%" />
                <Skeleton variant="rectangular" height={50} width="50%" />
                <Skeleton variant="rectangular" height={50} sx={{ marginLeft: "auto" }} width="60%" />
                <Skeleton variant="rectangular" height={50} sx={{ marginLeft: "auto" }} width="80%" />
                <Skeleton variant="rectangular" height={50} width="50%" />

            </div>

            {/* Input and Attachment Skeleton */}
            <div className="fixed w-full bottom-0 flex justify-center items-center gap-3 px-[1rem] z-[99] bg-white pt-2 pb-2">
                <Skeleton variant="rounded" height={50} width="80%" />
                <Skeleton variant="circular" width={40} height={40} />
            </div>
        </div>
    );
}

export default ChatSkeleton;
