import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';

function PulseOximetry({ patientData }) {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%'}}>
        <Typography sx={{ fontSize: '17px', fontWeight: 'bold' }}>
          Pulse Oximetry (Spo2)
        </Typography>
      </Box>

      <Card className="bg-pastelGreen" sx={{ width: '100%', display: 'flex', justifyContent: 'around', alignItems: 'center', gap: theme.spacing(2), p: theme.spacing(2), borderRadius: '3px', boxShadow: '0 8px 30px rgba(0,0,0,0.12)' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1), textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{patientData.resting}%</Typography>
          <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>Resting</Typography>
        </CardContent>
        <Box component="img" src={patientData.image} sx={{ width: 50, height: 50 }} alt="Spo2" />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1), textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{patientData.activity}%</Typography>
          <Typography sx={{ fontWeight: 500, fontSize: '18px' }}>Activity</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PulseOximetry;