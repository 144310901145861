import React from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { MdHome } from "react-icons/md";

import { TbCalendarFilled } from "react-icons/tb";
import { FaNotesMedical, FaTablets } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

function BottomNav() {
  const navigate = useNavigate();

  // Function to handle navigation
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className="w-full h-[65px] px-4 py-2 flex justify-around items-center fixed bottom-0 shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] bg-white z-[100]">
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            `flex flex-col items-center ${isActive ? "text-activeLink" : "text-inactiveLink"}`
          }
        >
          <MdHome size={26} />
          <span className="text-xs font-[600]">Home</span>
        </NavLink>
        <NavLink
          to="/appointments"
          className={({ isActive }) =>
            `flex flex-col items-center ${isActive ? "text-activeLink" : "text-inactiveLink"}`
          }
        >
          <TbCalendarFilled size={26} />
          <span className="text-xs font-[600]">Appointments</span>
        </NavLink>
        <NavLink
          to="/medicine-reminder"
          className={({ isActive }) =>
            `flex flex-col items-center ${isActive ? "text-activeLink" : "text-inactiveLink"}`
          }
        >
          <FaTablets size={26} />
          <span className="text-xs font-[600]">Medicines</span>
        </NavLink>

        <NavLink
          to="/prescription"
          className={({ isActive }) =>
            `flex flex-col items-center ${isActive ? "text-activeLink" : "text-inactiveLink"}`
          }
        >
          <FaNotesMedical size={26} />
          <span className="text-xs font-[600]">Prescription</span>
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) =>
            `flex flex-col items-center ${isActive ? "text-activeLink" : "text-inactiveLink"}`
          }
        >
          <IoSettingsOutline size={26} />
          <span className="text-xs font-[600]">Settings</span>
        </NavLink>
      </div>
    </>
  );
}

export default BottomNav;
