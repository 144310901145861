import React, { useEffect, useRef, useState } from "react";
import ChatHeader from "../Components/ChatHeader";
import { RiCheckDoubleFill } from "react-icons/ri";
import AC from "agora-chat";
import ChatSendDiv from "../Components/ChatSentDiv";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Dialog, DialogContent, IconButton } from '@mui/material'; // MUI components for the modal
import CloseIcon from '@mui/icons-material/Close'; // MUI icon for closing the dialog
import { FormatTime, getRandomString, mimeToExtension } from '../agora/helper';
import ChatSkeleton from "../Components/ChatSkeleton";
import { FaFile } from "react-icons/fa";

function ChatTab() {
  const messagesEndRef = useRef(null);
  const [data, setData] = useState([]);
  const [conn, setConn] = useState(null);
  const [userId, setUserId] = useState(null);
  const [agoraToken, setAgoraToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State for opening dialog
  const [selectedImage, setSelectedImage] = useState(null); // State to store selected image
  const appointment_details = JSON.parse(sessionStorage.getItem("appointment_details"));
  const { recipientId } = useParams();

  const saveData = (body) => {

    // const newMessage = {
    //   id: data.length + 1,
    //   chat: newChatMessage,
    //   type: type,
    //   created_at: new Date().toISOString(),
    //   from: userId,
    //   to: recipientId
    // };
    setData((prevData) => [...prevData, body]);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  useEffect(() => {
    const fetchCredentials = async () => {
      try {
        const response = await axios.get(`https://api.medoczen.uk/agora-chat/generate_chat_token`);
        const { userId, token } = response.data;
        setUserId(userId);
        setAgoraToken(token);
      } catch (error) {
        console.error("Failed to fetch Agora credentials:", error.response?.data || error.message);
      }
    };

    fetchCredentials();
  }, []);


  const fetchOldMessages = async (connection) => {

    try {
      const options = {
        queue: recipientId,
        count: 20, // Number of messages to fetch
        chatType: "singleChat",
      };

      const messages = await connection.fetchHistoryMessages(options);

      messages.forEach((message) => {
        saveData(message);
      });

    } catch (error) {
      console.error("Failed to fetch old messages:", error);
    }
  };

  useEffect(() => {
    if (userId && agoraToken) {
      const connection = new AC.connection({
        appKey: "711193274#1380888",
      });

      connection.addEventHandler("connection&message", {
        onConnected: () => {
          fetchOldMessages(connection);
          setIsLoading(false);
        },
        onDisconnected: () => {
          // console.log("Agora Chat disconnected for user:", userId);
        },
        onTextMessage: (message) => {
          // console.log("onTextre", message);
          saveData({ ...message, contentsType: "TEXT", data: message?.msg });
        },
        onFileMessage: (message) => {
          saveData({ ...message, contentsType: "FILE" });
        },
        onImageMessage: (message) => {
          saveData({ ...message, contentsType: "IMAGE" });
        },
        onDeliveredMessage: (message) => {
          // console.log("onDeliveredMessage", message);
        },
        onReadMessage: (message) => {
          // console.log("onReadMessage", message);
        },
        onAudioMessage: (message) => {
          saveData({ ...message, contentsType: "VOICE" });
        },
        onVideoMessage: (message) => {
          saveData({ ...message, contentsType: "VIDEO" });
        },
        onTokenWillExpire: () => {
          console.warn("Agora token is about to expire for user:", userId);
        },
        onTokenExpired: () => {
          console.warn("Agora token expired for user:", userId);
        },
        onError: (error) => {
          handleAgoraError(error);
        },
        onOnline: (user) => {
          // console.log("Agora user is online:", user);
        },
        onOffline: (user) => {
          // console.log("Agora user is offline:", user);
        },
      });

      setConn(connection);

      connection.open({
        user: userId,
        agoraToken
      });

      return () => {
        connection.close();
      };
    }
  }, [userId, agoraToken]);

  const handleSendMessage = (newChatMessage) => {
    if (conn && newChatMessage) {
      const option = {
        chatType: "singleChat",
        type: "txt",
        to: recipientId,
        msg: newChatMessage,
      };
      const msg = AC.message.create(option);

      conn.send(msg)
        .then((res) => {

          saveData({ ...res.message, contentsType: "TEXT", data: res?.message?.msg });
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          handleAgoraError(error);
        });
    } else {
      console.warn("Cannot send message: No connection or empty message.");
    }
  };


  const addAudioElement = async (blob) => {

    const res = await sendMediaMessage({
      recipientId: recipientId,
      from: userId,
      blob,
      fileType: 'mp3',
      name: 'audio - ' + Date.now(),
    });
    if (res.localMsgId && res.serverMsgId) {


      saveData({ ...res.message, contentsType: "VOICE" });
      // getMessages();
    } else {
      alert('Unable to send message');
    }
  };

  const handleFileSelected = async (file) => {


    const arrayBuffer = await file.arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

    const res = await sendMediaMessage({
      recipientId,
      from: userId,
      blob,
      fileType: file.type,
      name: file?.name?.split('.')[0] || "untitled",
    });
    if (res.localMsgId && res.serverMsgId) {

      if (res.message.type === "video") {
        saveData({ ...res.message, contentsType: "VIDEO" });
      } else if (res.message.type === "img") {
        saveData({ ...res.message, contentsType: "IMAGE" });
      } else if (res.message.type === "audio") {
        saveData({ ...res.message, contentsType: "VOICE" });
      } else if (res.message.type === "file") {
        saveData({ ...res.message, contentsType: "FILE" });
      }

      // getMessages();

    } else {
      alert('Unable to send message');
    }
  };

  const sendMediaMessage = async ({ recipientId, from, blob, fileType, name }) => {
    try {
      const file = {
        url: AC.utils.parseDownloadResponse.call(AC.connection, blob),
        filename: `${name}.${fileType}`,
        filetype: fileType,
        data: blob,
        length: '3',
        duration: '3',
        name
      };


      const imageTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
      const videoTypes = ['mp4', 'wmv', 'avi', 'rmvb', 'mkv'];
      const documentTypes = ['zip', 'txt', 'doc', 'pdf'];
      const audioTypes = ['mp3', 'amr', 'wmv', 'mpeg'];

      let option = {};
      if (imageTypes.some((val) => file.filetype.toLowerCase().includes(val))) {
        option = { type: 'img' };
      } else if (
        audioTypes.some((val) => file.filetype.toLowerCase().includes(val))
      ) {
        option = { type: 'audio', length: '3' };
      } else if (
        videoTypes.some((val) => file.filetype.toLowerCase().includes(val))
      ) {
        option = { type: 'video' };
      } else if (
        documentTypes.some((val) => file.filetype.toLowerCase().includes(val))
      ) {
        option = { type: 'file' };
      }

      option = {
        ...option,
        file,
        filename: `${name}.${fileType}`,
        to: recipientId,
        from,
        chatType: 'singleChat',
        onFileUploadError: () => {
          // console.log('onFileUploadError')
        },
        onFileUploadProgress: (e) => {
          // console.log(e)

        },
        onFileUploadComplete: (data) => {
          // console.log('onFileUploadComplete', data)
        },
        ext: {
          file_length: file.data.size,
        },
      };

      const msg = AC.message.create(option);

      return await conn.send({ ...msg, filename: option.filename });
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };
  const openImageDialog = (imageUrl) => {
    // console.log("Opening image in fullscreen:", imageUrl);
    setSelectedImage(imageUrl);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    // console.log("Closing image dialog");
    setIsDialogOpen(false);
  };

  const handleAgoraError = (error) => {
    const errorCode = error.type;
    switch (errorCode) {
      case 1:
        console.error("Agora connection failed:", error.message);
        break;
      case 2:
        console.error("Agora authorization failed:", error.message);
        break;
      case 3:
        console.warn("Agora token expired. Please renew the token.");
        break;
      case 206:
        console.warn("User already exists:", error.message);
        break;
      default:
        console.error("Agora Chat error:", error.message || error);
    }
  };




  if (isLoading) {
    return <ChatSkeleton />;
  }


  return (
    <div className="flex">
      <ChatHeader profilePic="path/to/avatar1.png" subHead="Owner" name="Carla" />
      <div className="flex flex-col gap-2 w-full mt-[7rem] mb-[10rem] px-[1rem]">
        {data.map((message, index) => {
          let isMine = message?.from === userId;
          return (
            <div
              key={index}
              className={`${isMine
                ? "self-end text-white rounded-b-[10px] rounded-l-[10px] bg-primary"
                : "self-start bg-[#E4E4E496] rounded-b-[10px] rounded-r-[10px]"
                } p-1 text-[14px] relative max-w-[70%] font-[500] break-words`}
            >

              {isMine ? <div className="absolute bottom-[1px] right-1 flex items-center">
                <span className="text-[.45rem] me-1 lowercase">{FormatTime(message?.time)}</span>  <RiCheckDoubleFill color="white" />
              </div>
                :
                <div className="absolute  bottom-[1px] right-1">
                  <span className="text-[.5rem] me-1 lowercase text-black">{FormatTime(message?.time)}</span>
                </div>}

              {message?.contentsType === "IMAGE" && (
                <>
                  <div className="w-full pb-5  ">
                    <img
                      src={message?.url}
                      alt="image"
                      className="cursor-pointer rounded-[10px]"
                      onClick={() => openImageDialog(message?.url)}
                    />
                    <div className="flex w-full justify-between items-center text-[.8rem]">
                      <span>{message?.filename?.split('.')[0] || "untitled"}</span>
                      <span className="text-[.6rem]">{(Number(message?.file_length) / 1024).toFixed(2) + " KB"}</span>
                    </div>
                  </div>

                </>
              )}

              {message.contentsType === "VIDEO" && (
                <>
                  <div className="w-full pb-5  ">
                    <video src={message?.url} className="rounded-[10px]" autoPlay={false} controls />

                    <div className="flex w-full justify-between items-center text-[.8rem]">
                      <span>{message?.filename?.split('.')[0] || "untitled"}</span>
                      <span className="text-[.6rem]">{(Number(message?.file_length) / 1024).toFixed(2) + " KB"}</span>
                    </div>
                  </div>

                </>

              )}

              {message.contentsType === "FILE" && (

                <>
                  <div className="w-full pb-3 min-w-[10rem]">
                    <div className=" bg-[#00000021] items-center justify-self-start rounded-[10px] p-1">
                      <div className="flex flex-1 items-center ">
                        <div className="relative flex flex-col items-center">
                          {/* Icon */}
                          <FaFile color="black" size={35} className="relative z-10" />

                          {/* Extension Text */}
                          <span className="absolute top-[0.7rem] text-white text-[0.7rem] text-center z-20">
                            {message?.filename?.split('.').pop() ? mimeToExtension(message?.filename?.split('.').pop()) : "file"}
                          </span>
                        </div>

                        <span>{message?.filename?.split('.')[0] || "untitled"}</span>

                      </div>
                      <span className="ps-1 text-[.6rem]">{(Number(message?.file_length) / 1024).toFixed(2) + " KB"}</span>
                    </div>


                  </div>

                </>


              )}

              {message.contentsType === "VOICE" && (
                <>
                  <div className="w-full pb-5 ">
                    <div className="flex flex-1"> <audio className="h-10 " style={{ color: "white" }} src={message?.url} controls /></div>


                  </div>
                  <div className="absolute bottom-1 left-1 ">
                    <span className="text-[.6rem]">{(Number(message?.file_length) / 1024).toFixed(2) + " KB"}</span>
                  </div>

                </>

              )}

              {message.contentsType === "TEXT" && (
                <>
                  <div className="w-full min-w-20 pb-1 ">

                    <div className="px-1">{message?.data}</div>

                  </div>

                </>
              )}

              {/* {message.from === userId && (
              
              )} */}

              <div ref={messagesEndRef} />
            </div>
          )

        }

        )}

        {data.length === 0 && <div>No Chats</div>}
      </div>

      <ChatSendDiv addAudioElement={addAudioElement} handleFileSelected={handleFileSelected} onSave={handleSendMessage} />

      {/* Fullscreen Image Dialog */}
      {selectedImage && (
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <img src={selectedImage} alt="Fullscreen Preview" className="w-full h-full object-cover" />
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              style={{ position: 'absolute', top: 10, right: 10 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ChatTab;
