import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function VitaminsList() {
  const vitamins = [
    { name: 'Vitamin B1 (Thiamine)', amount: '1.2 mg', color: 'primary' },
    { name: 'Vitamin B2 (Riboflavin)', amount: '1.3 mg', color: '#E37510' },
    { name: 'Vitamin B3 (Niacin)', amount: '16 mg', color: 'primary' },
    { name: 'Vitamin B5 (Pantothenic Acid)', amount: '5 mg', color: '#E37510' },
    { name: 'Vitamin B6 (Pyridoxine)', amount: '1.7 mg', color: 'primary' },
    { name: 'Vitamin B7 (Biotin)', amount: '30 µg', color: '#E37510' },
    { name: 'Vitamin B9 (Folate)', amount: '400 µg', color: 'primary' },
    { name: 'Vitamin B12 (Cobalamin)', amount: '2.4 µg', color: '#E37510' },
    { name: 'Vitamin C (Ascorbic Acid)', amount: '90 mg', color: 'primary' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        px: 2,
      }}
    >
      <Box sx={{ width: '100%'}}>
        <Typography variant="h6" component="h1" sx={{ fontSize: 17, fontWeight: 'bold' }}>
          Water-Soluble Vitamins
        </Typography>
      </Box>
      <Paper
      className='bg-lightGray'
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 1,
           boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
        }}
      >
        {vitamins.map((vitamin, index) => (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', px: 1, fontSize: 14, fontWeight: 400 }}>
            <Typography>{vitamin.name}</Typography>
            <Typography sx={{ fontWeight: 'bold', color: vitamin.color === 'primary' ? 'primary.main' : vitamin.color }}>
              {vitamin.amount}
            </Typography>
          </Box>
        ))}
      </Paper>
    </Box>
  );
}

export default VitaminsList;