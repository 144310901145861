import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FormControl, Skeleton, Typography } from "@mui/material";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { IoIosArrowDown } from "react-icons/io";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import { FaRegEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAnswer, getAllAnswers } from "../store/healthAssessmentSlice";
import AnswerUpdateDialog from "@medoczen/core/AnswerUpdateDialog";
import { CiTrash } from "react-icons/ci";

// Define an array with questions and answers

function Skeletons() {
  return (
    <div className="w-full flex flex-col items-center mt-8 justify-center gap-2">
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
    </div>
  );
}

function Answers() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { medical_category, question_category } = location.state;
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  const selectAnswers = useSelector(
    (state) => state.healthAssessment.healthAssessmentSlice,
  );

  useEffect(() => {
    if (selectAnswers) {
      setAnswers(selectAnswers.healthAssessment_success);
    }
  }, [selectAnswers]);

  const fetchData = () => {
    setLoading(true);
    dispatch(getAllAnswers({ question_category, medical_category })).then(
      () => {
        setLoading(false);
      },
    );
  };
  const handleDelete = (item) => {
    setLoading(true);
    dispatch(DeleteAnswer(item)).then(() => {
      fetchData();
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HeaderWrapper title="Answers" path="health-assessment"></HeaderWrapper>
      <div className="flex items-center w-full px-[1rem]  mt-[1rem] ">
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "100%" }} height={60} />
        ) : (
          <SearchBar placeholder="Search Here" />
        )}
      </div>
      <div className="w-full flex flex-col items-center py-[2rem]">
        {loading ? (
          <Skeletons />
        ) : (
          <>
            {answers.map((item, index) => (
              <Accordion sx={{ width: "100%" }} key={index}>
                <AccordionSummary
                  style={{ height: "4rem" }}
                  expandIcon={<IoIosArrowDown size={20} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-12">
                    {" "}
                    <span className=" col-span-10">
                      <Typography className=" text-justify">
                        {item.answer}{" "}
                      </Typography>
                    </span>
                    <span className="w-full flex justify-between items-end col-span-2">
                      <AnswerUpdateDialog n={item} fetchData={fetchData} />{" "}
                      <span onClick={() => handleDelete(item)}>
                        <CiTrash size={24} />
                      </span>
                    </span>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default Answers;
