import React, { useEffect, useState } from "react";
import { IoVideocam } from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { BiSolidCalendar } from "react-icons/bi";
import { Box, IconButton, Rating, Skeleton } from "@mui/material";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import BasicTabs from "./Tabs";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { getDoctor } from "./store/DoctorSlice";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { GiTwoCoins } from "react-icons/gi";

function DoctorProfile(props) {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState();
  const { doctorId } = useParams();
  const navigate = useNavigate(); // Hook to get the navigate function
  const dispatch = useDispatch();
  const selectDoctor = useSelector((state) => state.doctor.doctorSlice);

  useEffect(() => {
    setProfile(selectDoctor.doctor_success);
  }, [selectDoctor]);



  function getDoctorProfile() {
    dispatch(getDoctor(doctorId)).then(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getDoctorProfile();
  }, []);

  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ countryCode }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(countryCode);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img
        src={flagSrc}
        className="absolute top-[5px] left-[5px] w-[15px] h-[10px]"
      />
    );
  };

  function toLowercaseHyphenateAndLabel(text) {
    return (
      text
        .toLowerCase() // Convert all characters to lowercase
        .replace(/\s+/g, "-") + // Replace all spaces with hyphens
      "-label"
    ); // Append '-label' at the end
  }

  // Sample doctor object
  const skills = [
    "Effective Communication",
    "Problem Solving",
    "Project Management",
    "Team Leadership",
    "Technical Proficiency",
  ];
  const doctorDetails = {
    // name: "Dr. John Doe",
    specialty: "Psychiatrist",
    rating: 4.5,
    availability: "10am-2pm",

    // avatar: avatar1,
    // countryFlag: usaFlag,
    // languages: ["English", "French"]
  };

  const doc_experiences = [];

  // Sample array of review objects


  const ButtonClick = (e) => {
    navigate(`/book-appointment/${doctorId}`);
  };

  const { name, specialty, rating, availability, bio, avatar, countryFlag } =
    doctorDetails;

  const doctor = doctorDetails;

  return (
    <>
      <div className="inset-0 bg-primary w-full h-[100px] sticky flex items-center justify-center z-[99] mb-[3rem]">
        <div className="grid grid-cols-[auto_1fr_auto] mt-[-45px] w-full max-w-4xl px-4 items-center">
          {/* Left column for back navigation or sidebar toggle */}
          <div className="flex justify-start">
            <Link
              to={`/${props.path || "doctors-list"}`}
              className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
            >
              <GoChevronLeft color="white" size={26} />
            </Link>
          </div>

          {/* Center column for title */}
          <div className="flex justify-center">
            <h1 className="font-[600] text-lg text-white">Profile</h1>
          </div>

          {/* Right column for children and optional notification icon */}
          <div className="flex justify-end min-w-[1rem]">
            <div className="gap-2"></div>
          </div>
        </div>

        <div className="w-full px-[12px] py-[2px] absolute top-[3.5rem]">
          {loading ? (
            <Box className="relative    px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <Skeleton variant="rectangular" width={100} height={60} />
              </div>

              <Box className="flex flex-col justify-around gap-3 w-full ml-[8px]">
                <Skeleton variant="body1" width={130} height={10} />

                <Skeleton variant="body1" width={130} height={10} />
                <Skeleton variant="body1" width={130} height={10} />
              </Box>
              <Skeleton variant="rectangular" width={100} height={60} />
            </Box>
          ) : (
            <Box className="relative px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <img
                  src={profile?.selfie}
                  className="w-[100px] rounded-[6px]"
                  alt=""
                />
                <CountryFlag countryCode={profile?.country_code ?? "US"} />
              </div>

              <Box className="flex flex-col justify-center w-full ml-[8px]">
                <span className="text-[16px] font-[600]">
                  {profile?.full_name}
                </span>
                <span
                  className={`text-[11px] font-[700] rounded-[3px] text-white text-left ${profile?.specialties[0]?.name && toLowercaseHyphenateAndLabel(profile?.specialties[0]?.name)} bg-orange-400  py-[2px] px-[4px] inline-block w-fit`}
                >
                  {profile?.specialties[0]?.name}
                </span>

                <span className="text-[12px] font-[500] lowercase">
                  {profile?.schedule?.open_time?.hour ? <>  {profile?.schedule?.open_time?.hour}{profile?.schedule?.open_time?.period} -{profile?.schedule?.close_time?.hour}{profile?.schedule?.open_time?.period}</> : "not available"}
                </span>
              </Box>
              <Box className="flex flex-col justify-start text-[#616161]  mr-[20px]">
                {profile?.schedule?.in_home && <HiHome size={15} className="my-[1px]" />}
                {profile?.schedule?.video_call && <IoVideocam size={15} className="my-[1px]" />}
                {profile?.schedule?.chat && <BiSolidMessageRoundedDetail size={15} className="my-[1px]" />}
              </Box>
              {profile?.schedule && <Box className="text-[#616161] flex flex-col items-center">

                <IconButton className=" ps-[2px]">
                  <GiTwoCoins size={30} />
                </IconButton>
                <span className="font-[500] text-[12px] text-black mt-[1px]">
                  £{profile.schedule?.rate || ""} /hr
                </span>
              </Box>}
            </Box>
          )}
        </div>
      </div>
      <div className="z-[98] flex justify-around items-center px-3 pt-3 w-full bg-white">
        <div className="text-[16px] font-[600] w-[102px] h-[80px] bg-[#E4C1A6] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Patients</h1>
          <span>100+</span>
        </div>
        <div className="text-[16px] font-[600] w-[102px] h-[80px] bg-[#B6B0E7] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Experience</h1>
          <span>3 Years</span>
        </div>
        <div className="text-[16px] font-[600] w-[102px] h-[80px] bg-[#A8D297] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Rating</h1>
          <span>4.9/5</span>
        </div>
      </div>

      <div className="mb-[3rem]">
        <BasicTabs
          {...doctorDetails}
          experiences={profile?.experience ?? []}
          doc_educations={profile?.education ?? []}
          bio={profile?.metadata?.bio ?? ""}
          specialties={profile?.specialties ?? []}
          skills={skills}

          loading={loading}
          languages={profile?.languages ?? []}
        />
      </div>

      {profile?.schedule && <div className="fixed bottom-0 flex w-full justify-center bg-white p-2">
        <PrimaryButton
          name={"Book Appointment"}
          className="w-[244px] "
          fontSize="18px"
          active={profile?.schedule?.rate !== undefined}
          disabled={profile?.schedule?.rate === undefined}
          action={ButtonClick}
        />
      </div>}
    </>
  );
}

export default DoctorProfile;
