import { Search } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const bookappointmentSlice = createSlice({
  name: "bookappointment",
  initialState: {
    ToastOpen: false,
    ToastMessage: '',
  },
  reducers: {
    getProfileSuccess: (state, action) => {
      state.getProfile_success = action.payload;
      state.getProfile_error = null;
    },
    getProfileFailure: (state, action) => {
      state.getProfile_error = action.payload;
      state.getProfile_success = null;
    },
    getDoctorScheduleSuccess: (state, action) => {
      state.getDoctorSchedule_success = action.payload[0];
      state.getDoctorSchedule_error = null;
    },
    getDoctorScheduleFailure: (state, action) => {
      state.getDoctorSchedule_error = action.payload[0];
      state.getDoctorSchedule_success = null;
    },
    AddAppointmentSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    AddAppointmentFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    SearchText: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    showToast: (state, action) => {
      state.toastOpen = true;
      state.toastMessage = action.payload;
    },
    hideToast: (state) => {
      state.toastOpen = false;
      state.toastMessage = '';
    },
  },
});

export const {
  getProfileSuccess,
  getProfileFailure,
  getDoctorScheduleSuccess,
  getDoctorScheduleFailure,
  AddAppointmentSuccess,
  AddAppointmentFailure,
  showToast,
  hideToast
} = bookappointmentSlice.actions;

// Asynchronous thunk action for fetching bookappointment data
export const getDoctorProfile = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`doctors/${params}`);
    dispatch(getProfileSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getProfileFailure(errorMessage));
    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching bookappointment data
export const getDoctorSchedule = (params) => async (dispatch) => {
  try {
    const response = await axios.get(
      `medical-appointments/doctor-schedule/${params.doctorId}?date=${params.date}`,
    );
    dispatch(getDoctorScheduleSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getDoctorScheduleFailure(errorMessage));
    throw errorMessage;
  }
};


// Asynchronous thunk action for adding 
export const AddAppointment = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`medical-appointments/book/${params.doctor_id}`, params);

    dispatch(AddAppointmentSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(AddAppointmentFailure(errorMessage));
    throw errorMessage;
  }
};


// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = bookappointmentSlice.name;

export default bookappointmentSlice.reducer;
