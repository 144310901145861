import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

export default function BasicDateCalendar({ date, onDateChange }) {
  // Parse the passed date or use today's date if no date is passed
  const selectedDate = date ? dayjs(date) : dayjs();

  // Define the current year
  const yearToDisplay = dayjs().year();

  // Set the minDate to today, restricting past dates
  const minDate = dayjs();

  // Set the maxDate to the end of the current year
  const maxDate = dayjs(`${yearToDisplay}-12-31`);

  // Handle date change
  const handleDateChange = (newDate) => {
    // Call the onDateChange prop with the new date if provided

    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="scale-[1.2]">
        <DateCalendar
          sx={{
            ".MuiPickersDay-root": {
              color: "black",
              borderRadius: 3,
              fontSize: 16,
              borderWidth: 0,
              borderColor: "#2196f3",
              border: "0px solid",
              backgroundColor: "#ffff",
            },
            ".MuiPickersDay-today": {
              color: "#ffff",
              borderRadius: 3,
              fontSize: 16,
              borderWidth: 0,
              borderColor: "#2196f3",
              border: "0px solid",
              backgroundColor: "#4C33C9",
            },
            ".Mui-selected": {
              color: "#ffff !important",
              borderRadius: 3,
              borderWidth: 0,
              borderColor: "#e91e63",
              border: "0px solid",
              backgroundColor: "#4C33C9",
              "&:focus": {
                backgroundColor: "#4C33C9",
              },
            },
          }}
          value={selectedDate}
          onChange={handleDateChange} // Added onChange handler
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </LocalizationProvider>
  );
}
