import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Avatar, Divider } from '@mui/material';
import { HiHome, HiOutlineCalendarDays } from 'react-icons/hi2';
import { LuClock3 } from 'react-icons/lu';
import { IoVideocam } from 'react-icons/io5';
import { BiSolidMessageRoundedDetail } from 'react-icons/bi';


export default function ConfirmCodeDialog({ buttonName, title, content, Action, button, value, open, onClose }) {


    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        onClose()
    };

    const action = () => {
        if (value) {
            Action(...value)
        } else {
            Action()
        }

        handleClose()
    }




    let item
    let code
    if (content) {
        item = JSON.parse(content).appointmentDetails
        code = JSON.parse(content).sessionCode
    }

    return (
        <React.Fragment>
            {/* {button ? <div className='w-full' onClick={handleClickOpen}>{button}</div> : <Button
                sx={{ marginRight: "20px" }}
                variant="contained"
                color="secondary"
                onClick={() => handleClickOpen()}
            >
                {buttonName}
            </Button>} */}
            <Dialog
                open={open}
                // maxWidth='lg'
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >


                {content ? <div className='w-full '>



                    <div className="  w-full  bg-white  ">


                        <div className={`flex items-end   p-2 ${item?.status === 'completed' ? 'bg-gray-50 text-[black]' : 'bg-primary text-white'}  `}>
                            <div className='relative'>
                                <Avatar
                                    alt={item?.doctor?.full_name}
                                    src={item?.doctor?.selfie}
                                    sx={{ width: 62, height: 62 }}
                                    className="border-2 border-white"
                                />
                                {/* <CountryFlag country_code={item?.doctor?.country_code} /> */}
                            </div>
                            <div className="ml-4 flex w-full  flex-col justify-center">
                                <h2 className=" text-[1rem] font-[600]">{item?.doctor?.full_name}</h2>
                                <p className="text-[.75rem] ">{item?.doctor?.specialties[0]?.name}</p>

                            </div>
                            {/* <div className="ml-auto flex flex-col h-full justify-between gap-4   ">
                                {item?.status === 'completed' && <button className="text-green-500 px-[3px]    py-[3px] text-[.65rem] bg-green-100 rounded-full ">
                                    {item?.status}
                                </button>}
                                {item?.status === 'started' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-blue-500 rounded-full ">
                                    {item?.status}
                                </button>}

                                {item?.status === 'pending' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-orange-500 rounded-full ">
                                    {item?.status}
                                </button>}

                                {item?.status === 'canceled' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-green-100 rounded-full ">
                                    {item?.status}
                                </button>}

                            </div> */}

                            <div className="flex w-full h-full pb-2    items-center justify-end">

                                {item?.type === 'video' && <button aria-label="video call" className='bg-white p-1 rounded-md'>
                                    <IoVideocam color='black' size={24} className='p-0 m-0' />

                                </button>}
                                {item?.type === 'in-home' && <button aria-label="in-home" className=' bg-white p-1 rounded-md'>
                                    <HiHome color='black' size={24} className='p-0 m-0' />

                                </button>}
                                {item?.type === 'chat' && <button aria-label="chat" className=' bg-white p-1 rounded-md'>
                                    <BiSolidMessageRoundedDetail color='black' size={24} className='p-0 m-0' />

                                </button>}

                                {/* <div className="flex items-center text-green-500">
                                    <CheckCircleIcon fontSize='inherit' className='text-[.69rem]' />
                                    <span className="ml-2 text-[.69rem]">Confirmed</span>
                                </div> */}
                            </div>
                        </div>
                        <Divider />
                        <div className="p-2 h-full flex w-full bg-gray-50">
                            <div className='flex-1 flex justify-between flex-col text-[.8rem] text-[black]'>
                                <div className="flex items-center justify-around  ">

                                    <div className="flex items-center  ">
                                        <HiOutlineCalendarDays />
                                        <span>-{new Date(item?.date).toLocaleDateString()}</span>
                                    </div>
                                    <div className="flex items-center  ">
                                        <LuClock3 />
                                        <span>{item?.time}</span>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <Divider />
                        <div className="p-2 text-center flex justify-center text-2xl font-[700] h-full w-full bg-gray-50">

                            {code}

                        </div>
                        <Divider />
                        <div className="p-2 text-center flex justify-center text-2xl font-[700] h-full w-full bg-gray-50">

                            <Button onClick={handleClose} sx={{ bgcolor: 'white' }} color='inherit' size='small'>
                                Close
                            </Button>

                        </div>
                    </div>


                </div> : " "}

            </Dialog>
        </React.Fragment>
    );
}