import React, { useState } from 'react';
import {
    Box,
    Card,
    Typography,
    Button,
    Tabs,
    Tab,
    Grid,
} from '@mui/material';

// Helper function to convert hour and period into a 24-hour format and validate inputs
const convertTo24Hour = (hour, period) => {
    if (
        typeof hour !== 'number' ||
        hour < 1 ||
        hour > 12 ||
        !['AM', 'PM'].includes(period?.toUpperCase())
    ) {
        return null;
    }
    if (period?.toUpperCase() === 'PM' && hour !== 12) {
        return hour + 12;
    } else if (period?.toUpperCase() === 'AM' && hour === 12) {
        return 0;
    }
    return hour;
};

// Function to get the time category based on the hour
const getTimeCategory = (hour) => {
    hour = hour % 24; // Ensure hour is between 0 and 23
    if (hour >= 5 && hour < 12) {
        return 'Morning';
    } else if (hour >= 12 && hour < 15) {
        return 'Noon';
    } else if (hour >= 15 && hour < 20) {
        return 'Evening';
    } else {
        return 'Night';
    }
};

// Function to generate time slots categorized by time of day
const generateTimeSlots = (
    openTime,
    breakStartTime,
    breakEndTime,
    closeTime
) => {
    const startTime = convertTo24Hour(Number(openTime?.hour), openTime?.period);
    const endTime = convertTo24Hour(Number(closeTime?.hour), closeTime?.period);
    const startBreak = convertTo24Hour(
        Number(breakStartTime?.hour),
        breakStartTime?.period
    );
    const endBreak = convertTo24Hour(
        Number(breakEndTime?.hour),
        breakEndTime?.period
    );

    if (
        startTime === null ||
        endTime === null ||
        startBreak === null ||
        endBreak === null
    ) {
        return null; // Return null if any conversions failed
    }

    let slotsByCategory = {
        Morning: [],
        Noon: [],
        Evening: [],
        Night: [],
    };

    // Adjust for overnight schedules
    let adjustedEndTime = endTime;
    if (endTime <= startTime) {
        adjustedEndTime = endTime + 24;
    }

    for (let time = startTime; time < adjustedEndTime; time += 0.5) {
        let currentHour = time % 24;
        // Skip break times
        if (!(currentHour >= startBreak && currentHour < endBreak)) {
            const category = getTimeCategory(currentHour);

            const displayHour = Math.floor(currentHour) % 12 || 12;
            const minutes = time % 1 === 0 ? '00' : '30';
            const suffix = currentHour >= 12 && currentHour < 24 ? 'pm' : 'am';

            slotsByCategory[category].push(`${displayHour}:${minutes} ${suffix}`);
        }
    }

    return slotsByCategory;
};

// Define the TimeButton component using MUI
const TimeButton = ({ time, schedules, setSchedules }) => {
    const isSelected = schedules?.time === time;
    return (
        <Button
            variant={isSelected ? 'contained' : 'outlined'}
            color={isSelected ? 'primary' : 'inherit'}
            onClick={() => setSchedules({ ...schedules, time: time })}
            sx={{
                width: '102px',
                height: '32px',
                textTransform: 'none',
                ...(isSelected
                    ? {}
                    : {
                        borderColor: 'grey.400',
                        color: 'grey.700',
                    }),
            }}
        >
            {time}
        </Button>
    );
};

// TimeButtonGridTab component using MUI Tabs
const TimeButtonGridTab = ({
    openTime,
    breakStartTime,
    breakEndTime,
    closeTime,
    schedules,
    setSchedules,
}) => {
    const timeSlots = generateTimeSlots(
        openTime,
        breakStartTime,
        breakEndTime,
        closeTime
    );
    const [currentTab, setCurrentTab] = useState(0);

    if (!timeSlots) {
        // Validation check failed
        return null;
    }

    const categories = ['Morning', 'Noon', 'Evening', 'Night'];


    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{ width: '100%', minHeight: '400px' }}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="fullWidth"


                // scrollButtons="auto"
                aria-label="Time Categories"
            >
                {categories.map(
                    (category, index) =>
                        timeSlots[category].length > 0 && (
                            <Tab key={category} label={category} />
                        )
                )}
            </Tabs>

            {categories.map(
                (category, index) =>
                    timeSlots[category].length > 0 && (
                        <TabPanel key={category} value={currentTab} index={index}>
                            <Grid container spacing={2}>
                                {timeSlots[category].map((time, idx) => (
                                    <Grid item xs={4} sm={3} md={2} key={idx} >
                                        <TimeButton
                                            time={time}
                                            schedules={schedules}
                                            setSchedules={setSchedules}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    )
            )}
        </Box>
    );
};

// TabPanel component
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`time-category-tabpanel-${index}`}
            aria-labelledby={`time-category-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

export default TimeButtonGridTab;


