import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import eyePng from "@/../../public/assets/images/Dashboard/eye.png";

function EyeSideNumbers({ patientData }) {
  return (
    <Box sx={{ width: '100%', px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" component="h1" sx={{ fontSize: 17, fontWeight: 'bold' }}>
          Eye Side Numbers
        </Typography>
      </Box>
      <Paper
        elevation={3}
        className='bg-lightCream'
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'around',
          p: 1,
          borderRadius: 1,
          backgroundColor: '#96969a0e',
          boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
        }}
      >
        <Box sx={{ fontSize: 14, fontWeight: 400, display: 'flex', flexDirection: 'column', alignItems: 'end', gap: 0.25 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Left Eye
          </Typography>
          <Typography>R:Sphere {patientData.leftEye.sphere}</Typography>
          <Typography>L:Sphere {patientData.leftEye.sphere}</Typography> {/* Assuming you meant to display the same sphere value, adjust if necessary */}
          <Typography>Cylinder {patientData.leftEye.cylinder}</Typography>
          <Typography>Axis {patientData.leftEye.axis} Degrees</Typography>
          <Typography>Add {patientData.leftEye.add}</Typography>
        </Box>
        <Box
          component="img"
          src={eyePng} // Ensure you have `eyePng` defined or passed as a prop if it's dynamic
          sx={{ width: 50, height: 50 }}
          alt="Eye Diagram"
        />
        <Box sx={{ fontSize: 14, fontWeight: 400, display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0.25 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Right Eye
          </Typography>
          <Typography>R:Sphere {patientData.rightEye.sphere}</Typography>
          <Typography>L:Sphere {patientData.rightEye.sphere}</Typography> {/* Adjust if necessary */}
          <Typography>Cylinder {patientData.rightEye.cylinder}</Typography>
          <Typography>Axis {patientData.rightEye.axis} Degrees</Typography>
          <Typography>Add {patientData.rightEye.add}</Typography>
        </Box>
      </Paper>
    </Box>
  );
}

export default EyeSideNumbers;
