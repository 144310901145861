import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Avatar,
  Button,
  IconButton,
  CircularProgress,
  Box,
  Card,
  CardContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventIcon from "@mui/icons-material/Event";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CloseIcon from "@mui/icons-material/Close";
import { HiHome } from "react-icons/hi2";
import { IoVideocam } from "react-icons/io5";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";

const PaymentConfirmationDialog = ({ open, appointment, handleClose, loading, paymentResult }) => {
  const { profile, schedules, appointmentType } = appointment || {};
  const formattedDate = schedules?.date
    ? new Date(schedules.date).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "No date available";

  function formatTimeRange(time) {
    // Create a Date object for the input time
    const date = new Date(`1970-01-01 ${time}`);

    // If the time cannot be parsed, return an error message
    if (isNaN(date.getTime())) return "Invalid time";

    // Format start time to "hh:mm am/pm"
    const startTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    // Add 30 minutes to get the end time
    date.setMinutes(date.getMinutes() + 30);
    const endTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return `${startTime} - ${endTime}`;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 3,
          boxShadow: 3,
          overflow: "hidden",
          bgcolor: "#f7f9fc",
        },
      }}
    >
      {loading ? (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: 4,
          }}
        >
          <CircularProgress size={80} sx={{ mb: 3 }} />
          <Typography variant="h6">Please wait, confirming your booking...</Typography>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <>
              <button
                onClick={() => handleClose(paymentResult.id)}
                className="absolute top-2 right-2 p-2 text-gray-900 bg-slate-200 rounded-full w-10 h-10 flex items-center justify-center"
              >
                <CloseIcon />
              </button>
            </>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: 2,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 50,
                borderRadius: "50%",
                bgcolor: "#e0f7fa",
                marginBottom: 2,
              }}
            >
              <CheckCircleIcon sx={{ fontSize: 40, color: "#4caf50" }} />
            </Box>

            <Typography variant="h6" gutterBottom>
              Thanks, your booking has been confirmed.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please check your email for receipt and booking details.
            </Typography>

            <Card
              sx={{
                mt: 2,
                p: 1,
                width: "100%",
                bgcolor: "#ffffff",
                boxShadow: 0.5,
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    alt={profile?.full_name}
                    src={profile?.selfie} // Replace with actual image path
                    sx={{ width: 56, height: 56, mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h6">{profile?.full_name}</Typography>
                    <Typography textAlign={"left"} variant="body2" color="textSecondary">
                      {profile?.specialties[0]?.name}
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  {appointmentType === "in-home" && (
                    <>
                      <HiHome size={25} className="my-[1px]" />{" "}
                      <Typography ml={2} variant="body2">
                        In House Consultation
                      </Typography>
                    </>
                  )}
                  {appointmentType === "video" && (
                    <>
                      <IoVideocam size={25} className="my-[1px]" />{" "}
                      <Typography ml={2} variant="body2">
                        Video Consultation
                      </Typography>
                    </>
                  )}
                  {appointmentType === "chat" && (
                    <>
                      <BiSolidMessageRoundedDetail size={25} className="my-[1px]" />{" "}
                      <Typography ml={2} variant="body2">
                        Online Consultation
                      </Typography>
                    </>
                  )}
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <EventIcon sx={{ mr: 2 }} />
                  <div>
                    <Typography variant="body2">{formattedDate}</Typography>
                    <Typography textAlign={"left"} variant="body2">
                      {formatTimeRange(schedules?.time)}
                    </Typography>
                  </div>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Button variant="text" onClick={() => handleClose(paymentResult.id)} fullWidth>
                  See all details
                </Button>
              </CardContent>
            </Card>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default PaymentConfirmationDialog;
