import React, { useState, useEffect } from 'react';
import { Sheet } from 'react-modal-sheet';
import { FaChevronDown } from 'react-icons/fa';
import { FormLabel, TextField, InputAdornment } from '@mui/material';

export default function DropDownSheet({ field, label, options }) {
    const [isOpen, setOpen] = useState(false);

    const initialLabel = options.find(option => Number(option.value) === Number(field.value))?.label;
    const [selectedLabel, setSelectedLabel] = useState(initialLabel);

    useEffect(() => {
        const selectedOption = options.find(option => Number(option.value) === Number(field.value));
        if (selectedOption) {
            setSelectedLabel(selectedOption.label);
        }
    }, [field.value, options]);

    const handleSelect = (value) => {
        field.onChange(value);
        const selectedOption = options.find(option => Number(option.value) === Number(value));
        setSelectedLabel(selectedOption?.label);
        setOpen(false);
    };

    const textFieldStyles = {
        "& .MuiInputBase-root": {
            paddingRight: "1.5rem", // Adjusted padding to fit the icon
            background: "#f8f9fa",
            borderRadius: "4px",
            "& input": {
                fontSize: "15px",
                fontWeight: "400",
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ced4da",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000",
        },
        marginBottom: "8px",
    };

    return (
        <>
            <FormLabel
                sx={{
                    marginBottom: "3px",
                    color: "#010101",
                    fontSize: "14px",
                    fontWeight: "800",
                    display: "block",
                }}
            >
                {label}
            </FormLabel>

            <TextField
                variant="outlined"
                value={selectedLabel || ''}
                onClick={() => setOpen(true)}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FaChevronDown />
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
                sx={textFieldStyles}
            />

            <Sheet snapPoints={[400, 300, 0]} isOpen={isOpen} onClose={() => setOpen(false)}>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="flex flex-col p-4">
                            {options.map(option => (
                                <div
                                    key={option?.value}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleSelect(option?.value)}
                                >
                                    {option?.label}
                                </div>
                            ))}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => setOpen(false)} />
            </Sheet>
        </>
    );
}
