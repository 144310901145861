import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";

import { AiFillThunderbolt } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import { IoMdClock } from "react-icons/io";
import { MdHealthAndSafety } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { BsGraphUp } from "react-icons/bs";

import { Button, Typography } from "@mui/material";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { useDispatch } from "react-redux";
import { getPackages, PurchasePackage } from "./store/PackageSlice";
import LoadingSkeleton from "./Skeleton";
import { useNavigate } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { ArrowBack } from "@mui/icons-material";

// Reusable Feature Item Component
const FeatureItem = ({ Icon, text }) => (
  <Typography
    sx={{
      fontSize: 14,
      color: "#000000B0",
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    }}
  >
    <Icon size={25} color="#003F38" /> {text}
  </Typography>
);

// Mapping of feature keys to their corresponding icons and display texts
const FEATURES_MAPPING = [
  {
    key: "appointment_reminders",
    Icon: FaRegCalendarAlt,
    text: "Appointment Reminders",
  },
  {
    key: "health_tracking",
    Icon: MdHealthAndSafety,
    text: "Health Tracking",
  },
  {
    key: "interactive_dashboard",
    Icon: BsGraphUp,
    text: "Interactive Dashboard",
  },
  {
    key: "priority_doctor_access",
    Icon: RiTeamFill,
    text: "Priority Doctor Access",
  },
  {
    key: "video_consultations",
    Icon: IoMdClock,
    text: "Video Consultations",
  },
  // Add more mappings as needed based on API features
];

function MedoczenPro() {
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [packages, setPackages] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isManagingSubscription, setIsManagingSubscription] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function FetchData() {
    setLoading(true);
    dispatch(getPackages())
      .then((fetched) => {
        const fetchedData = fetched?.data;

        if (fetchedData?.subscription_details?.length > 0) {
          // Subscription exists
          const latestSubscription = fetchedData.subscription_details[0]
          setSelectedPackageId(latestSubscription?.package_id);
          setPackages(fetchedData.result);
          setSubscription(latestSubscription);
        } else if (fetchedData?.result?.length > 0) {
          // No subscription, select the first package by default
          setSelectedPackageId(fetchedData.result[0].id);
          setPackages(fetchedData.result);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching packages:", err);
      });
  }

  useEffect(() => {
    FetchData();
  }, []);

  const handleOptionChange = (packageId) => {
    setSelectedPackageId(packageId);
  };

  const handleGoPremium = (pkg) => {
    dispatch(PurchasePackage(pkg))
      .then(() => {
        alert("Package Purchased Successfully");
        FetchData();
        setIsManagingSubscription(false);
      })
      .catch((error) => {
        console.error("Error purchasing package:", error);
        alert("Failed to purchase the package. Please try again.");
      });
  };

  const selectedPackage = packages?.find((pkg) => pkg.id === selectedPackageId);

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <HeaderWrapper title="Medoczen Pro" path="settings" />

      {/* Main Container */}
      {isManagingSubscription && (
        <div className="w-full flex items-start bg-slate-200 ">
          <Button
            onClick={() => setIsManagingSubscription(false)}
            className="text-blue-500 underline"

          >
            back
          </Button>
        </div>
      )}
      <div className="w-full h-full px-4 py-4 flex flex-col items-center gap-8">


        {/* Disclaimer */}
        <div className="w-full flex flex-col items-start gap-2">
          <h1 className="font-medium text-base">Disclaimer</h1>
          <span className="text-sm font-normal">
            Medoczen Pro offers professionals more freedom, yet imposes
            additional account restrictions. If patients are not satisfied or
            break our terms & conditions.
          </span>
        </div>

        {/* Features */}
        {selectedPackage && (
          <div className="w-full flex flex-col items-start gap-2">
            <h1 className="font-medium text-base">Features</h1>
            <div className="flex flex-col gap-2">
              {FEATURES_MAPPING?.map(({ key, Icon, text }) => {
                if (selectedPackage[key]) {
                  return <FeatureItem key={key} Icon={Icon} text={text} />;
                }
                return null;
              })}
              {/* Handle Additional Features if any */}
              {selectedPackage?.additional_features && (
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#000000B0",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <AiFillThunderbolt size={25} color="#003F38" />{" "}
                  {selectedPackage?.additional_features}
                </Typography>
              )}
            </div>
          </div>
        )}

        {/* Package Options or Subscription Details */}
        {(!subscription || isManagingSubscription) ? (
          <>
            {/* Package Options */}
            <div className="w-full flex flex-wrap justify-center gap-4 mt-4 mb-40">
              {packages?.map((pkg) => {

                return (
                  <label
                    key={pkg.id}
                    htmlFor={`package-${pkg?.id}`}
                    className={`relative flex flex-col justify-between p-4 w-40 h-32 rounded-lg ${selectedPackageId === pkg.id
                      ? "bg-primary text-white cursor-pointer"
                      : "text-black border-2 border-gray-300 cursor-pointer"
                      }`}
                    onClick={() => {

                      handleOptionChange(pkg.id);

                    }}
                  >
                    <input
                      type="radio"
                      id={`package-${pkg?.id}`}
                      name="package"
                      checked={selectedPackageId === pkg?.id}
                      onChange={() => handleOptionChange(pkg?.id)}
                      className="hidden"

                    />
                    <div className="flex items-center gap-2">
                      <AiFillThunderbolt size={18} color="#FFC700" />
                      <span>{pkg?.name}</span>
                    </div>
                    <div className="flex flex-col items-start">
                      <span className="text-sm">
                        £{pkg?.price}
                        {pkg.name.toLowerCase() === "yearly" && (
                          <span className="ml-1 text-xs rounded-full px-2 py-1 bg-white text-black">
                            save 20%
                          </span>
                        )}
                      </span>
                      <span className="text-xs">{pkg?.description}</span>
                    </div>
                    {selectedPackageId === pkg?.id && (
                      <span className="absolute top-1 right-1 w-fit h-fit text-xs rounded-full p-1 bg-white text-black">
                        <FaCheck />
                      </span>
                    )}
                  </label>
                );
              })}
            </div>

            {/* Fixed Purchase Button */}
            {selectedPackage && (
              <div className="fixed bottom-0 left-0 p-2 w-full flex justify-center bg-white z-50">

                <PrimaryButton
                  name={`Go Premium for £${selectedPackage?.price}/${selectedPackage?.name.toLowerCase() === "monthly"
                    ? "month"
                    : "year"
                    }`}
                  className="w-80"
                  fontSize="15px"
                  active
                  action={() => handleGoPremium(selectedPackage)}
                />

              </div>
            )}
          </>
        ) : (
          /* Subscription Details */
          <div className="w-full max-w-md p-6 bg-gray-100 rounded-lg shadow-md mt-4 mb-40">
            <h2 className="text-xl font-semibold mb-4">Your Subscription</h2>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <span className="font-medium">Package:</span>
                <span>{packages.find((pkg) => pkg.id === subscription?.package_id)?.name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Start Date:</span>
                <span>
                  {new Date(subscription?.start_date).toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">End Date:</span>
                <span>
                  {new Date(subscription?.end_date).toLocaleDateString()}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Status:</span>
                <span
                  className={`capitalize ${subscription?.status === "active"
                    ? "text-green-600"
                    : "text-yellow-600"
                    }`}
                >
                  {subscription?.status}
                </span>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <PrimaryButton
                name="Manage Subscription"
                className="w-48"
                fontSize="15px"
                active
                action={() => setIsManagingSubscription(true)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MedoczenPro;
