import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authServiceInstance from "@services/jwtService/index";

export const signupSlice = createSlice({
  name: "signup",
  initialState: {
    full_name: "",
    email: "",
    password: "",
  },
  reducers: {
    signupSuccess: (state, action) => {
      // Directly mutate the state properties
      state.success = action.payload;
      state.error = null; // Reset error state on success
    },
    signupFailure: (state, action) => {
      // Directly mutate the state properties
      state.error = action.payload;
      state.success = null; // Reset success state on failure
    },
  },
});

export const { signupSuccess, signupFailure } = signupSlice.actions;

// Asynchronous thunk action for logging in
export const signup = (credentials) => async (dispatch) => {
  try {
    const data_resp = await authServiceInstance.signUpWithEmailAndPassword({
      ...credentials,
    });
    dispatch(signupSuccess(data_resp));
  } catch (error) {
    dispatch(signupFailure(error));
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = signupSlice.name;

export default signupSlice.reducer;
