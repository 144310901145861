import React from "react";
import { Grid } from "@mui/material";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import SplashLogo from "@/../../public/assets/images/SplashLogo.jpg";

function SplashScreen(props) {
  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/signin");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="w-full py-[50px]"
    >
      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        className="flex"
      >
        <img
          src={SplashLogo}
          style={{ width: "250px", margin: "auto" }}
          alt="splashLogo"
        />
      </Grid>

      <Grid item xs={12} className="py-[50px]" textAlign="center">
        <PrimaryButton active name={"Get Started"} action={ButtonClick} />
      </Grid>
    </Grid>
  );
}

export default SplashScreen;
