import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const packageSlice = createSlice({
  name: "package",
  initialState: {
    package_success: null, // Holds success response or null
    package_error: null, // Holds error message or null
  },
  reducers: {
    getPackageMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    getPackageMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    addPackageMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    addaymentMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    deletePackageMethodSuccess: (state, action) => {
      state.delete_success = action.payload;
      state.delete_error = null;
    },
    deletePackageMethodFailure: (state, action) => {
      state.error = action.payload;
      state.delete_success = null;
    },



  },
});

export const {
  getPackageMethodSuccess,
  getPackageMethodFailure,
  addPackageMethodSuccess,
  addPackageMethodFailure,
  deletePackageMethodSuccess,
  deletePackageMethodFailure,

} = packageSlice.actions;

// Asynchronous thunk action for fetching package data
export const getPackages = (params) => async (dispatch) => {
  try {
    const response = await axios.get("packages/patient",);

    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching package data
export const PurchasePackage = (params) => async (dispatch) => {
  try {
    const response = await axios.post("packages/patient", params);

    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};



// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = packageSlice.name;

export default packageSlice.reducer;
