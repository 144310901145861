import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

export default function DynamicRating(props) {
  const [rating, setRating] = React.useState(props?.value !==undefined ? props?.value :  1);
  const [hover, setHover] = React.useState(-1);

  const handleChange = (event, newRating) => {
    // Limit the minimum rating to 1
    setRating(newRating < 1 ? 1 : newRating);
    props.setValue(props.name, newRating < 1 ? 1 : newRating);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Rating
        name="hover-feedback"
        value={rating}
        sx={{ fontSize: props.size }}
        precision={1}
        onChange={handleChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {/* {rating !== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rating]}</Box>
      )} */}
    </Box>
  );
}
