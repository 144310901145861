import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({ buttonName, title, content, Action, button, value }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const action = () => {
        if (value) {
            Action(...value)
        } else {
            Action()
        }

        handleClose()
    }




    return (
        <React.Fragment>
            {button ? <div className='w-full' onClick={handleClickOpen}>{button}</div> : <Button
                sx={{ marginRight: "20px" }}
                variant="contained"
                color="secondary"
                onClick={() => handleClickOpen()}
            >
                {buttonName}
            </Button>}
            <Dialog
                open={open}
                maxWidth='lg'
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title ? title : "Update Confirmation"}
                </DialogTitle>
                <DialogContent>
                    {content ? content : " "}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => action()} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}