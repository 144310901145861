import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function VitaminLevels({ vitaminsData }) {
  // Assuming the total width of the bar is 300px and the range is 0 to 100 ng/ml
  const totalWidth = 350;
  const maxValue = 100;

  return (
    <Box sx={{ width: '100%', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mb: 2 }}>
        <Typography sx={{ fontSize: '17px', fontWeight: 'bold' }}>
          Fast-soluble Vitamins
        </Typography>
      </Box>

      {vitaminsData?.map((data, index) => {
        // Calculate the position percentage of the vitaminLevel within the range for each vitamin
        const position = (data.level / maxValue) * totalWidth;

        return (
          <Paper className='bg-lightGray' key={index} sx={{
            width: '100%', 
            py: 1, 
            borderRadius: '6px', 
            backgroundColor: '#96969a0e', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
            mb: 2, // Added margin bottom for spacing between each vitamin
          }}>
            <Box sx={{ fontSize: '14px', fontWeight: 400, display: 'flex', width: '100%', px: 2, alignItems: 'end' }}>
              <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>{data.name}</Typography>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.58)', marginLeft: 1 }}>(ng/ml)</Typography>
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', width: 350, mt: 1 }}>
              <VitaminLevel label="Very Low" range="20" />
              <VitaminLevel label="Low" range="21-40" />
              <VitaminLevel label="Optimal" range="40-80" />
              <VitaminLevel label="Elevated" range="80-100" />
              <VitaminLevel label="High" range="100" />
            </Box>

            <Box sx={{ position: 'relative', width: 350, mt: 1 }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 70px)', gap: 0 }}>
                <Box sx={{ bgcolor: '#CC4264', height: '35px', borderRadius: '40px 0 0 40px' }}></Box>
                <Box sx={{ bgcolor: '#FFBE02', height: '35px' }}></Box>
                <Box sx={{ bgcolor: '#BDDC05', height: '35px' }}></Box>
                <Box sx={{ bgcolor: '#FFBE02', height: '35px' }}></Box>
                <Box sx={{ bgcolor: '#CC4264', height: '35px', borderRadius: '0 40px 40px 0' }}></Box>
              </Box>
              <Box sx={{
                position: 'absolute',
                top: '4px',
                left: `${position - 15}px`, // Adjust left position based on calculation
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: 1
              }}>
                <Typography sx={{ fontSize: '12px', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                  {data.level}ng/ml
                </Typography>
              </Box>
            </Box>
          </Paper>
        );
      })}
    </Box>
  );
}

function VitaminLevel({ label, range }) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography sx={{ fontWeight: 600, fontSize: '8px' }}>{label}</Typography>
      <Typography sx={{ fontSize: '8px' }}>{range}</Typography>
    </Box>
  );
}

export default VitaminLevels;