import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FormControl, Typography } from "@mui/material";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { IoIosArrowDown } from "react-icons/io";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import { useDispatch } from "react-redux";
import { getTroubleShootGuides } from "../store/helpSupportSlice";
import Skeletons from "./Skeletons";

// Define an array with questions and answers
// const TroubleShootGuideData = [
//   {
//     question: "How can I raise a ticket?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
//   },
//   {
//     question: "How can I change password?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
//   },
//   // Add more questions and answers here
// ];

function TroubleShootGuide() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [TroubleShootGuideData, setTroubleShootGuideData] = useState([]);
  function FetchData() {
    dispatch(getTroubleShootGuides()).then((res) => {
      setTroubleShootGuideData(res?.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    FetchData();
  }, []);

  return (
    <>
      <HeaderWrapper title="TroubleShooting Guide" path="help-support"></HeaderWrapper>
      {loading ? (
        <Skeletons />
      ) : (
        <>
          <div className="flex items-center w-full px-[1rem]  mt-[1rem] ">
            <SearchBar placeholder="Search Here" />
          </div>
          <div className="w-full flex flex-col items-center py-[2rem]">
            {TroubleShootGuideData?.map((TroubleShootGuide, index) => (
              <Accordion sx={{ width: "100%" }} key={index}>
                <AccordionSummary
                  style={{ height: "4rem" }}
                  expandIcon={<IoIosArrowDown size={20} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography style={{ fontWeight: "bold" }}>{TroubleShootGuide?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{TroubleShootGuide?.description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default TroubleShootGuide;
