import React from "react";
import { IoVideocam } from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { Rating, Box, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";

//countryCode: The country code to fetch the flag for
const CountryFlag = ({ country_code }) => {
  // Fetch the flag source based on the country code
  const flagSrc = GetFlagByCountryCode(country_code);

  // If no flag source is found, return null or a placeholder
  if (!flagSrc) return null; // or some placeholder

  // Render the flag image with appropriate styling
  return (
    <img
      src={flagSrc}
      className="absolute top-[5px] left-[5px] w-[15px] h-[10px]"
    />
  );
};

function toLowercaseHyphenateAndLabel(text) {

  return text?.toLowerCase() // Convert all characters to lowercase
    .replace(/\s+/g, '-') // Replace all spaces with hyphens
    + '-label'; // Append '-label' at the end
}


function DoctorTab({ doctors }) {

  return (
    <>
      {<>
        {doctors.map((doctor, index) => (
          <Link
            to={`/doctor-profile/${doctor?.id}`}
            className="w-full px-3 mt-[1rem]"
            key={index}
          >
            <Box className={`relative  px-[10px] py-[10px] rounded-[6px] ${doctor.nhs_eligible ? "bg-lightCream" : "bg-[#FAFAFA]"} flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full`}>
              <div className="relative flex flex-col ">

                <Avatar src={doctor.selfie} variant="square" sx={{ width: 73, height: 73, borderRadius: 1.5 }} />
                <CountryFlag country_code={doctor.country_code} />
              </div>

              <Box className="flex flex-col justify-center w-full ml-[8px]">
                <span className="text-[16px] font-[600]">{doctor.full_name}</span>
                <span
                  className={`text-[11px] font-[700] rounded-[3px] text-white text-left  ${doctor.specialties[0]?.name !== undefined && toLowercaseHyphenateAndLabel(doctor.specialties[0]?.name)} py-[2px] px-[4px] inline-block w-fit`}
                >
                  {doctor.specialties[0]?.name}

                </span>
                <Box className="flex items-center gap-1">
                  <Rating
                    name="half-rating-read"
                    defaultValue={4.5}
                    size="small"
                    precision={0.5}
                    readOnly
                  />
                  <span className="text-xs font-semibold">(100)</span>
                </Box>
                <span className="text-[12px] font-[500] lowercase">
                  {doctor?.schedule?.open_time?.hour ? <>  {doctor?.schedule?.open_time?.hour}{doctor?.schedule?.open_time?.period} -{doctor?.schedule?.close_time?.hour}{doctor?.schedule?.open_time?.period}</> : "not available"}
                </span>
              </Box>
              <>
                <Box className="flex flex-col justify-center text-[#616161]  mr-[20px]">
                  {doctor?.schedule?.in_home && <HiHome size={15} className="my-[1px]" />}
                  {doctor?.schedule?.video_call && <IoVideocam size={15} className="my-[1px]" />}
                  {doctor?.schedule?.chat && <BiSolidMessageRoundedDetail size={15} className="my-[1px]" />}
                </Box>
                {doctor?.schedule && <Box className="text-[#616161] flex flex-col items-center">
                  <Box className="ps-[2px]">
                    <GiTwoCoins size={30} />
                  </Box>


                  <span className="font-[500] text-[12px] text-black mt-[1px]">
                    £{doctor?.schedule?.rate}/hr
                  </span>
                </Box>}
              </>
            </Box>
          </Link>
        ))}
      </>}
    </>
  );
}

export default DoctorTab;
