import React, { useEffect, useState, useMemo } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { getPreferredPayment, MakePayment } from "../store/PaymentSlice";
import { Box, Skeleton, Divider, IconButton, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import creditCardPng from "@/../../public/assets/images/payment/creditcard.png";
import { HiHome } from "react-icons/hi2";
import { IoVideocam } from "react-icons/io5";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { GoChevronLeft } from "react-icons/go";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { GiTwoCoins } from "react-icons/gi";
import { showToast } from "pages/Appointments/BookAppointment/store/bookAppointmentSlice";
import { getPersonalDetails } from "pages/Settings/Personal-details/store/personalDetailsSlice";
import SwipeableButton from "@medoczen/core/SwipeableButton/SwipeableButton";
import PaymentConfirmationDialog from "./PaymentConfirmationDialog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function StripePayment() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [open, setOpen] = useState(false);
  const selectUser = useSelector((state) => state.personalDetails.personalDetailsSlice);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [paymentState, setPaymentState] = useState(() => {
    const state = sessionStorage.getItem("payment_state");
    return state ? JSON.parse(state) : null;
  });

  useEffect(() => {
    if (!paymentState) {
      navigate("/doctors-list"); // Redirect if payment state is missing
    }
  }, [paymentState, navigate]);

  useEffect(() => {
    setUser(selectUser?.personalDetails_success);
  }, [selectUser]);

  const { profile, schedules, appointmentType } = paymentState || {};

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getPreferredPayment());
      setCardData(res?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentState) {
      fetchData();
      dispatch(getPersonalDetails());
    }
  }, [dispatch, paymentState]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setPaymentLoading(true);
    setOpen(true);

    try {
      const paymentData = {
        appointment_deatils: {
          doctor_id: profile.id,
          date: schedules.date,
          time: schedules.time,
          doctor: profile,
          patient: user,
          status: "pending",
          type: appointmentType,
        },
        payment_details: {
          payment_method: cardData.id,
          amount: Math.round(schedules.rate * 100), // Example amount in cents (£100.00)
          currency: "GBP",
          return_url: "https://www.patient-android.medoczen.uk/signin",
        },
      };

      const res = await dispatch(MakePayment(paymentData));
      if (res.status === 200) {
        // dispatch(showToast({ message: "Payment successful", type: "success" }));
        setPaymentResult(res.data);

        setPaymentLoading(false);

        // sessionStorage.removeItem("payment_state");
      } else {
        dispatch(showToast({ message: "Payment failed", type: "error" }));
      }
    } catch (error) {
      console.error("Payment error:", error);
      dispatch(
        showToast({ message: "An error occurred during payment. Please try again.", type: "error" })
      );
    }
  };

  function handleDialogClose(id) {
    navigate(`/appointments/${id}`);
    setOpen(false);
  }

  const formattedDate = useMemo(() => {
    return schedules?.date ? new Date(schedules.date).toLocaleDateString() : "No date available";
  }, [schedules?.date]);

  return (
    <div className="h-full">
      <div className="inset-0 bg-primary w-full h-[100px] sticky flex flex-col items-center justify-center z-[99]">
        <div className="grid grid-cols-[auto_1fr_auto] mt-[-50px] w-full max-w-4xl px-4 items-center">
          <div className="flex justify-start">
            <button
              onClick={() => navigate(-1)}
              className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
            >
              <GoChevronLeft color="white" size={26} />
            </button>
          </div>
          <div className="flex justify-center">
            <h1 className="font-[600] text-lg text-white">Payment</h1>
          </div>
          <div className="flex justify-end min-w-[1rem]"></div>
        </div>
        <div className="absolute top-[3.5rem]">
          {loading ? (
            <SkeletonCard />
          ) : (
            <PaymentCard
              profile={profile}
              schedules={schedules}
              appointmentType={appointmentType}
            />
          )}
        </div>
      </div>
      <div className="sticky top-[6.3rem] pt-[3rem] bg-white flex justify-around items-center px-3 py-3 w-full gap-3 z-[90]">
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "100%" }} height={80} />
        ) : (
          <InfoBox title="Date" content={formattedDate} bgColor="#E4C1A6" />
        )}
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "100%" }} height={80} />
        ) : (
          <InfoBox
            title="Time Slot"
            content={schedules?.time || "No time available"}
            bgColor="#B6B0E7"
          />
        )}
      </div>
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem] mt-[1rem]">
        <div className="w-full">
          {cardData && (
            <div className="w-full flex gap-[1rem] justify-end mb-[1rem] mx-auto">
              <Link to={"/payment-method"} className="font-semibold text-primary text-[12px]">
                Manage payment Methods{" "}
              </Link>
            </div>
          )}
          {loading ? (
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={70} />
          ) : (
            <CardDisplay cardData={cardData} />
          )}
        </div>
      </div>
      <div className="fixed bottom-0 w-full bg-white h-fit border-[1px] bg-[#96969a0e] shadow-[0_8px_30px_rgb(0,0,0,0.12)] pb-[1rem] flex flex-col gap-[1rem]">
        {loading ? (
          <div className="w-full flex justify-center">
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={130} />
          </div>
        ) : (
          <TotalAccordion schedules={schedules} />
        )}
        <div className="w-full flex justify-center">
          {/* <PrimaryButton
                        active={!!cardData}
                        disabled={!cardData}
                        name="Continue"
                        className="w-[244px] mx-auto"
                        fontSize="18px"
                        action={handleSubmit}
                    /> */}
          <SwipeableButton
            active={!!cardData}
            disabled={!cardData}
            name="Swipe to Complete"
            text_unlocked={
              <CircularProgress
                size={32}
                color="inherit"
                sx={{ color: "white", marginTop: "8px" }}
              />
            }
            action={handleSubmit}
          />
        </div>
      </div>

      <PaymentConfirmationDialog
        open={open}
        handleClose={handleDialogClose}
        loading={paymentLoading}
        appointment={paymentState}
        formattedDate={formattedDate}
        paymentResult={paymentResult}
      />
    </div>
  );
}

function SkeletonCard() {
  return (
    <Box className="relative px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
      <div className="relative flex flex-col w-fit">
        <Skeleton variant="rectangular" width={100} height={60} />
      </div>
      <Box className="flex flex-col justify-around gap-3 w-full ml-[8px]">
        <Skeleton variant="body1" width={130} height={10} />
        <Skeleton variant="body1" width={130} height={10} />
        <Skeleton variant="body1" width={130} height={10} />
      </Box>
      <Skeleton variant="rectangular" width={100} height={60} />
    </Box>
  );
}

function PaymentCard({ profile, schedules, appointmentType }) {
  const labelClass = useMemo(() => {
    if (profile?.specialties[0]?.name) {
      return profile.specialties[0].name.toLowerCase().replace(/\s+/g, "-") + "-label";
    }
    return "";
  }, [profile]);

  return (
    <Box className="relative px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
      <div className="relative flex flex-col w-fit">
        <img src={profile?.selfie} className="w-[130px] rounded-[6px]" alt="" />
      </div>
      <Box className="flex flex-col justify-center w-full ml-[8px]">
        <span className="text-[16px] font-[600]">{profile?.full_name}</span>
        <span
          className={`text-[11px] font-[700] rounded-[3px] text-white text-left ${labelClass} py-[2px] px-[4px] inline-block w-fit`}
        >
          {profile?.specialties[0]?.name}
        </span>
        <span className="text-[12px] font-[500] lowercase">
          {profile?.schedule?.open_time?.hour
            ? `${profile?.schedule?.open_time?.hour}${profile?.schedule?.open_time?.period} - ${profile?.schedule?.close_time?.hour}${profile?.schedule?.close_time?.period}`
            : "Not available"}
        </span>
      </Box>
      <Box className="flex flex-col justify-center text-[#616161] mr-[20px]">
        {appointmentType === "in-home" && <HiHome size={25} className="my-[1px]" />}
        {appointmentType === "video" && <IoVideocam size={25} className="my-[1px]" />}
        {appointmentType === "chat" && (
          <BiSolidMessageRoundedDetail size={25} className="my-[1px]" />
        )}
      </Box>
      <Box className="text-[#616161] flex flex-col items-center">
        <IconButton className="ps-[2px]">
          <GiTwoCoins size={30} />
        </IconButton>
        <span className="font-[500] text-[12px] text-black mt-[1px]">£{schedules?.rate || ""}</span>
      </Box>
    </Box>
  );
}

function InfoBox({ title, content, bgColor }) {
  return (
    <div
      className={`text-[16px] font-[600] w-full h-[80px] rounded-[8px] flex flex-col justify-center items-center`}
      style={{ backgroundColor: bgColor }}
    >
      <h1>{title}</h1>
      <span>{content}</span>
    </div>
  );
}

function CardDisplay({ cardData }) {
  if (!cardData) return null;

  return (
    <div
      className={`relative w-full h-fit px-[10px] py-[1rem] rounded-[10px] border-2 text-[#000000a8] flex items-center gap-[1.5rem] mx-auto`}
    >
      <div className="text-[14px] font-[500] flex gap-[1rem] justify-start items-center">
        <img src={creditCardPng} className="w-[60px] h-[40px] object-cover border-2" alt="" />
        <span className="font-[600] text-[19px]">{`**** ${cardData?.card?.last4}`}</span>
        <span className="font-[600] text-[19px] text-[#A4A9AE]">
          {String(cardData?.card?.exp_month).padStart(2, "0")}/
          {String(cardData?.card?.exp_year).slice(-2)}
        </span>
      </div>
      <span className="bg-black text-white p-1 rounded-md font-semibold text-[13px] w-fit h-fit">
        Preferred
      </span>
    </div>
  );
}

function TotalAccordion({ schedules }) {
  return (
    <Accordion sx={{ boxShadow: "none" }}>
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <div className="text-[16px] font-[600] w-full flex justify-between px-[2rem]">
          <span>Total</span>
          <span>£{Number(schedules?.rate) + 3}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="h-fit flex flex-col gap-2">
          <div className="text-[16px] font-[500] w-full flex justify-between px-[2rem]">
            <span>Doctor Fee</span>
            <span>£{schedules?.rate}</span>
          </div>
          <div className="text-[16px] font-[500] w-full flex justify-between px-[2rem]">
            <span>Tax</span>
            <span>£2</span>
          </div>
          <div className="text-[16px] font-[500] w-full flex justify-between px-[2rem]">
            <span>Platform Fee</span>
            <span>£1</span>
          </div>
          <Divider />
          <div className="text-[16px] font-[600] w-full flex justify-between px-[2rem]">
            <span>Total</span>
            <span>£{Number(schedules?.rate) + 3}</span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
