import { GetFlagByCountryCode } from '@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode';
import { Avatar, Divider } from '@mui/material';
import React from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LuClock3 } from 'react-icons/lu';
import { HiOutlineCalendarDays } from 'react-icons/hi2';
import { IoVideocam } from 'react-icons/io5';
import { HiHome } from "react-icons/hi";
import { BiSolidMessageRoundedDetail } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';

export default function AppointmentTab({ item }) {
  const navigate = useNavigate()
  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ country_code }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(country_code);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img
        src={flagSrc}
        className="absolute bottom-0 right-[-2px] w-[1.2rem] object-cover h-[1.2rem] rounded-full bg-white p-[1px]"
        alt="flag"
      />
    );
  };

  const status = item?.status

  function HandleClick() {

    navigate(`/appointments/${item.id}`)
  }

  return (
    <div onClick={HandleClick} className=" mx-auto w-full bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
      <div className={`flex items-center  p-2 ${status === 'completed' ? 'bg-gray-50 text-[black]' : 'bg-primary text-white'}  `}>
        <div className='relative'>
          <Avatar
            alt={item?.doctor?.full_name}
            src={item?.doctor?.selfie}
            sx={{ width: 62, height: 62, borderRadius: 1 }}
            variant='square'
            className="border-2 border-white"
          />
          <CountryFlag country_code={item?.doctor?.country_code} />
        </div>
        <div className="ml-4">
          <h2 className=" text-[1rem] font-[600]">{item?.doctor?.full_name}</h2>
          <p className="text-[.75rem] ">{item?.doctor?.specialties[0]?.name}</p>

        </div>
        <div className="ml-auto flex flex-col h-full justify-between gap-4   ">
          {item?.status === 'completed' && <button className="text-green-500 px-[3px]    py-[3px] text-[.65rem] bg-green-100 rounded-[3px] ">
            {item?.status}
          </button>}
          {item?.status === 'started' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-blue-500 rounded-[3px] ">
            {item?.status}
          </button>}

          {item?.status === 'pending' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-orange-500 rounded-[3px] ">
            {item?.status}
          </button>}

          {item?.status === 'canceled' && <button className="text-white px-2    py-[3px] text-[.65rem] bg-green-100 rounded-[3px] ">
            {item?.status}
          </button>}
          <p className="text-[1.25rem] ">£{item?.doctor?.schedule?.rate}/hr</p>
        </div>
      </div>
      <Divider />
      <div className="p-2 h-full flex w-full bg-gray-50">
        <div className='flex-1 flex justify-between flex-col text-[.75rem] text-[#1d1d1d]'>
          <div className="flex items-center  ">
            <HiOutlineCalendarDays />
            <span>Scheduled -{new Date(item?.date).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center  ">
            <LuClock3 />
            <span>{item?.time}</span>
          </div>
        </div>
        <div className="flex flex-col flex-1  items-end justify-between">
          <div className='flex gap-1'>
            {item?.type === 'video' && <button aria-label="video call" className=' bg-blue-600 p-1 rounded-md'>
              <IoVideocam color='white' className='p-0 m-0' />

            </button>}
            {item?.type === 'in-home' && <button aria-label="in-home" className=' bg-blue-600 p-1 rounded-md'>
              <HiHome color='white' className='p-0 m-0' />

            </button>}
            {item?.type === 'chat' && <button aria-label="chat" className=' bg-blue-600 p-1 rounded-md'>
              <BiSolidMessageRoundedDetail color='white' className='p-0 m-0' />

            </button>}
          </div>
          <div className="flex items-center text-green-500">
            <CheckCircleIcon fontSize='inherit' className='text-[.69rem]' />
            <span className="ml-2 text-[.69rem]">Confirmed</span>
          </div>
        </div>
      </div>
    </div>

  )
}
















// <Link to={`/appointments/${item.id}`} key={item.id} className="relative w-full h-auto p-4 rounded-[3px] bg-lightCream flex items-center shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
// <div className="flex flex-col items-center w-16 mr-4">
//   <img src={item?.patient?.selfie} alt="" className="w-16 h-16 rounded-full border-4 border-gray-200 shadow-sm" />
//   <CountryFlag country_code={item?.patient?.country_code} className="mt-2" />
// </div>
// <div className="flex-grow flex flex-col justify-between">
//   <h1 className="text-xl font-semibold text-gray-800">{item?.patient?.full_name}</h1>
//   <div className="flex items-center text-gray-600">
//     <GiConfirmed size={16} />
//     <span className="text-base font-medium">Confirmed</span>
//     <span className="ml-2 flex items-center justify-center px-2 py-1 h-6 rounded text-xs text-white bg-green-500">
//       {item?.type}
//     </span>
//   </div>
//   <div className="text-sm text-gray-500 flex items-center">
//     <MdViewTimeline size={14} />
//     <span>{item?.time} - {new Date(item?.date).toLocaleDateString()}</span>
//   </div>
// </div>
// <div className='text-gray-600 flex flex-col items-center' ><GiTwoCoins size={30} /> <span className='font-[500]'>£{item?.doctor?.schedule?.rate}/hr</span></div>
// <div className="absolute right-0 top-0 px-3 py-1 text-xs text-white  bg-orange-500">
//   {item?.status}
// </div>
// </Link>