import DynamicRating from '@medoczen/core/DynamicRating'
import HeaderWrapper from '@medoczen/core/Header/HeaderWrapper'
import InputField from '@medoczen/core/InputField/InputField'
import PrimaryButton from '@medoczen/core/PrimaryButton/PrimaryButton'
import { Divider, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { AddUserReview } from './store/reviewsSlice'

export default function AddReview() {
    const location = useLocation()
    const { doctor_id, appointment_id } = location.state

    const {
        control,
        reset,
        setValue,
        watch,
        formState: { errors, isDirty },
    } = useForm();
    const form = watch();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function HandleSubmit() {
        dispatch(AddUserReview(form)).then(() => {
            navigate(-1)
        })

    }

    useEffect(() => {

        setValue("doctor_id", doctor_id)
        setValue("appointment_id", appointment_id)

    }, [])

    return (
        <div>
            <HeaderWrapper title="Add Review" />
            <div className='mt-[1rem] px-[1rem] flex flex-col mb-[1rem]'>

            </div>
            <Divider textAlign="left" > <h1 className='font-[500] text-[16px]'>Communication skills</h1></Divider>
            <div className='flex flex-col justify-start items-center my-[1rem]'>
                <DynamicRating name="communication_skills" value={form?.communication_skills} setValue={setValue} size={40} />
            </div>

            <Divider textAlign="left" > <h1 className='font-[500] text-[16px]'>Professionalism and behavior</h1></Divider>
            <div className='flex flex-col justify-start items-center my-[1rem]'>
                <DynamicRating name="professionalism_and_behavior" value={form?.professionalism_and_behavior} setValue={setValue} size={40} />
            </div>

            <Divider textAlign="left" > <h1 className='font-[500] text-[16px]'>Outcome and satisfaction</h1></Divider>
            <div className='flex flex-col justify-start items-center my-[1rem]'>
                <DynamicRating name="outcome_and_satisfaction" value={form?.outcome_and_satisfaction} setValue={setValue} size={40} />
            </div>

            <Divider textAlign="left" > <h1 className='font-[500] text-[16px]'>Digital experience</h1></Divider>
            <div className='flex flex-col justify-start items-center my-[1rem]'>
                <DynamicRating name="digital_experience" value={form?.digital_experience} setValue={setValue} size={40} />
            </div>
            <hr />
            <div className='mt-[2rem] px-[1rem] flex flex-col mb-[1rem]'>
                <h1>Tell us more (optional)</h1>
            </div>
            <div className='flex flex-col justify-start items-center px-5'>


                <div className="w-full">
                    <div className="mb-3">
                        <Controller
                            name="review"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputField {...field} multiline minRows={5} className="mb-2" />
                            )}
                        />
                    </div>


                </div>


                <div className='fixed bottom-5 w-full flex justify-center items-center '>
                    <PrimaryButton
                        active
                        name="Submit"
                        action={HandleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}
