import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { Avatar, Divider, Skeleton } from "@mui/material";
import {
  getAppointments,
  getUpcomingAppointment,
  selectUpcomingAppointments,
} from "pages/Appointments/AppointmentsList/store/AppointmentSlice";
import React, { useEffect, useState } from "react";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";

import { HiHome } from "react-icons/hi";
import { HiOutlineCalendarDays } from "react-icons/hi2";

import { IoVideocam } from "react-icons/io5";
import { LuClock3 } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
function SkeletonComponent() {
  return (
    <div className="w-full h-[155px] px-[10px] py-[1rem] rounded-[6px] bg-[#96969a0e]  flex items-center gap-[1rem] shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
      <div className="">
        <Skeleton width={130} height={200} />
      </div>

      <div className="flex flex-col gap-2">
        <Skeleton width={122} height={30} />
        <Skeleton width={122} height={15} />
        <Skeleton width={122} height={15} />
        <Skeleton width={122} height={15} />
      </div>
    </div>
  );
}

export default function UpcomingAppointment() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [item, setItem] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(getUpcomingAppointment()).then((res) => {
      console.log("res", res);
      setItem(res.payload);

      setLoading(false);
    });
  }, [dispatch]);

  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ country_code }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(country_code);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img src={flagSrc} className="absolute top-[2px] left-[2px] w-[18px] h-[12px]" alt="flag" />
    );
  };

  const status = item?.status;

  return (
    <>
      {loading ? (
        <SkeletonComponent />
      ) : (
        <>
          <Link
            to={`/appointments/${item.id}`}
            key={item.id}
            className=" mx-auto w-full flex flex-col justify-start  bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
          >
            <div
              className={`flex items-center  p-2 ${
                !status === "completed" ? "bg-gray-50 text-[black]" : "bg-primary text-white"
              }  `}
            >
              <div className="relative">
                <Avatar
                  alt={item?.doctor?.full_name}
                  src={item?.doctor?.selfie}
                  variant="square"
                  sx={{ width: 62, height: 62, borderRadius: 1 }}
                  className="border-2 border-white"
                />
                <CountryFlag country_code={item?.doctor?.country_code} />
              </div>
              <div className="ml-4">
                <h2 className=" text-[1rem] font-[600]">{item?.doctor?.full_name}</h2>
                <p className="text-[.75rem] ">{item?.doctor?.specialties[0]?.name}</p>
              </div>
              <div className="ml-auto flex flex-col h-full justify-between gap-4   ">
                {item?.status === "completed" && (
                  <button className="text-green-500 px-[3px]    py-[3px] text-[.65rem] bg-green-100 rounded-[3px]">
                    {item?.status}
                  </button>
                )}
                {item?.status === "started" && (
                  <button className="text-white px-2    py-[3px] text-[.65rem] bg-blue-500 rounded-[3px] ">
                    {item?.status}
                  </button>
                )}

                {item?.status === "pending" && (
                  <button className="text-white px-2    py-[3px] text-[.65rem] bg-orange-500 rounded-[3px] ">
                    {item?.status}
                  </button>
                )}

                {item?.status === "canceled" && (
                  <button className="text-white px-2    py-[3px] text-[.65rem] bg-green-100 rounded-[3px] ">
                    {item?.status}
                  </button>
                )}
                <p className="text-[1.25rem] ">£{item?.doctor?.schedule?.rate}/hr</p>
              </div>
            </div>
            <Divider />
            <div className="p-2 h-full flex w-full bg-gray-50">
              <div className="flex-1 flex justify-between flex-col text-[.75rem] text-[#8e92a3]">
                <div className="flex items-center  ">
                  <HiOutlineCalendarDays />
                  <span>Scheduled -{new Date(item?.date).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center  ">
                  <LuClock3 />
                  <span>{item?.time}</span>
                </div>
              </div>
              <div className="flex flex-col flex-1  items-end justify-between">
                <div className="flex gap-1">
                  {item?.type === "video" && (
                    <button aria-label="video call" className=" bg-blue-600 p-1 rounded-md">
                      <IoVideocam color="white" className="p-0 m-0" />
                    </button>
                  )}
                  {item?.type === "in-home" && (
                    <button aria-label="in-home" className=" bg-blue-600 p-1 rounded-md">
                      <HiHome color="white" className="p-0 m-0" />
                    </button>
                  )}
                  {item?.type === "chat" && (
                    <button aria-label="chat" className=" bg-blue-600 p-1 rounded-md">
                      <BiSolidMessageRoundedDetail color="white" className="p-0 m-0" />
                    </button>
                  )}
                </div>
                <div className="flex items-center text-green-500">
                  <CheckCircleIcon fontSize="inherit" className="text-[.69rem]" />
                  <span className="ml-2 text-[.69rem]">Confirmed</span>
                </div>
              </div>
            </div>
          </Link>
        </>
      )}
    </>
  );
}
