import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import { Button } from "@mui/material";
import DoctorSpecialtiesBoxTab from "./DoctorSpecialtiesBoxTab";
import { getDoctorSpecialties } from "./store/doctorSpecialtiesSlice";

// Create an array of 10 items for the skeletons
const skeletons = Array.from({ length: 10 }, (_, index) => index + 1);

function DoctorSpecialties(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectUser = useSelector(
    (state) => state.doctorSpecialties.doctorSpecialtiesSlice,
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (selectUser?.doctorSpecialties_success) {
      setLoading(false);
      setData(selectUser?.doctorSpecialties_success);
    } else if (selectUser?.doctorSpecialties_error) {
      setLoading(false);

      if (
        selectUser?.doctorSpecialties_error?.name === "NotAuthorizedException"
      ) {
        setSnackbarMessage("Username/Password is incorrect");
        setSnackbarOpen(true);
      } else if (
        selectUser?.doctorSpecialties_error?.CodeDeliveryDetails?.Destination
      ) {
        // Handle the success scenario of code being sent
        setSnackbarMessage(
          `Code sent to ${selectUser.doctorSpecialties_error.CodeDeliveryDetails.Destination}`,
        );
        setSnackbarOpen(true);
      } else if (
        selectUser.doctorSpecialties_error.name === "LimitExceededException"
      ) {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`,
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  useEffect(() => {
    setLoading(true);
    dispatch(getDoctorSpecialties());
  }, []);

  const appointmentBoxes = data?.map((specialist) => {

    return (
      <DoctorSpecialtiesBoxTab
        key={specialist.id}
        id={specialist.id}
        image={specialist?.icon}
        name={specialist.name}
      />
    );
  });

  return (
    <>
      <HeaderWrapper path="dashboard" title="Medical Categories" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full mb-[5rem] bg-[#F3F3F3]">
        <SearchBar placeholder="Search Here" />

        <div className="flex flex-col justify-start w-full ">
          <div className="grid grid-cols-2 gap-[1rem] mt-[1rem]">
            {/* Loading Skeleton animation */}
            {loading && (
              <>
                {skeletons.map((skeleton, i) => (
                  <div
                    key={i}
                    className="h-[123px] min-w-[170px] rounded-[14px] flex flex-col justify-center items-center shadow-[10px_10px_30px_0px_#cbd5e0]"
                  >
                    <Skeleton
                      style={{ marginBottom: 6 }}
                      variant="circular"
                      width={60}
                      height={60}
                    />
                    <Skeleton variant="rectangular" width={80} height={20} />
                  </div>
                ))}
              </>
            )}

            {appointmentBoxes}
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorSpecialties;
