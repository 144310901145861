import VideoCall from "@medoczen/core/Agora/VideoCall";
import React from "react";

import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

function ChatHeader(props) {
  const appointment_details = JSON.parse(sessionStorage.getItem("appointment_details"));
  return (
    <div className="fixed top-0 w-full z-[99]">
      <div className="relative w-full h-[80px] bg-white shadow-xl rounded-b-[30px]">
        <div className="absolute bottom-6 w-full flex justify-between px-[2rem]">
          <div className="flex  items-center gap-4">
            <Link to={(-1)} >
              <FaArrowLeftLong size={18} />
            </Link>
            <img
              src={appointment_details?.doctor?.selfie}
              className="w-[40px] h-[40px] rounded-full"
              alt=""
            />

            <div className="flex flex-col">
              <h1 className="font-[700] text-[14px] ">{appointment_details?.doctor?.full_name}</h1>
              <span className="font-[500] text-[12px]">{appointment_details?.doctor?.specialties[0]?.name}</span>
            </div>
          </div>

          <div className="flex items-center gap-6 text-primary text-[20px]">

            <VideoCall channel={appointment_details?.channel_name} />


          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
