import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

function SettingsLinks(props) {
  // Ensure you destruct `path` from props and use it in the `to` prop of `Link`
  const { path, icon, name } = props;

  return (
    <Link to={path} className="flex items-center relative">
      <span className="mr-[1rem]">{icon}</span>
      <span className="font-[600] text-[14px]">{name}</span>
      <div className="absolute right-2">
        <IoIosArrowForward size={20} />
      </div>
    </Link>
  );
}

export default SettingsLinks;
