import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Wrap the function component with React.forwardRef
const InputField = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    icon,
    required,
    endIcon,
    placeholder,
    maxLength,
    disabled,
    type,
    error,
    onChange,
    onBlur,
    value,
    className,
    multiline,
    rows,
    maxRows,
    minRows,
  } = props;

  const textFieldStyles = {
    "& .MuiInputBase-root": {
      paddingRight: endIcon ? "1.5rem" : "0rem",
      paddingLeft: multiline || icon ? "0 rem" : "0px",
      background: "#f8f9fa",
      border: "6px #000000",
      "& input": {
        fontSize: "15px",
        fontWeight: "400",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {},
    marginBottom: "8px",
  };

  return (
    <FormControl
      fullWidth
      className={className}
      variant="outlined"
      component="div"
      error={!!error}
    >
      {label && (
        <FormLabel
          htmlFor={name}
          sx={{
            marginBottom: "3px",
            color: "#010101",
            fontSize: "14px",
            fontWeight: "800",
            display: "block",
          }}
        >
          {label}
        </FormLabel>
      )}
      <TextField
        id={name}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        helperText={error || ""}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        fullWidth
        required={required}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          inputProps: {
            maxLength: maxLength,
          },
        }}
        sx={textFieldStyles}
        ref={ref} // Pass the ref to the TextField
      />
    </FormControl>
  );
});

export default InputField;
