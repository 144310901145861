import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fab, Skeleton } from "@mui/material";
import ReviewComponent from "./reviews/ReviewComponent";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "./reviews/store/reviewsSlice";
import Pharmaceuticals from "./Pharmaceuticals";
import axios from "axios";
import VideoCall from "@medoczen/core/Agora/VideoCall";
import { Link, useNavigate } from "react-router-dom";
import { ChatBubble, ChatBubbleOutlineRounded } from "@mui/icons-material";
import { IoChatbubbleEllipses } from "react-icons/io5";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [prescription, setPrescription] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const reviews = useSelector((state) => state.review.reviewsSlice.getReview_success);
  const navigate = useNavigate();
  const appointment_details = sessionStorage.getItem("appointment_details")
    ? JSON.parse(sessionStorage.getItem("appointment_details"))
    : null;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getprescription() {
    try {
      await axios.get(`/medical-prescription/${props.appointment_id}`).then((res) => {
        setPrescription(res.data);
      });
    } catch (error) {
    } finally {
    }
  }
  React.useEffect(() => {
    setLoading(true);
    if (value === 0) {
      getprescription();
    }
  }, [value]);

  React.useEffect(() => {
    setLoading(true);
    if (value === 2) {
      dispatch(getReviews({ appointment_id: props.appointment_id })).then(() => {
        setLoading(false);
      });
    }
  }, [value]);

  function HandleChat() {
    navigate(`/chat/${appointment_details?.doctor?.aws_user_id?.replace(/-/g, "_")}`);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {props.loading ? (
          <Skeleton variant="rounded" sx={{ width: "100%", minWidth: "" }} height={30} />
        ) : (
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ textTransform: "none", color: "black" }}
              label="Prescription"
              {...a11yProps(0)}
            />
            <Tab
              style={{ textTransform: "none", color: "black" }}
              label="Medication"
              {...a11yProps(1)}
            />
            <Tab
              style={{ textTransform: "none", color: "black" }}
              label="Review"
              {...a11yProps(2)}
            />
          </Tabs>
        )}
      </Box>
      <CustomTabPanel value={value} index={0}>
        {props.loading ? (
          <div className="mb-5">
            <div className="flex items-center justify-between">
              <h1 className="font-[600] text-[23px]">Prescription</h1>
            </div>
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={120} />
          </div>
        ) : (
          <div className="mb-5">
            <div className="flex items-center justify-start">
              <h1 className="font-[600] text-[23px]">Prescription</h1>
            </div>
            <p className="font-[400] text-[14px]">{prescription?.description}</p>
          </div>
        )}

        <hr className="border-[#00000050] w-full my-[10px]" />

        {props?.status === "started" && (
          <div className="fixed bottom-[2rem] right-5">
            <Fab onClick={() => HandleChat()} color="" aria-label="chat">
              <IoChatbubbleEllipses size={30} />
            </Fab>
          </div>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Pharmaceuticals
          metadata={{ prescription_id: prescription?.id, appointment_id: props.appointment_id }}
        />

        <hr className="border-[#00000050] w-full my-[10px]" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="">
          {loading ? (
            <Skeleton sx={{ width: "100%", height: "100px" }} />
          ) : (
            <ReviewComponent
              reviews={reviews}
              doctor_id={props.doctor_id}
              appoinment_id={props.appointment_id}
            />
          )}
        </div>
      </CustomTabPanel>
    </Box>
  );
}
