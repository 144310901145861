import React, { useEffect } from "react";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { FormControl, FormLabel, IconButton, Slider, Input as MuiInput, TextField, InputAdornment } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { LuSettings2 } from "react-icons/lu";
import { GoChevronLeft } from "react-icons/go";
import InputField from "@medoczen/core/InputField/InputField";
import { Controller, useForm } from "react-hook-form";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import DropDownSheet from "@medoczen/core/DropDownSheet/DropDownSheet";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CheckBoxGroupSheet from "@medoczen/core/DropDownSheet/CheckBoxGroupSheet";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function DoctorsFilterDialog({ filterByCountry, setFilterByCountry, filterByRate, setFilterByRate, setFilterByType, filterByType }) {
  const [open, setOpen] = React.useState(false);


  const {
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  const marks = [
    { value: 10, label: '£10' },

    { value: 50, label: '£50' },


    { value: 100, label: '£100' }
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterClick = () => {
    setFilterByCountry(form.country);
    setFilterByRate(form.preferred_rate);
    setFilterByType(form.appointment_type);
    setOpen(false);
  };


  const handleResetClick = () => {
    setFilterByCountry("");
    setValue("preferred_rate", '', { shouldDirty: true });
    setValue("appointment_type", "", { shouldDirty: true });
    setValue("country", "", { shouldDirty: true });
    setFilterByRate('');

    setFilterByType('');
    // setValue('appointment_type', '');
    setOpen(false);
  };

  const handleSlideChange = (event, newValue) => {
    setValue("preferred_rate", newValue, { shouldDirty: true });
  };

  const handleInputChange = (event) => {
    setValue("preferred_rate", event.target.value === '' ? 0 : Number(event.target.value), { shouldDirty: true });
  };

  const handleBlur = () => {
    if (form.preferred_rate < 0) {
      setValue("preferred_rate", 0, { shouldDirty: true });
    } else if (form.preferred_rate > 100) {
      setValue("preferred_rate", 100, { shouldDirty: true });
    }
  };

  const valuetext = (value) => `${value}`;


  useEffect(() => {
    setValue("preferred_rate", filterByRate, { shouldDirty: true });
    setValue("appointment_type", filterByType, { shouldDirty: true });
    setValue("country", filterByCountry, { shouldDirty: true });

  }, [open, filterByCountry, filterByRate, filterByType]);


  const appointmentTypeOptions = [
    { value: 1, label: 'In-Home' },
    { value: 2, label: 'Video' },
    { value: 3, label: 'Chat' },
  ];

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen}>
        <LuSettings2 size={30} />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ padding: 0 }}>
          <div className="w-full flex flex-col gap-2 items-center">
            <div className="inset-0 bg-primary w-full h-[50px] sticky flex items-center justify-center z-[99]">
              <div className="grid grid-cols-[auto_1fr_auto] w-full max-w-4xl px-4 items-center">
                <div className="flex justify-start">
                  <button
                    onClick={handleClose}
                    className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
                  >
                    <GoChevronLeft color="white" size={26} />
                  </button>
                </div>
                <div className="flex justify-center">
                  <h1 className="font-[600] text-lg text-white">Doctor Filter</h1>
                </div>
                <div className="flex justify-end min-w-[1rem]"></div>
              </div>
            </div>
            <div className="px-[2rem] w-full mt-[2rem]">
              <div className="mb-3">
                <Controller
                  name="appointment_type"
                  control={control}
                  render={({ field }) => (
                    <CheckBoxGroupSheet
                      field={field}
                      label="Appointment Type"
                      options={appointmentTypeOptions}
                    />
                  )}
                />
              </div>

              <FormControl fullWidth>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={filterByCountry}
                  render={({ field }) => (
                    <CountriesDropdown
                      label="Country"
                      field={field}
                      defaultValue={filterByCountry}
                      setValue={setValue}
                    />
                  )}
                />
              </FormControl>

              <div className="mb-3 mt-5">
                <FormLabel
                  sx={{
                    marginBottom: "3px",
                    color: "#010101",
                    fontSize: "14px",
                    fontWeight: "800",
                    display: "block",
                  }}
                >
                  {'Preferred Rate '}
                </FormLabel>

                <div className="w-full border-1 pt-4 ">
                  <Controller
                    name="preferred_rate"
                    control={control}
                    defaultValue={100} // Default value for the slider range
                    render={({ field }) => (
                      <div className=" flex w-full justify-between flex-col items-center">
                        {/* <div className="w-full ">
                          <TextField
                            value={field.value || 100} // Ensure the input field shows correct values
                            size="small"
                            fullWidth
                            onChange={handleInputChange}
                            onBlur={handleBlur}

                            inputProps={{
                              step: 10,
                              min: 10,
                              max: 100,
                              type: 'number',
                              'aria-labelledby': 'input-slider',

                            }}
                            InputProps={{ endAdornment: <InputAdornment position="end">£/hr</InputAdornment> }}
                          />
                        </div> */}
                        <div className="w-full  ">
                          <Slider
                            {...field}
                            value={field.value || 100} // Ensure the slider shows correct values
                            onChange={handleSlideChange}
                            valueLabelDisplay="auto"
                            min={10}
                            getAriaValueText={valuetext}
                            marks={marks}
                            disableSwap
                          />
                        </div>

                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-[2rem] flex justify-between items-center">
              <PrimaryButton name="Reset" action={handleResetClick} className="w-[104px] h-[30px]" />
              <PrimaryButton name="Filter" action={handleFilterClick} className="w-[104px] h-[30px]" active />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default DoctorsFilterDialog;
