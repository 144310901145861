import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { Typography } from "@mui/material";
import React from "react";

function DetailedDescription() {
  return (
    <div>
      <HeaderWrapper title="Detailed Description" path="health-assessment" />

      <div className="mt-[1rem]">
        <div className="px-[1rem] py-[2rem]">
          <Typography variant="body1" component="p">
            Health assessment is a questionnaire set focused on daily health,
            lifestyle, and past medical history across various medical
            categories.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default DetailedDescription;
