import React from "react";
import { Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";

// Component for handling consents and agreements
function ConsentsAgreement(props) {
  const location = useLocation();
  const navigate = useNavigate();

  // Destructuring props for easier access
  const { form, control } = props;

  // Checking if all consents are agreed
  const allAgreed =
    form?.privacyPolicyAgreed &&
    form?.healthDataConsent &&
    form?.thirdPartyDataSharingConsent;

  // Handler for submit action
  const onSubmit = () => {
    // Navigate to the same path to clear consent screen state and hide the component
    navigate(location.pathname, { state: {}, replace: true });
  };

  return (
    <>
      <div>
        <HeaderWrapper path="signup" title="Consents & Agreements" />

        <div className="w-full mt-5 px-5">
          <h1 className="text-[20px] font-[500]">Consents & Agreements</h1>
          <p className="text-[14px] font-[500] text-justify">
            This app is currently in the testing phase, and some services, such
            as doctor appointments, are not live features yet.
          </p>
        </div>

        {/* Privacy Policy Agreement Section */}
        <div className="w-full mt-5 ">
          <label className="flex items-center">
            <Controller
              name="privacyPolicyAgreed"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} />
              )}
            />
            <span className="text-[12px] font-[600]">
              Privacy Policy and Terms of Service Agreement
            </span>
          </label>
          <p className="text-[12px] font-[500] text-justify  px-10">
            - I have read and agree to the Privacy Policy and Terms of Service.
          </p>
        </div>

        {/* Health Data Consent Section */}
        <div className="w-full mt-5 ">
          <label className="flex items-center">
            <Controller
              name="healthDataConsent"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} />
              )}
            />
            <span className="text-[12px] font-[600]">Health Data Consent</span>
          </label>
          <p className="text-[12px] font-[500] text-justify  px-10">
            - I consent to the collection and use of my health and medical
            information as described in the Privacy Policy to provide
            personalized health services and support.
          </p>
        </div>

        {/* Third-Party Data Sharing Consent Section */}
        <div className="w-full mt-5 ">
          <label className="flex items-center">
            <Controller
              name="thirdPartyDataSharingConsent"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value} />
              )}
            />
            <span className="text-[12px] font-[600]">
              Third-Party Data Sharing Consent for AI Services
            </span>
          </label>
          <p className="text-[12px] font-[500] text-justify  px-10">
            - I agree to the sharing of my health data with selected third-party
            AI services, such as OpenAI, to enhance the app's features and
            provide personalized assistance. All data shared will be treated
            with strict confidentiality and used solely for the purpose of
            improving user experience as described in the Privacy Policy.
          </p>
        </div>

        {/* Consent Submission Button */}
        <div className="mt-[1rem] mb-[1rem] text-center">
          <PrimaryButton action={onSubmit} active={allAgreed} name="I accept" />
        </div>
      </div>
    </>
  );
}

export default ConsentsAgreement;
