import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { LuBellRing } from "react-icons/lu";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import { Box, Fab, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import { CiCalendar } from "react-icons/ci";
import PropTypes from "prop-types";
import {
  getAppointments,
  resetAppointmentValues,
  selectAppointmentLoading,
  selectCompletedAppointments,
  selectTodaysAppointments,
  selectUpcomingAppointments,
} from "./store/AppointmentSlice";
import AppointmentTab from "./AppointmentTab";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
// import AppointmentWebsocket from '@medoczen/core/WebSockets/AppointmentWebsocket';

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      className="w-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
}

function AppointmentsList(props) {
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();
  const todaysAppointments = useSelector(selectTodaysAppointments);
  const upcomingAppointments = useSelector(selectUpcomingAppointments);
  const completedAppointments = useSelector(selectCompletedAppointments);
  const loading = useSelector(selectAppointmentLoading);

  const { control } = useForm();

  const filter = {
    appointment_type: ["all", "home", "video", "chat"][tabValue],
    name: searchText,
  };

  const handleChange = (event, newValue) => {
    if (newValue >= 0 && newValue <= 3) {
      setTabValue(newValue);
    }
  };

  useEffect(() => {
    dispatch(resetAppointmentValues());
    dispatch(getAppointments(filter)).then(() => {});
  }, [tabValue, searchText, dispatch]);

  const handleSearch = (e) => setSearchText(e.target.value);
  const renderAppointmentList = (appointments, title) =>
    appointments &&
    appointments.length > 0 && (
      <>
        <div className="w-full">
          <Typography fontWeight={500} variant="subtitle1" mt={2}>
            {title}
          </Typography>
        </div>
        <div className="flex flex-col items-center w-full h-full gap-[1rem]">
          {appointments.map((item, index) => (
            <AppointmentTab key={index} item={item} />
          ))}
        </div>
      </>
    );

  return (
    <>
      <HeaderWrapper left_nav path="dashboard" title="Appointments">
        <button className="mt-[5px]">
          <LuBellRing color="white" size={30} />
        </button>
      </HeaderWrapper>
      <div className=" relative flex flex-col  items-center px-3 py-3 w-full h-full">
        <div className="fixed bottom-20 right-5">
          <Fab LinkComponent={Link} to="/doctor-specialties" color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </div>

        <div className="flex items-center w-full gap-[10px]">
          <SearchBar onChange={handleSearch} value={searchText} placeholder="Search Here" />
          <div className="relative w-[58px] h-[57px] bg-[#D9D9D963] rounded-[5px] mb-[10px] flex justify-center items-center ">
            <div className=" opacity-0 z-[99]">
              <div className="w-full">
                <MDatePicker label="Start date" name="start_date" control={control} />
              </div>
            </div>
            <div className="absolute">
              {" "}
              <CiCalendar size={30} />
            </div>
          </div>
        </div>

        <Box className="w-full" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="appointment tabs"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Home" {...a11yProps(1)} />
            <Tab label="Video" {...a11yProps(2)} />
            <Tab label="Chat" {...a11yProps(3)} />
          </Tabs>
        </Box>
        {[0, 1, 2, 3].map((index) => (
          <CustomTabPanel key={index} value={tabValue} index={index}>
            <>
              {renderAppointmentList(todaysAppointments, "Todays Appointments")}
              {renderAppointmentList(upcomingAppointments, "Upcoming Appointments")}
              {renderAppointmentList(completedAppointments, "Completed Appointments")}
            </>
          </CustomTabPanel>
        ))}
      </div>
      {/* {!loading && <AppointmentWebsocket item={appointmentList[0]} />} */}
    </>
  );
}

export default AppointmentsList;
