import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { FaCheck } from "react-icons/fa";
import { FaMicrophone } from "react-icons/fa";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { Skeleton } from "@mui/material";

// Reusable Option Component
const Option = ({ id, selectedOption, handleOptionChange, icon, label }) => {
  const isSelected = selectedOption === id;

  return (
    <label
      htmlFor={id}
      className={`relative flex flex-col items-center justify-center border-2 text-black w-40 h-32 rounded-lg ${isSelected ? "border-primary" : "border-gray-300"
        }`}
      onClick={() => handleOptionChange(id)}
    >
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={isSelected}
        onChange={() => handleOptionChange(id)}
        className="hidden"
      />
      {icon}
      <p className="text-lg font-semibold">{label}</p>
      <span
        className={`absolute top-1 right-1 text-sm rounded-full p-1 ${isSelected
          ? "bg-primary text-white"
          : "text-white border border-gray-300"
          }`}
      >
        <FaCheck />
      </span>
    </label>
  );
};

function Question({ selectedOption, handleOptionChange, handleSubmit }) {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex items-center justify-center gap-4 mt-4">
        <Option
          id="text"
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          icon={
            <BsFillChatLeftTextFill className="w-12 h-12 object-cover rounded-full" />
          }
          label="Text"
        />
        <Option
          id="voice"
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          icon={
            <FaMicrophone className="w-12 h-12 object-cover rounded-full" />
          }
          label="Voice"
        />
      </div>

      <div className="mt-[2rem] ">
        <PrimaryButton active action={handleSubmit} name="Continue" />
      </div>
    </div>
  );
}

function Skeletons() {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-full flex items-center justify-center gap-4 mt-4">
        <Skeleton variant="rounded" width={160} height={128} />
        <Skeleton variant="rounded" width={160} height={128} />
      </div>

      <div className="mt-[2rem] ">
        {" "}
        <Skeleton variant="rounded" width={300} height={45} />
      </div>
    </div>
  );
}

function QuestionnaireType() {
  const navigate = useNavigate();
  const location = useLocation();
  const { medical_category, progress } = location.state;
  const [selectedOption, setSelectedOption] = React.useState(null);

  const [loading, setLoading] = useState(true);
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    const state = {
      medical_category: medical_category,
      question_category: selectedOption,
      progress,
    };
    // Navigation or further logic can be implemented here
    navigate("/health-assessment/questionnaire", { state });
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timeoutId); // Clean up timeout on component unmount
    };
  }, []);

  return (
    <>
      <HeaderWrapper title="Questionnaire Type" path="health-assessment" />
      <div className="w-full flex flex-col justify-start items-center mt-8">
        {loading ? (
          <Skeletons />
        ) : (
          <Question
            selectedOption={selectedOption}
            handleOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
}

export default QuestionnaireType;
