import React, { Component } from "react";
import "./SwipeableButton.css";

class DynamicSwipeableButton extends Component {
    sliderLeft = 0;
    isDragging = false;
    startX = 0;
    containerWidth = 0;

    sliderRef = React.createRef();
    containerRef = React.createRef();

    state = {
        unlocked: false,
    };


    componentDidMount() {
        if (this.containerRef.current) {
            this.containerWidth = this.containerRef.current.clientWidth - 50;

        }

        document.addEventListener("mousemove", this.onDrag);
        document.addEventListener("mouseup", this.stopDrag);
        document.addEventListener("touchmove", this.onDrag);
        document.addEventListener("touchend", this.stopDrag);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.stopDrag);
        document.removeEventListener("touchmove", this.onDrag);
        document.removeEventListener("touchend", this.stopDrag);
    }

    onDrag = (e) => {
        if (this.state.unlocked || this.props.disabled || this.props.loading) {
            return;
        }

        if (this.isDragging) {
            const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
            this.sliderLeft = Math.min(
                Math.max(0, clientX - this.startX),
                this.containerWidth
            );
            this.updateSliderStyle();
        }
    };

    updateSliderStyle = () => {
        if (this.state.unlocked || this.props.disabled || this.props.loading) return;
        if (this.sliderRef.current) {
            this.sliderRef.current.style.left = `${this.sliderLeft + 50}px`;
        }
    };

    stopDrag = () => {
        if (this.state.unlocked || this.props.disabled || this.props.loading) return;
        if (this.isDragging) {
            this.isDragging = false;
            if (this.sliderLeft > this.containerWidth * 0.9) {
                this.sliderLeft = this.containerWidth;
                if (this.props.action) {
                    this.props.action();
                    this.onSuccess();
                }
            } else {
                this.sliderLeft = 0;
            }
            this.updateSliderStyle();
        }
    };

    startDrag = (e) => {
        if (this.state.unlocked || this.props.disabled || this.props.loading) return;
        this.isDragging = true;
        this.startX = "touches" in e ? e.touches[0].clientX : e.clientX;
    };

    onSuccess = () => {
        if (this.containerRef.current) {
            this.containerRef.current.style.width = `${this.containerRef.current.clientWidth}px`;
        }
        this.setState({
            unlocked: true,
        });
    };

    getText = () => {

        return this.state.unlocked
            ? this.props.text_unlocked || ""
            : this.props.name || "SLIDE";
    };

    render() {
        const { className, active, disabled, loading, color } = this.props;


        return (
            <div className={`ReactSwipeButton px-4 ${className || ""}`}>
                <div
                    className={`rsbContainer ${this.state.unlocked ? "rsbContainerUnlocked" : ""} ${active ? "active" : ""} ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`}
                    ref={this.containerRef}
                >
                    <div
                        className="rsbcSlider"

                        ref={this.sliderRef}
                        onMouseDown={this.startDrag}

                        style={{ background: color }}
                        onTouchStart={this.startDrag}
                    >
                        <span className="rsbcSliderText">{this.getText()}</span>
                        <span className="rsbcSliderArrow"></span>
                        <span
                            className="rsbcSliderCircle"
                            style={{ background: color }}
                        ></span>
                    </div>
                    <div className="rsbcText">{this.getText()}</div>
                </div>
            </div>
        );
    }
}

export default DynamicSwipeableButton;
