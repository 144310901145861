import * as React from "react";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormLabel from "@mui/material/FormLabel";

export default function MDatePicker({ control, name, label, disabled, disableFuture }) {
  return (
    <div className="relative mb-[8px]">
      <FormLabel
        htmlFor={label}
        sx={{
          marginBottom: "3px",
          color: "#010101",
          fontSize: "14px",
          fontWeight: "800",
          display: "block",
        }}
      >
        {label}
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={name}
          control={control}
          defaultValue={dayjs().toISOString()}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={dayjs(value)}
              onChange={(newValue) =>
                onChange(newValue ? newValue.toISOString() : null)

              }
              disableFuture={disableFuture}
              disabled={disabled}
              sx={{
                height: "fit",
                width: "100%",
                fontFamily: "700",
                background: "#f8f9fa",
                border: "6px #000000",
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
