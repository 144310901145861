const { Skeleton } = require("@mui/material");

export default function Skeletons() {
  return (
    <div>
      <Skeleton variant="rounded" sx={{ width: "95%", my: 2, mx: "auto" }} height={50} />
      <div className="w-full flex flex-col items-center mt-8 justify-center gap-2">
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
        <Skeleton variant="rounded" sx={{ width: "100%" }} height={50} />
      </div>
    </div>
  );
}
