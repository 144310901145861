import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import tabletPng from "@/../../public/assets/images/medicines/tablets.png";
function PrescribedMedicines(props) {
  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/prescribed-medicines");
  };

  return (
    <>
      <div className="inset-0  bg-primary w-full min-h-[126px] py-5  sticky flex flex-col items-center gap-2 justify-center z-[99] ">
        <div className="flex w-full px-[4px] justify-around items-center ">
          <Link
            to={"/add-medicine-reminder"}
            className=" w-[40px] h-[40px]  rounded-full border border-[#ffffff56] flex items-center place-content-center "
          >
            {" "}
            <GoChevronLeft color="white" size={26} />{" "}
          </Link>

          <h1 className={` font-[500] text-[14px] text-white `}>
            Your prescription for Fever
          </h1>
          <div className="w-[40px]"></div>
        </div>
        <div className=" ">
          <div className="relative w-[336px] text-wrap h-fit text-white text-[14px]  flex justify-start items-center">
            <span className="w-full text-justify  capitalize">
              Dr Sushant Shah, General Physician Visited on Fri, 12 Mar
            </span>
          </div>
        </div>
      </div>

      <div className="w-full px-[1.5rem] mb-[1rem] mt-[1rem] flex flex-col gap-2">
        <div className="flex w-fit h-[147px] border-[1px] p-[10px]  rounded-xl shadow-lg">
          <div className=" w-[50px] me-[1rem]">
            <img
              src={tabletPng}
              className="w-full h-[41px] object-cover"
              alt=""
            />{" "}
          </div>
          <div>
            <h1 className="text-[14px] font-semibold ">Paracetamol 500mg</h1>
            <p className="text-[14px] font-[400]">
              1 tablet every day for 1 week in morning, noon after food
            </p>
            <div className="flex justify-around px-[1rem] mt-[10px]">
              <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                Order Medicine
              </button>
              <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                Set Reminder
              </button>
            </div>
          </div>
        </div>

        <div className="flex w-fit h-[147px] border-[1px] p-[10px]  rounded-xl shadow-lg  ">
          <div className=" w-[50px] me-[1rem]">
            <img
              src={tabletPng}
              className="w-full h-[41px] object-cover"
              alt=""
            />{" "}
          </div>
          <div>
            <h1 className="text-[14px] font-semibold ">Paracetamol 500mg</h1>
            <p className="text-[14px] font-[400]">
              1 tablet every day for 1 week in morning, noon after food
            </p>
            <div className="flex justify-around px-[1rem] mt-[10px]">
              <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                Order Medicine
              </button>
              <button className="w-[104px] h-[32px] border-[1px]  border-[#b4aeae] rounded-[5px] text-primary text-[12px] font-[400] ">
                Set Reminder
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrescribedMedicines;
