import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { CircularProgress, Checkbox } from "@mui/material";
import { useLocation, Link, useNavigate } from "react-router-dom";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import SignUpPng from "@/../../public/assets/images/signup.png";
import { CiUnlock } from "react-icons/ci";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { signup } from "./store/signupSlice";
import ConsentsAgreement from "./Consents&Agreements/ConsentsAgreement";

// Component for handling user registration
function SignUp() {
  // Hooks for navigation and state management
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Form handling with react-hook-form
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  // Watch form values for real-time updates
  const form = watch();
  // Selector for accessing redux store state
  const selectUser = useSelector((state) => state.signup.signupSlice);

  // Variables to control the UI state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Variables for conditional rendering based on form input
  const partialAgreed = form?.full_name && form?.email && form?.password;
  const allAgreed =
    partialAgreed &&
    form?.privacyPolicyAgreed &&
    form?.healthDataConsent &&
    form?.thirdPartyDataSharingConsent;

  // Handle snackbar visibility with a timer
  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => setSnackbarOpen(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbarOpen]);

  // Effect to process user signup response
  useEffect(() => {
    if (selectUser?.success) {
      const { $metadata, UserConfirmed } = selectUser.success;
      setLoading(false);
      if ($metadata.httpStatusCode === 200 && !UserConfirmed) {
        navigate("/verification");
        setSnackbarOpen(true);
      }
    } else if (selectUser?.error) {
      setLoading(false);
      const message =
        selectUser.error.name === "UsernameExistsException"
          ? "Account already exists with that email."
          : selectUser.error.name === "InvalidPasswordException"
            ? "Password must include uppercase, lowercase, a number, a special character, and be at least 8 characters long."
            : "";
      if (message) {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate]);

  // Toggle visibility of the password
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Handle form submission
  const onSubmit = () => {
    setLoading(true);
    if (allAgreed) {
      dispatch(signup(form));
    } else {
      setLoading(false);
      navigate(location.pathname, { state: { showConsentScreen: true } });
    }
  };

  return (
    <>
      {/* Conditional rendering based on consent screen visibility */}
      {!location.state?.showConsentScreen ? (
        <div className="flex flex-col items-center px-8 py-3 w-full">
          {/* Loading overlay */}
          {loading && (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
              <CircularProgress />
            </div>
          )}

          {/* Signup illustration */}
          <div className="w-full flex justify-center">
            <img
              className="object-cover w-[250px]"
              src={SignUpPng}
              alt="Sign up"
              loading="lazy"
            />
          </div>

          <div className="flex flex-col justify-start mt-5 w-full">
            <div className="mb-5">
              <h1 className="font-bold mb-2 text-lg">Sign Up</h1>
              <p className="font-medium text-base">
                Enter your credentials to continue
              </p>

              {/* Snackbar for showing alerts */}
              {snackbarOpen && (
                <Alert className="mt-2" variant="filled" severity="error">
                  {snackbarMessage}
                </Alert>
              )}
            </div>

            {/* Form inputs */}
            <div className="w-full flex flex-col gap-2">
              {/* Full name input */}
              <Controller
                name="full_name"
                control={control}
                defaultValue=""
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <InputField
                    label="Name"
                    icon={<FaRegUser size={20} />}
                    {...field}
                  />
                )}
              />

              {/* Email input */}
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Email is required" }}
                render={({ field }) => (
                  <InputField
                    label="Email"
                    icon={<MdOutlineMail size="24px" />}
                    {...field}
                  />
                )}
              />

              {/* Password input with toggleable visibility */}
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <InputField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    icon={<CiUnlock size={"24px"} />}
                    endIcon={
                      showPassword ? (
                        <BsEye
                          size={"24px"}
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <BsEyeSlash
                          size={"24px"}
                          onClick={togglePasswordVisibility}
                        />
                      )
                    }
                    error={errors.password?.message}
                    {...field}
                  />
                )}
              />

              {/* Terms acceptance info */}
              {allAgreed && (
                <div className="text-left">
                  <span>
                    <Checkbox disabled checked /> All terms accepted?
                  </span>{" "}
                  <button className="text-primary font-bold text-base">
                    View again
                  </button>
                </div>
              )}

              {/* Submit button */}
              <div className="mt-[1rem] mb-[1rem] text-center">
                <PrimaryButton
                  name={allAgreed ? "Sign Up" : "Next"}
                  active={partialAgreed}
                  action={onSubmit}
                />
              </div>

              {/* Link to sign in page */}
              <div className="text-center">
                <span>Already have an account?</span>{" "}
                <Link className="text-primary font-bold text-base" to="/signin">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Render consents and agreements component if consent screen is to be shown
        <ConsentsAgreement
          setConsentScreen={(value) =>
            navigate(location.pathname, {
              state: { showConsentScreen: !value },
            })
          }
          consentScreen={!!location.state?.showConsentScreen}
          control={control}
          form={form}
        />
      )}
    </>
  );
}

export default SignUp;
