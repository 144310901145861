import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import masterCardPng from "@/../../public/assets/images/payment/mastercard.png";
import visaCardPng from "@/../../public/assets/images/payment/visa.png";
import { useDispatch, useSelector } from "react-redux";
import { deletePaymentMethod, getPaymentMethods, getPreferredPayment, setPreferredPaymentMethod } from "../store/PaymentSlice";
import { useForm } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { Skeleton } from "@mui/material";
import ConfirmDialog from "@medoczen/core/ConfirmDialog/ConfirmDialog";

function Paymentmethod(props) {
  const { setValue, watch } = useForm({
    mode: "onChange",
  });

  const form = watch();
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState([]);

  const [loading, setLoading] = useState(true);
  const selectCardData = useSelector((state) => state.payment.paymentSlice);


  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/add-card");
  };

  useEffect(() => {
    setCardData(selectCardData?.success);
  }, [selectCardData]);

  useEffect(() => {
    setLoading(true);
    fetchPreferredCard();
    dispatch(getPaymentMethods()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const handleCardDelete = (card) => {
    setLoading(true);
    dispatch(deletePaymentMethod({ payment_method_id: card?.id })).then(() => {
      dispatch(getPaymentMethods()).then(() => {
        setLoading(false);
      })

    });
  };

  const handleCardSelect = (card) => {
    setLoading(true)
    // Set the selected card as the preferred payment method
    dispatch(setPreferredPaymentMethod({ payment_method_id: card?.id })).then(() => {
      dispatch(getPaymentMethods()).then(() => {
        setLoading(false);
      });
      setValue("payment_method", card);
    });
  };

  const fetchPreferredCard = async () => {
    dispatch(getPreferredPayment()).then((res) => { setValue("payment_method", res.data); })
  }


  return (
    <div className="h-full">
      <HeaderWrapper path={'payment'} title="Payment Method Settings" />
      <div className=" w-full h-full px-[1rem] pt-[1rem] pb-[2rem]">
        <div className=" shadow-lg border-2 border-gray-200 rounded-xl ">
          {loading ? (
            <>
              <Skeleton variant="rounded" sx={{ width: '100%', marginBottom: '1rem' }} height={70} />
              <Skeleton variant="rounded" sx={{ width: '100%' }} height={70} />
            </>
          ) : (
            <>
              {cardData && cardData.map((card, index) => (
                <div

                  key={index}
                  className={`relative w-full h-fit px-[10px] py-[1rem]   border-b-2  border-gray-200  text-[#000000a8] flex items-center gap-[1.5rem] mx-auto`}
                >
                  <div className="relative w-full h-fit text-[#000000a8] flex items-center ">
                    <div className="flex  justify-start items-center">
                      <img src={card.card.brand === 'visa' ? visaCardPng : masterCardPng} className="w-[50px] h-[30px] object-cover border-2" alt="" />
                      <div className="flex flex-col">

                        <div className="text-[14px] font-[500] flex  justify-start items-center">

                          <span className="font-[600] text-[18px] mx-3">{`**** ${card?.card?.last4}`}</span>
                          <span className="font-[600] text-[18px] text-[#A4A9AE] mr-3">
                            {String(card?.card?.exp_month).padStart(2, '0')}/{String(card?.card?.exp_year).slice(-2)}
                          </span>
                          <div className="">
                            {(form.payment_method?.id === card?.id) && <span className="bg-black text-white p-1 rounded-[3px] font-semibold text-[13px] w-fit h-fit">
                              Preferred
                            </span>}
                          </div>
                        </div>
                        {(form.payment_method?.id !== card?.id) && <span onClick={() => handleCardSelect(card)} className=" text-primary ms-3 font-semibold text-[11px] w-fit  h-fit ">
                          set as Preferred
                        </span>}
                      </div>
                    </div>
                  </div>


                  {form.payment_method?.id !== card?.id && <div className=" flex justify-center items-center ">

                    <ConfirmDialog title="Delete Card" content={<p>Are you sure you want to delete this payment method?</p>} Action={() => handleCardDelete(card)} button={<button> <FaTrash size={20} /></button>} />
                  </div>}

                </div>
              ))}
            </>
          )}
        </div>
        <div className="fixed bottom-[2rem]">
          {loading ? (
            <Skeleton variant="rounded" sx={{ width: '244px' }} height={70} />
          ) : (
            <div className="w-full fixed bottom-0 bg-white p-1 left-0 flex justify-center ">
              <PrimaryButton
                active
                name="Add New Card"
                className="w-[244px]"
                fontSize="18px"
                action={ButtonClick}
              />
            </div>
          )}
        </div>
      </div>
    </div >
  );
}

export default Paymentmethod;
