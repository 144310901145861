import { configureStore, combineReducers } from "@reduxjs/toolkit";

const RESET_GLOBAL_STATE = "RESET_GLOBAL_STATE";

function importAllReducers() {
  const context = require.context("../src/pages", true, /store\/index\.jsx$/);

  const reducers = context.keys().reduce((acc, path) => {
    const module = context(path);
    const key = module.sliceName || module.name;
    if (!key) {
      throw new Error(
        `The module ${path} does not export a 'sliceName' or 'name' property.`,
      );
    }
    acc[key] = module.default; // Assume each module exports a reducer as default
    return acc;
  }, {});

  return reducers;
}

const rootReducer = combineReducers(importAllReducers());

const rootReducerWithReset = (state, action) => {
  if (action.type === RESET_GLOBAL_STATE) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export const resetGlobalState = () => ({ type: RESET_GLOBAL_STATE });

const store = configureStore({
  reducer: rootReducerWithReset,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
