import { Fab } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { FaVideo } from 'react-icons/fa';

export default function VideoCall({ channel }) {


    const fetchCredentials = async () => {
        try {
            const response = await axios.get('https://api.medoczen.uk/agora-chat/generate_video_token?channelName=' + channel);
            const { userId, token, channelName } = response.data;

            window.ReactNativeWebView.postMessage(JSON.stringify({
                command: 'startCall',
                channel: channelName,
                token: token,
            }));
        } catch (error) {
            console.error("Failed to fetch Agora credentials:", error.response?.data || error.message);
        }
    };



    const startCall = () => {
        // const channelName = 'testChannel';
        fetchCredentials()
        // window.ReactNativeWebView.postMessage(JSON.stringify({
        //     command: 'startCall',
        //     channel: channelName,
        //     token: agoraToken

        // }));
    };

    return (
        <div>
            <FaVideo onClick={startCall} />
        </div>
    );
}
