import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment_success: null, // Holds success response or null
    payment_error: null, // Holds error message or null
  },
  reducers: {
    getPaymentMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    getPaymentMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    addPaymentMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    addaymentMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    deletePaymentMethodSuccess: (state, action) => {
      state.delete_success = action.payload;
      state.delete_error = null;
    },
    deletePaymentMethodFailure: (state, action) => {
      state.error = action.payload;
      state.delete_success = null;
    },



  },
});

export const {
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
  addPaymentMethodSuccess,
  addPaymentMethodFailure,
  deletePaymentMethodSuccess,
  deletePaymentMethodFailure,

} = paymentSlice.actions;

// Asynchronous thunk action for fetching payment data
export const getPaymentMethods = (params) => async (dispatch) => {
  try {
    const response = await axios.get("payment-methods/list-cards",);
    dispatch(getPaymentMethodSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getPaymentMethodFailure(errorMessage));
    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching payment data



export const getPreferredPayment = (params) => async (dispatch) => {
  try {
    const response = await axios.get("payment-methods/preferred-card", '');

    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};
// Asynchronous thunk action for adding payment
export const addPaymentMethod = (params) => async (dispatch) => {
  try {
    const response = await axios.post("payment-methods/save-card", params);

    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};



export const deletePaymentMethod = (params) => async (dispatch) => {
  try {
    const response = await axios.delete(`payment-methods/delete-card?payment_method_id=${params.payment_method_id}`, '');

    dispatch(deletePaymentMethodSuccess(response.data));
    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(deletePaymentMethodFailure(errorMessage));
    throw errorMessage;
  }
};


export const MakePayment = (params) => async (dispatch) => {
  try {
    const response = await axios.post("payment/appointment-payment", params);
    return (response)
  }

  catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    throw errorMessage;
  }
}

export const setPreferredPaymentMethod = (params) => async (dispatch) => {
  try {
    const response = await axios.put("payment-methods/set-preferred-card", params);

    return response
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = paymentSlice.name;

export default paymentSlice.reducer;
