import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authServiceInstance from "@services/jwtService/index";

export const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState: {
    full_name: "",
    country_code: "",
  },
  reducers: {
    personalDetailsSuccess: (state, action) => {
      // Directly mutate the state properties
      state.personalDetails_success = action.payload;
      state.personalDetails_error = null; // Reset error state on success
    },
    personalDetailsFailure: (state, action) => {
      // Directly mutate the state properties
      state.personalDetails_error = action.payload;
      state.personalDetails_success = null; // Reset success state on failure
    },
    updatePersonalDetailsSuccess: (state, action) => {
      // Directly mutate the state properties
      state.personalDetails_success = action.payload;
      state.personalDetails_error = null; // Reset error state on success
    },
    updatePersonalDetailsFailure: (state, action) => {
      // Directly mutate the state properties
      state.personalDetails_error = action.payload;
      state.personalDetails_success = null; // Reset success state on failure
    },
  },
});

export const {
  updatePersonalDetailsSuccess,
  updatePersonalDetailsFailure,
  personalDetailsSuccess,
  personalDetailsFailure,
} = personalDetailsSlice.actions;

// Asynchronous thunk action for logging in
export const getPersonalDetails = () => async (dispatch) => {
  try {
    const data_resp = await authServiceInstance.getPersonalDetails();
    dispatch(personalDetailsSuccess(data_resp));
  } catch (error) {
    dispatch(personalDetailsFailure(error));
  }
};

// Asynchronous thunk action for logging in
export const updatePersonalDetails = (params) => async (dispatch) => {
  try {
    const data_resp = await authServiceInstance.updatePersonalDetails(params);
    dispatch(updatePersonalDetailsSuccess(data_resp));
  } catch (error) {
    dispatch(updatePersonalDetailsFailure(error));
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = personalDetailsSlice.name;

export default personalDetailsSlice.reducer;
