import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import avatar from "@/../../public/assets/images/avatar.png";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import InputField from "@medoczen/core/InputField/InputField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MdOutlineCalendarToday } from "react-icons/md";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import InputLabel from "@mui/material/InputLabel";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { AddMember, DeleteMember, UpdateMember, getMember } from "./store/membersSlice";
import { useParams } from "react-router-dom";
import { FormLabel, Skeleton } from "@mui/material";
import SkeletonPage from "./SkeletonPage";
import DropDownSheet from "@medoczen/core/DropDownSheet/DropDownSheet";

function AddFamilyMembers(props) {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();
  const [tempImage, setTempImage] = useState(null);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { memberId } = useParams()


  const selectMember = useSelector((state) => state.member.membersSlice)


  useEffect(() => {
    if (props.update) {
      reset(selectMember.getMember_success)
    }

  }, [selectMember])


  function onSubmit() {

    if (props.update) {
      dispatch(UpdateMember({ ...form, id: memberId })).then(() => {
        props.navigate('/family-members')
      })
    } else {
      dispatch(AddMember(form)).then(() => {
        props.navigate('/family-members')
      })
    }
  }

  function fetchData() {
    setLoading(true);
    dispatch(getMember(memberId))
      .then(() => {

        setLoading(false);
      })
      .catch(error => {

        console.error("Error fetching members:", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.update) {
      setLoading(true);
      fetchData()
    }

  }, [])

  function HandleDelete() {
    if (props.update) {
      dispatch(DeleteMember({ ...form, id: memberId })).then(() => {
        props.navigate('/family-members')
      })
    }
  }

  if (loading) {
    return (<SkeletonPage />)
  }

  return (
    <div>
      <>
        <HeaderWrapper title={`${props.update ? "Edit" : "Add"} Family Member`} path={"family-members"} >
          {props?.update && <button onClick={() => HandleDelete()}><FaTrashAlt size={20} color="white" /></button>}
        </HeaderWrapper>
        <div className="flex flex-col items-center px-4 py-3 w-full">
          {/* PersonalDetails Image Upload */}
          <div className="w-full flex justify-center pb-5">
            <Controller
              name="selfie"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <label className="relative">
                  <img
                    src={
                      tempImage
                        ? tempImage
                        : `${form?.selfie
                          ? `${form.selfie}?${new Date().getTime()}`
                          : `${avatar}?${new Date().getTime()}`
                        }`
                    }
                    className="w-[100px] h-[100px] rounded-full"
                  />

                  <input
                    id="image"
                    name={name}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        const file = e.target.files[0];

                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setTempImage(reader.result);
                          onChange({
                            name: file.name,
                            type: file.type,
                            file: reader.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    onBlur={onBlur}
                    ref={ref}
                  />
                  <div className="absolute right-0 bottom-0 bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center shadow-md cursor-pointer">
                    <FaRegEdit size={20} />
                  </div>
                </label>
              )}
            />
          </div>



          <div className="w-full">
            <div className="mb-3">
              <Controller
                name="full_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField {...field} label="Name" className="mb-2" />
                )}
              />
            </div>


            <div className="mb-3 col-span-2">
              <Controller
                name="nhs_number"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    type="text"
                    label="NHS Number"
                    className="mb-2"
                  />
                )}
              />
            </div>




            <div className="mb-3">
              <Controller
                name="birthday"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MDatePicker
                    label="birthday"
                    name="birthday"
                    disableFuture={true}
                    control={control}
                    icon={<MdOutlineCalendarToday size={24} />}
                    className="mb-2"
                  />
                )}
              />
            </div>

            {/* Gender Field with DropDownSheet */}
            <div className="mb-3">
              <Controller
                name="gender_id"
                control={control}
                render={({ field }) => (
                  <DropDownSheet
                    field={field}
                    label="Gender"
                    options={[
                      { value: '1', label: 'Male' },
                      { value: '2', label: 'Female' },
                      { value: '3', label: 'Other' },
                    ]}
                  />
                )}
              />
            </div>

            {/* Family Relationship Type Field with DropDownSheet */}
            <div className="mb-3">
              <Controller
                name="family_relationship_type_id"
                control={control}
                render={({ field }) => (
                  <DropDownSheet
                    field={field}
                    label="Family Relationship Type"
                    options={[
                      { value: '1', label: 'Mother' },
                      { value: '2', label: 'Father' },
                      { value: '3', label: 'Son' },
                      { value: '4', label: 'Daughter' },
                      { value: '5', label: 'Uncle' },
                      { value: '6', label: 'Aunt' },
                      { value: '7', label: 'Sister' },
                      { value: '8', label: 'Brother' },
                    ]}
                  />
                )}
              />
            </div>

            <div className="mb-[4rem]">
              <FormControl fullWidth>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CountriesDropdown
                      label="Country"
                      field={field}
                      setValue={setValue}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="fixed left-0 bg-white p-2  bottom-0 w-full flex justify-center z-[99] ">
              <PrimaryButton
                active
                name="Save"
                type="submit"
                className="w-full max-w-xs  "
                action={onSubmit}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default AddFamilyMembers;
