import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Checkbox, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import InputField from "@medoczen/core/InputField/InputField";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import { useDispatch } from "react-redux";
import { showToast } from "pages/Appointments/BookAppointment/store/bookAppointmentSlice";

const stripePromise = loadStripe('pk_test_51PoG4nFxaZUa1Cs0CO7Vo14PZVWP7mYjKXJyciFOE3uvjUwZBZlfZP5Hc5ehRAgIngEvmHNOvWLTvkp4bcsCsE6y006zyTVNMi');

function FormComponent(props) {
  const { handleSubmit, control, setValue } = useForm();
  const elements = useElements();
  const dispatch = useDispatch()
  const stripe = useStripe();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (!elements || !stripe) return;
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);


    try {
      const billingDetails = {
        name: data.account_holder_name,
        address: {
          line1: data.billing_address_line1,
          line2: data.billing_address_line2,
          city: data.billing_city,
          state: data.billing_state,
          postal_code: data.billing_postal_code,
          country: data.billing_country?.country_code,
        },
      };

      // Make a POST request to your backend to create the SetupIntent
      const { data: setupIntentResponse } = await axios.post('/payment-methods/create-setup-intent', { email: '' });

      const clientSecret = setupIntentResponse.clientSecret;

      const response = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: billingDetails,
        },
      });

      if (response.error) {
        console.error(response.error.message);
        dispatch(showToast({ message: response.error.message, type: 'error' }));
        // Handle error
      } else {

        dispatch(showToast({ message: 'Card added successfully', type: 'success' }));

        props.navigate('/payment-method');
      }
    } catch (error) {
      console.error('Error saving card:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const stripeElementStyles = {
    base: {
      fontSize: '18px',
      fontWeight: 500,
      color: 'black',
      '::placeholder': {
        color: '#aab7c4',
      },
      padding: '17px 10px 13px 10px',
      backgroundColor: '#f8f9fa',
      border: '1px solid #ced4da',
      borderRadius: '4px',
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  return (
    <>
      <HeaderWrapper title="Add Card" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full">
        <form className="w-full flex flex-col gap-2 px-3 pb-[100px]" onSubmit={handleSubmit(onSubmit)}>

          {/* Card Number Field */}
          <div className="mb-4">
            <FormLabel
              htmlFor='card-element'
              sx={{
                marginBottom: "3px",
                color: "#010101",
                fontSize: "14px",
                fontWeight: "800",
                display: "block",
              }}
            >
              Card Number
            </FormLabel>

            <div style={stripeElementStyles.base}>
              <CardNumberElement
                id="card-element"
                options={{ style: stripeElementStyles }}
              />
            </div>
          </div>

          <div className="flex items-center w-full gap-4 mb-2">
            {/* Expiry Date Field */}
            <div className="flex-1">
              <FormLabel
                htmlFor='expiry-element'
                sx={{
                  marginBottom: "3px",
                  color: "#010101",
                  fontSize: "14px",
                  fontWeight: "800",
                  display: "block",
                }}
              >
                Expiry Date
              </FormLabel>

              <div style={stripeElementStyles.base}>
                <CardExpiryElement
                  id="expiry-element"
                  options={{ style: stripeElementStyles }}
                />
              </div>
            </div>

            {/* CVC Field */}
            <div className="flex-1">
              <FormLabel
                htmlFor='cvc-element'
                sx={{
                  marginBottom: "3px",
                  color: "#010101",
                  fontSize: "14px",
                  fontWeight: "800",
                  display: "block",
                }}
              >
                CVC
              </FormLabel>


              <div style={stripeElementStyles.base}>
                <CardCvcElement
                  id="cvc-element"
                  options={{ style: stripeElementStyles }}
                />
              </div>
            </div>
          </div>

          {/* Account Holder Name */}
          <Controller
            name="account_holder_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputField
                {...field}
                label="Account Holder Name"

                placeholder="Account Holder Name"
              />
            )}
          />

          {/* Billing Address Fields */}
          <Controller
            name="billing_address_line1"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputField
                {...field}
                label="Address Line 1"
                placeholder="Address Line 1"
              />
            )}
          />

          <Controller
            name="billing_address_line2"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputField
                {...field}
                label="Address Line 2 (optional)"
                placeholder="Address Line 2"
              />
            )}
          />

          <div className=" flex items-center w-full gap-4">
            <div className="flex-1">
              <Controller
                name="billing_city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="City"
                    placeholder="City"
                  />
                )}
              />
            </div>

            <div className="flex-1">
              <Controller
                name="billing_state"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="State"
                    placeholder="State"
                  />
                )}
              />
            </div>

          </div>
          <Controller
            name="billing_postal_code"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputField
                {...field}
                label="Postal Code"
                placeholder="Postal Code"
              />
            )}
          />
          <div className="mb-3">
            <FormControl fullWidth>
              <Controller
                name="billing_country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CountriesDropdown
                    label="Country"
                    field={field}
                    setValue={setValue}
                  />
                )}
              />
            </FormControl>
          </div>


          <div className=" fixed bottom-0 left-0 bg-white p-1 w-full flex justify-center">
            <PrimaryButton
              action={handleSubmit(onSubmit)}
              active
              fontSize="18px"
              name="Add Card"
              loading={loading}
              className="w-[244px]"

            />
          </div>
        </form>
      </div>
    </>
  );
}

export default function AddCard(props) {
  return (
    <Elements stripe={stripePromise}>
      <FormComponent {...props} />
    </Elements>
  );
}
