import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import InputField from "@medoczen/core/InputField/InputField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { CircularProgress } from "@mui/material"; // Import CircularProgress
import LoginPng from "@/../../public/assets/images/Login.png";
import { CiUnlock } from "react-icons/ci";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { signin } from "./store/signinSlice";

function SignIn(props) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const selectUser = useSelector((state) => state.signin.signinSlice);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbarOpen]);

  useEffect(() => {
    // Assuming `selectUser` might also contain info about successful operations like code delivery
    if (selectUser?.success) {
      setLoading(false);
      navigate("/dashboard");
    } else if (selectUser?.error) {
      setLoading(false);

      if (selectUser?.error?.name === "NotAuthorizedException") {
        setSnackbarMessage("Username/Password is incorrect");
        setSnackbarOpen(true);
      } else if (selectUser?.error?.CodeDeliveryDetails?.Destination) {
        // Handle the success scenario of code being sent
        setSnackbarMessage(
          `Code sent to ${selectUser.error.CodeDeliveryDetails.Destination}`,
        );
        setSnackbarOpen(true);
        navigate("/verification", {
          state: {
            module: "signin",
            email: selectUser.error.CodeDeliveryDetails.Destination,
          },
        });
      } else if (selectUser.error.name === "LimitExceededException") {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`,
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      signin({
        email: form?.email,
        password: form?.password,
      }),
    );
  };

  return (
    <div className="flex flex-col items-center px-8 py-2 w-full">
      {/* Loading animation */}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <CircularProgress />
        </div>
      )}

      {/* Sign up image */}
      <div className="w-full flex justify-center">
        <img
          className="w-[250px]"
          src={LoginPng}
          alt="sign in image"
          loading="lazy"
        />
      </div>

      {/* Sign in form */}
      <div className="flex flex-col justify-start w-full">
        <div className="mb-5">
          <h1 className="font-[700] mb-2 text-[18px]">LOG IN</h1>
          <p className="font-[500] text-[14px]">
            Enter your credentials to continue
          </p>
          {snackbarOpen && (
            <div>
              <Alert className="mt-2" variant="filled" severity="error">
                {snackbarMessage}
              </Alert>
            </div>
          )}
        </div>
        <div className="w-full flex flex-col gap-2">
          {/* Email field */}
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format", // This message will show if the pattern validation fails
              },
            }}
            render={({ field }) => (
              <InputField
                label="Email"
                placeholder=""
                icon={<MdOutlineMail size={"24px"} />}
                error={errors.email?.message}
                {...field}
              />
            )}
          />

          {/* Password field */}
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <InputField
                label="Password"
                type={showPassword ? "text" : "password"}
                placeholder=""
                icon={<CiUnlock size={"24px"} />}
                endIcon={
                  showPassword ? (
                    <BsEye size={"24px"} onClick={togglePasswordVisibility} />
                  ) : (
                    <BsEyeSlash
                      size={"24px"}
                      onClick={togglePasswordVisibility}
                    />
                  )
                }
                error={errors.password?.message}
                {...field}
              />
            )}
          />

          {/* Forgot password link */}
          <div className="flex mt-[.8rem] justify-end w-full text-primary font-semibold text-[14px]">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </div>

          <div className="mt-[1rem] mb-[1rem] text-center">
            <PrimaryButton active name="Log In" action={onSubmit} />
          </div>

          {/* Sign up link */}
          <div className="text-center">
            <span>Don't have an account? </span>
            <Link className="text-primary font-bold text-[14px]" to={"/signup"}>
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
