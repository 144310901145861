import React, { useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import {
  MdNotifications,
  MdPeopleOutline,
  MdPayment,
  MdOutlineEmail,
  MdPassword,
} from "react-icons/md";
import { GrShieldSecurity } from "react-icons/gr";

import SettingsLinks from "./PSLinks";

import { clearStorageAndSession } from "@medoczen/core/MDZsessions";
import PSLinks from "./PSLinks";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";

// Define sections with titles, links, paths, and icons directly included
const sections = [
  {
    title: "Login & recovery ",
    description:
      "Manage your passwords,login preferences and recovery methods.",
    links: [
      {
        name: "Change password",
        path: "/change-password",
        icon: <MdPeopleOutline size={24} />,
      },
      {
        name: "Two-factor authentication",
        path: "/two-factor-authentication",
        icon: <MdPeopleOutline size={24} />,
      },
      {
        name: "Saved login",
        path: "/saved-login",
        icon: <MdPeopleOutline size={24} />,
      },
    ],
  },
  {
    title: "Security checks",
    description:
      "Review security issues by running checks across apps, devices and emails sent.",
    links: [
      {
        name: "When you're logged in",
        path: "/",
        icon: <MdNotifications size={24} />,
      },
      {
        name: "Login alerts",
        path: "/",
        icon: <MdNotifications size={24} />,
      },
      {
        name: "Resent emails",
        path: "/",
        icon: <GrShieldSecurity size={24} />,
      },
      {
        name: "Security Checkup",
        path: "/",
        icon: <GrShieldSecurity size={24} />,
      },
    ],
  },
];

const SettingsSection = ({ title, links, description }) => (
  <div className="w-full">
    <span className="text-[16px] font-semibold text-primary">{title}</span>
    <p className="text-[14px] font-medium mb-2 mt-1">{description}</p>
    <div className="w-full h-fit px-[1rem] py-[1rem] rounded-[6px] bg-[#96969a0e] text-[#000000a8] flex flex-col gap-[1.2rem] mx-auto">
      {links.map((link) => (
        <PSLinks
          key={link.name}
          name={link.name}
          icon={link.icon}
          path={link.path}
        />
      ))}
    </div>
  </div>
);

function PasswordSecurity(props) {
  const navigate = useNavigate();
  const logout = () => {
    clearStorageAndSession();
    navigate("/signin");
  };
  return (
    <>
      <HeaderWrapper title="Password and security" path="settings" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[2rem] pt-5">
        {/* <div className=" text-start w-full">
          <h1 className="font-[600] text-[20px]">Password and security</h1>
        </div> */}
        {sections.map((section) => (
          <SettingsSection
            key={section.title}
            title={section.title}
            links={section.links}
            description={section.description}
          />
        ))}
        <PrimaryButton
          action={logout}
          active
          name="Logout"
          className="max-w-[244px] w-full  mx-auto"
          fontSize="18px"
        />
      </div>
    </>
  );
}

export default PasswordSecurity;
