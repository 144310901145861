import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

function ChangePassword() {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  return (
    <div>
      <HeaderWrapper title="Change password" path="password-and-security" />

      <div className="px-3 py-3 mt-[1rem] w-full h-full">
        <p className="font-[500] text-[14px]">
          Your password must be at least 6 characters and should include a
          combination of numbers, letters and special characters (!$@%).
        </p>

        <div className="w-full h-full mt-[1rem] flex flex-col justify-between">
          <div className="w-full">
            <div className="mb-3">
              <Controller
                name="current_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="Current Password"
                    className="mb-2"
                  />
                )}
              />
            </div>

            <div className="mb-3">
              <Controller
                name="new_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    label="New Password"
                    className="mb-2"
                  />
                )}
              />
            </div>

            <div className="mb-3">
              <Controller
                name="retype_new_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    type="text"
                    label="Retype new password"
                    className="mb-2"
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <Link className="text-primary font-[500]" to={"/forgot-password"}>
                forgotten your password?
              </Link>
            </div>
          </div>

          <div className="absolute bottom-1 left-0 mt-auto mb-[30px] w-full flex justify-center">
            <PrimaryButton
              active
              name="Change Password"
              type="submit"
              className="w-full max-w-xs "
            //   action={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
