import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const doctorSlice = createSlice({
  name: "doctor",
  initialState: {},
  reducers: {
    doctorSuccess: (state, action) => {
      // Directly mutate the state properties
      state.doctor_success = action.payload;
      state.doctor_error = null; // Reset error state on success
    },
    doctorFailure: (state, action) => {
      // Directly mutate the state properties
      state.doctor_error = action.payload;
      state.doctor_success = null; // Reset success state on failure
    },
  },
});

export const { doctorSuccess, doctorFailure } = doctorSlice.actions;

// Asynchronous thunk action for logging in
export const getDoctor = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`doctors/${params}`);
    dispatch(doctorSuccess(response.data));

  } catch (error) {
    // Handle errors, such as incorrect credentials or server issues
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(doctorFailure(errorMessage));
    throw errorMessage; // Rethrow the error for further handling
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = doctorSlice.name;

export default doctorSlice.reducer;
