import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const helpSupportSlice = createSlice({
  name: "helpSupport",
  initialState: {},
  reducers: {
    helpSupportSuccess: (state, action) => {
      // Directly mutate the state properties
      state.helpSupport_success = action.payload;
      state.helpSupport_error = null; // Reset error state on success
    },
    helpSupportFailure: (state, action) => {
      // Directly mutate the state properties
      state.helpSupport_error = action.payload;
      state.helpSupport_success = null; // Reset success state on failure
    },
  },
});

export const { helpSupportSuccess, helpSupportFailure } =
  helpSupportSlice.actions;


export const getFaqs = () => async (dispatch) => {
  try {
    const response = await axios.get("assistance-centre/patient/faq");
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const getUserGuides = () => async (dispatch) => {
  try {
    const response = await axios.get("assistance-centre/patient/user_guide");
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const getTroubleShootGuides = () => async (dispatch) => {
  try {
    const response = await axios.get("assistance-centre/patient/troubleshooting_guide");
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const getTermPolicies = () => async (dispatch) => {
  try {
    const response = await axios.get("assistance-centre/patient/terms_and_policies");
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const getFeedback = () => async (dispatch) => {
  try {
    const response = await axios.get("assistance-centre/patient/feedback");
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const AddFeedback = (params) => async (dispatch) => {
  try {
    const response = await axios.post("assistance-centre/patient/feedback", params);
    return response
  } catch (error) {

    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};


// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = helpSupportSlice.name;

export default helpSupportSlice.reducer;
