import React, { useEffect, useState, useRef } from 'react';
import ConfirmDialog from '@medoczen/core/ConfirmDialog';
import { Fab } from '@mui/material';

export default function AppointmentWebsocket({ item }) {
    const [sessionCode, setSessionCode] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [websocketConnected, setWebSocketConnected] = useState(false);
    const [showFab, setShowFab] = useState(false);
    const wsRef = useRef(null);
    const retryCountRef = useRef(0);
    const timerRef = useRef(null);

    const connectWebSocket = () => {
        if (wsRef.current || websocketConnected) {
            // console.log('WebSocket connection is already open or in progress.');
            return;
        }

        // console.log('Attempting to open WebSocket connection');
        wsRef.current = new WebSocket(
            `wss://xil33jjow7.execute-api.eu-west-2.amazonaws.com/v1?appointment_id=${item?.id}&user_id=${item?.patient?.id}&user_type=patient`
        );

        wsRef.current.onopen = () => {
            // console.log('WebSocket connection opened');
            retryCountRef.current = 0;
            setWebSocketConnected(true);
        };

        wsRef.current.onmessage = (message) => {
            try {
                // console.log('Received message:', message.data);
                setSessionCode(message.data);
                setOpenDialog(true);
            } catch (error) {
                // console.error('Error handling message:', error);
            }
        };

        wsRef.current.onerror = (error) => {
            // console.error('WebSocket error:', error);
            setWebSocketConnected(false);
            wsRef.current = null;

            if (retryCountRef.current < 5) {
                setTimeout(() => {
                    retryCountRef.current += 1;
                    connectWebSocket();
                }, 10000);
            } else {
                // console.error('Max retry attempts reached.');
            }
        };

        wsRef.current.onclose = () => {
            // console.log('WebSocket connection closed');
            setWebSocketConnected(false);
            wsRef.current = null;
        };
    };

    useEffect(() => {
        if (item && item.date && item.time) {
            // Parse the date and time
            const appointmentDate = new Date(item.date);
            const [hours, minutes, seconds] = item.time.split(':').map(Number);

            // Set the appointment time with correct hours, minutes, and seconds
            appointmentDate.setHours(hours, minutes, seconds, 0);

            const currentTime = new Date();
            const timeDifference = appointmentDate - currentTime;
            const fiveMinutesInMs = 5 * 60 * 1000;
            const thirtyMinutesInMs = 30 * 60 * 1000;

            if (timeDifference <= fiveMinutesInMs && timeDifference >= -thirtyMinutesInMs) {
                // Show Fab button immediately
                setShowFab(true);
            } else if (timeDifference > fiveMinutesInMs) {
                // Schedule to show Fab button 5 minutes before appointment
                timerRef.current = setTimeout(() => {
                    setShowFab(true);
                }, timeDifference - fiveMinutesInMs);
            }
        }

        return () => clearTimeout(timerRef.current);
    }, [item]);

    const handleFabClick = () => {
        connectWebSocket();
    };

    const handleDialogAction = () => {
        // console.log('Session code confirmed:', sessionCode);
        setOpenDialog(false);
    };

    return (
        <>
            {showFab && item?.status !== 'completed' && (
                <Fab
                    color='primary'
                    variant="extended"
                    onClick={handleFabClick}
                    disabled={websocketConnected}
                >
                    Check In
                </Fab>
            )}
            <ConfirmDialog
                open={openDialog}
                title="Session Code Received"
                content={sessionCode}
                Action={handleDialogAction}
                onClose={() => setOpenDialog(false)}
            />
        </>
    );
}
