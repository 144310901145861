import React from "react";
import { Button, CircularProgress } from "@mui/material";

const PrimaryButton = React.forwardRef((props, ref) => {
  const {
    name,
    className,
    action,
    backgroundColor,
    fontSize,
    active,
    disabled,
    loading
  } = props;

  // Default width for the button if className is not provided
  const defaultWidth = "w-[315px]";

  return (
    <Button
      ref={ref} // Forward the ref to the MUI Button
      variant="contained"
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : active
            ? "#005eb8"
            : "#C7C7C7",
        textTransform: "none",
        fontSize: fontSize ? fontSize : "",
        color: !active && "black",
      }}
      className={className || defaultWidth} // Use className if provided, otherwise use default width
      onClick={action} // Simplify onClick event handler
      disabled={disabled}

    >
      {loading ? <CircularProgress size={32} color="inherit" sx={{ color: "white" }} /> : name}
    </Button>
  );
});

export default PrimaryButton;
