import EventEmitter from "events";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  setUser,
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
} from "@medoczen/core/MDZsessions";

class AuthService extends EventEmitter {
  constructor() {
    super();
    console.log("AuthService initialized");
    this.init();
  }

  init() {
    console.log("AuthService init called");
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors() {
    console.log("Setting up Axios interceptors");
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.error("Axios interceptor caught an error:", error);
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest.__isRetryRequest) {
          console.warn("Received 401 error, attempting to refresh token");
          originalRequest.__isRetryRequest = true;
          try {
            const newTokens = await this.refreshToken();

            if (newTokens) {
              console.log("Token refreshed successfully");
              originalRequest.headers["Authorization"] = `Bearer ${newTokens.jwtToken}`;
              return axios(originalRequest);
            } else {
              console.error("Failed to refresh token");
              this.emit("onAutoLogout", "Failed to refresh token");
              this.setSession(null);
            }
          } catch (err) {
            console.error("Error during token refresh:", err);
            this.emit("onAutoLogout", "Failed to refresh token");
            this.setSession(null);
            return Promise.reject(err);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  handleAuthentication() {
    console.log("handleAuthentication called");
    const accessToken = this.getAccessToken();
    console.log("Current access token:", accessToken);

    if (!accessToken) {
      console.warn("No access token found, attempting to refresh token");
      // Attempt to refresh token if no access token is found
      this.refreshToken()
        .then(() => {
          console.log("Auto login after token refresh");
          this.emit("onAutoLogin", true);
        })
        .catch((error) => {
          console.error("Failed to refresh token:", error);
          this.emit("onAutoLogout", "Failed to refresh token");
        });
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      console.log("Access token is valid");
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      console.warn("Access token is invalid or expired, attempting to refresh");
      // Token is invalid or expired, attempt to refresh
      this.refreshToken()
        .then(() => {
          console.log("Auto login after token refresh");
          this.emit("onAutoLogin", true);
        })
        .catch((error) => {
          console.error("Failed to refresh token:", error);
          this.setSession(null);
          this.emit("onAutoLogout", "access_token expired");
        });
    }
  }

  isAuthTokenValid(accessToken) {
    console.log("Checking if auth token is valid:", accessToken);
    if (!accessToken) {
      console.warn("No access token provided");
      return false;
    }
    try {
      const decoded = jwtDecode(accessToken);
      console.log("Decoded token:", decoded);
      const currentTime = Date.now() / 1000;
      console.log("Current time:", currentTime, "Token expires at:", decoded.exp);
      return decoded.exp > currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  }

  getAccessToken() {
    const token = getToken();
    console.log("Retrieved access token from storage:", token);
    return token;
  }

  setSession(accessToken) {
    console.log("Setting session with access token:", accessToken);
    if (accessToken) {
      setToken(accessToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      try {
        const decoded = jwtDecode(accessToken);
        const expiresAt = decoded.exp;
        console.log("Token expires at:", new Date(expiresAt * 1000));

        let timeout = expiresAt * 1000 - Date.now() - 60000; // 1 minute before expiry
        if (timeout < 0) {
          console.warn("Token is already expired or about to expire, setting timeout to 0");
          timeout = 0;
        }
        console.log("Setting refresh token timeout in:", timeout, "ms");

        if (this.refreshTokenTimeout) {
          clearTimeout(this.refreshTokenTimeout);
        }

        this.refreshTokenTimeout = setTimeout(() => {
          console.log("Refresh token timeout reached, refreshing token");
          this.refreshToken();
        }, timeout);
      } catch (error) {
        console.error("Error decoding token in setSession:", error);
      }
    } else {
      console.log("Clearing session and removing access token");
      localStorage.removeItem("jwt_app_access_token");
      delete axios.defaults.headers.common["Authorization"];
      if (this.refreshTokenTimeout) {
        clearTimeout(this.refreshTokenTimeout);
      }
    }
  }

  async signInWithEmailAndPassword({ email, password }) {
    console.log("Signing in with email and password:", email);
    try {
      const response = await axios.post("auth/signin", { email, password });
      const { jwtToken, refreshToken } = response.data;
      console.log("Received tokens:", jwtToken, refreshToken);

      if (jwtToken) {
        setUser(response.data);
        setRefreshToken(refreshToken);
        this.setSession(jwtToken);
        return response.data;
      } else {
        console.error("No access token received during sign-in");
        return "No access token received";
      }
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during sign-in:", errorMessage);
      throw errorMessage;
    }
  }

  async signUpWithEmailAndPassword({ name, email, password }) {
    console.log("Signing up with email and password:", email);
    try {
      const response = await axios.post("auth/signup/doctor", { name, email, password });
      console.log("Sign-up response data:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during sign-up:", errorMessage);
      throw errorMessage;
    }
  }

  async refreshToken() {
    console.log("Attempting to refresh token");
    try {
      const refreshToken = getRefreshToken();

      console.log("Retrieved refresh token:", refreshToken);

      // Temporarily remove the Authorization header
      const originalAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];

      const response = await axios.post("auth/refresh", { refreshToken });

      // Restore the original Authorization header
      if (originalAuthHeader) {
        axios.defaults.headers.common["Authorization"] = originalAuthHeader;
      }

      const { jwtToken } = response.data;
      console.log("Received new JWT token from refresh:", jwtToken);

      if (jwtToken) {
        setUser(response.data);
        this.setSession(jwtToken);
        return response.data;
      } else {
        console.error("No access token received during token refresh");
        return "No access token received";
      }
    } catch (error) {
      // Restore the original Authorization header in case of error
      const originalAuthHeader = axios.defaults.headers.common["Authorization"];
      if (!originalAuthHeader) {
        axios.defaults.headers.common["Authorization"] = null;
      }
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during token refresh:", errorMessage);
      throw errorMessage;
    }
  }

  async verifyEmail({ confirmation_code, username }) {
    console.log("Verifying email for user:", username);
    try {
      const response = await axios.post("auth/confirm", { confirmation_code, username });
      console.log("Email verification response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during email verification:", errorMessage);
      throw errorMessage;
    }
  }

  async changePasswordCode({ email, code }) {
    console.log("Changing password code for email:", email);
    try {
      const response = await axios.post("auth/confirm", { email, code });
      console.log("Change password code response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during change password code:", errorMessage);
      throw errorMessage;
    }
  }

  async forgotPassword({ email }) {
    console.log("Requesting forgot password for email:", email);
    try {
      const response = await axios.post("auth/forgot/password", { email });
      console.log("Forgot password response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during forgot password:", errorMessage);
      throw errorMessage;
    }
  }

  async resetPassword({ username, code, password }) {
    console.log("Resetting password for username:", username);
    try {
      const response = await axios.post("auth/reset-password", { username, code, password });
      console.log("Reset password response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error during reset password:", errorMessage);
      throw errorMessage;
    }
  }

  async getPersonalDetails() {
    console.log("Fetching personal details");
    try {
      const response = await axios.get("auth/profile");
      console.log("Personal details response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error fetching personal details:", errorMessage);
      throw errorMessage;
    }
  }

  async updatePersonalDetails(params) {
    console.log("Updating personal details with params:", params);
    try {
      const response = await axios.put("auth/profile", params);
      console.log("Update personal details response:", response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data || error.message || "An unexpected error occurred";
      console.error("Error updating personal details:", errorMessage);
      throw errorMessage;
    }
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
