import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import VerificationPng from "@/../../public/assets/images/verification.png";
import { verifyEmail } from "./store/verificationSlice";

import { GoChevronLeft } from "react-icons/go";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Verification(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();
  const confirmation_code = query.get("confirmation_code");
  const username = query.get("username");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { module, email } = location?.state || {};

  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbarOpen]);

  useEffect(() => {
    const verifyEmailAsync = async () => {
      try {
        if (confirmation_code && username) {
          setLoading(true);

          // Assuming dispatch returns a Promise, for example when using Redux Thunk
          await dispatch(verifyEmail({ confirmation_code, username }));
          setLoading(false);
          navigate("/signin");
        }
      } catch (error) {
        console.error("Failed to verify email:", error);
        // Handle the error as needed, e.g., set error state, show notification, etc.
      }
    };

    verifyEmailAsync();
  }, []);

  useEffect(() => {
    if (!module) {
      navigate("/signin");
    }
  }, [module]);

  return (
    <div className="flex flex-col items-center justify-end px-[1.5rem]  pb-[4rem] w-full">
      {/* Sign up image */}
      <div className="w-full flex justify-center ">
        <img
          className="w-[300px]"
          src={VerificationPng}
          alt=" image"
          loading="lazy"
        />
      </div>

      {/* Sign in form */}
      <div className="flex flex-col justify-start mt-5 ">
        <div className="mb-5 text-center">
          <h1 className=" font-[600] mb-2 text-[24px]">
            {module == "reset_password" && "Password Reset Instructions Sent"}
            {module == "signin" && "Verification Code Sent"}
          </h1>
          <p className="font-[400] text-[14px] opacity-90">
            Please check your email- <strong>{email}</strong>
          </p>
        </div>
      </div>
      <Link
        to={"/"}
        className="absolute top-[2rem] start-[2rem] w-[40px] h-[40px]  rounded-full border border-[#00000056] flex items-center place-content-center"
      >
        {" "}
        <GoChevronLeft size={26} />{" "}
      </Link>
    </div>
  );
}

export default Verification;
