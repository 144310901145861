import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';

const cholesterolRanges = [
  {
    category: 'Dangerous',
    totalCholesterol: { min: 240, max: Infinity },
    ldlCholesterol: { min: 160, max: Infinity },
    hdlCholesterol: { male: { min: 0, max: 40 }, female: { min: 0, max: 50 } },
    backgroundColor: '#EC2127',
  },
  {
    category: 'AT-Risk',
    totalCholesterol: { min: 200, max: 239 },
    ldlCholesterol: { min: 100, max: 159 },
    hdlCholesterol: { male: { min: 40, max: 59 }, female: { min: 50, max: 59 } },
    backgroundColor: '#FFC905',
  },
  {
    category: 'HEART-HEALTHY',
    totalCholesterol: { min: 0, max: 199 },
    ldlCholesterol: { min: 0, max: 99 },
    hdlCholesterol: { male: { min: 60, max: Infinity }, female: { min: 60, max: Infinity } },
    backgroundColor: '#3EB840',
  },
];

const CholesterolLevelBox = ({ patientData }) => {
  const { totalCholesterol, ldlCholesterol, hdlCholesterol, gender } = patientData;

  const determineCategory = () => {
    for (const range of cholesterolRanges) {
      const hdlRange = gender === 'male' ? range.hdlCholesterol.male : range.hdlCholesterol.female;
      if (
        totalCholesterol >= range.totalCholesterol.min &&
        totalCholesterol <= range.totalCholesterol.max &&
        ldlCholesterol >= range.ldlCholesterol.min &&
        ldlCholesterol <= range.ldlCholesterol.max &&
        hdlCholesterol >= hdlRange.min &&
        hdlCholesterol <= hdlRange.max
      ) {
        return range;
      }
    }
    return null;
  };

  const category = determineCategory();

  if (!category) {
    return <Typography>No category found for the provided data.</Typography>;
  }

  return (

    <Box sx={{ width: '100%', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ width: '100%'}}>
      <Typography variant="h6" sx={{ fontSize: 17, fontWeight: 'bold' }}>
      Cholesterol Levels
      </Typography>
    </Box>

    <Paper
      sx={{
        width: '100%',
        mb: '1rem',
        borderRadius: '3px',
        backgroundColor: '#96969a0e',
        boxShadow: '0px 3px 10px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '39px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '700',
          fontSize: '20px',
          color: 'white',
          borderRadius: '3px 3px 0 0',
          backgroundColor: category.backgroundColor,
        }}
      >
        {category.category}
      </Box>

      <Grid className='bg-lightGray' container sx={{ height: '100%' }}>
        <Grid item xs={4} sx={gridStyle}>
          <Typography variant="body2">Total Cholesterol</Typography>
          <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '14px' }}>
            {totalCholesterol}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ ...gridStyle, borderLeft: '2px solid #d9d9d9', borderRight: '2px solid #d9d9d9' }}>
          <Typography variant="body2">LDL Cholesterol</Typography>
          <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '14px' }}>
            {ldlCholesterol}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={gridStyle}>
          <Typography variant="body2">HDL Cholesterol</Typography>
          <Typography variant="h6" sx={{ fontWeight: '600', fontSize: '14px' }}>
            {hdlCholesterol}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
    </Box>

  );
};

const gridStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  textAlign: 'center',
};
 
export default CholesterolLevelBox;
