import React, { useEffect, useState } from "react";
import { IoVideocam } from "react-icons/io5";
import { HiHome } from "react-icons/hi";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { Box, Skeleton } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import BasicTabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { getAppointment, selectAppointment } from "./store/AppointmentSlice";
import AppointmentWebsocket from "@medoczen/core/WebSockets/AppointmentWebsocket";

function AppointmentDetails(props) {
  const [loading, setLoading] = useState(true);
  const { appointmentId } = useParams();
  const navigate = useNavigate(); // Hook to get the navigate function
  const dispatch = useDispatch();
  const appointment = useSelector(selectAppointment);

  useEffect(() => {
    sessionStorage.setItem("appointment_details", JSON.stringify(appointment));
  }, [appointment]);

  function FetchAppointment() {
    dispatch(getAppointment(appointmentId))
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching :", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    FetchAppointment();
  }, []);

  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ countryCode }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(countryCode);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return <img src={flagSrc} className="absolute top-[5px] left-[5px] w-[15px] h-[10px]" />;
  };

  function toLowercaseHyphenateAndLabel(text) {
    return (
      text
        .toLowerCase() // Convert all characters to lowercase
        .replace(/\s+/g, "-") + // Replace all spaces with hyphens
      "-label"
    ); // Append '-label' at the end
  }

  const ButtonClick = (e) => {};

  return (
    <div className="w-full h-full relative ">
      <div className="inset-0 bg-primary w-full h-[100px] sticky flex items-center justify-center z-[99]">
        <div className="grid grid-cols-[auto_1fr_auto] mt-[-45px] w-full max-w-4xl px-4 items-center">
          {/* Left column for back navigation or sidebar toggle */}
          <div className="flex justify-start">
            <Link
              to={`/appointments`}
              className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
            >
              <GoChevronLeft color="white" size={26} />
            </Link>
          </div>

          {/* Center column for title */}
          <div className="flex justify-center">
            <h1 className="font-[600] text-lg text-white">Appointment</h1>
          </div>

          {/* Right column for children and optional notification icon */}
          <div className="flex justify-end min-w-[1rem]">
            <div className="gap-2"></div>
          </div>
        </div>

        <div className="absolute top-[3.5rem]">
          {loading ? (
            <Box className="relative   px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <Skeleton variant="rectangular" width={100} height={60} />
              </div>

              <Box className="flex flex-col justify-around gap-3 w-full ml-[8px]">
                <Skeleton variant="body1" width={130} height={10} />

                <Skeleton variant="body1" width={130} height={10} />
                <Skeleton variant="body1" width={130} height={10} />
              </Box>
              <Skeleton variant="rectangular" width={100} height={60} />
            </Box>
          ) : (
            <Box className="relative   px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <img src={appointment?.doctor?.selfie} className="w-[130px] rounded-[6px]" alt="" />
                <CountryFlag countryCode={appointment?.doctor?.country_code ?? "US"} />
              </div>

              <Box className="flex flex-col justify-center w-full ml-[8px]">
                <span className="text-[16px] font-[600]">{appointment?.doctor?.full_name}</span>
                <span
                  className={`text-[11px] font-[700] rounded-[3px] text-white text-left ${
                    appointment?.doctor?.specialties[0]?.name &&
                    toLowercaseHyphenateAndLabel(appointment?.doctor?.specialties[0]?.name)
                  } bg-orange-400  py-[2px] px-[4px] inline-block w-fit`}
                >
                  {appointment?.doctor?.specialties[0]?.name}
                </span>

                <span className="text-[12px] font-[500] lowercase">
                  {appointment?.doctor?.schedule?.open_time?.hour ? (
                    <>
                      {" "}
                      {appointment?.doctor?.schedule?.open_time?.hour}
                      {appointment?.doctor?.schedule?.open_time?.period} -
                      {appointment?.doctor?.schedule?.close_time?.hour}
                      {appointment?.doctor?.schedule?.open_time?.period}
                    </>
                  ) : (
                    "not available"
                  )}
                </span>
              </Box>
              <Box className="text-[#616161] px-5">
                {appointment?.type == "in-home" && <HiHome size={30} className="my-[1px]" />}
                {appointment?.type == "video" && <IoVideocam size={30} className="my-[1px]" />}
                {appointment?.type == "chat" && (
                  <BiSolidMessageRoundedDetail size={30} className="my-[1px]" />
                )}
              </Box>
            </Box>
          )}
        </div>
      </div>
      <div className="flex gap-5  sticky top-[100px] z-[40] justify-around items-center px-3 py-3 w-full  pt-[4rem] bg-white">
        <div className="text-[14px] font-[600] flex-1 h-[80px] bg-[#E4C1A6] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Date</h1>
          <span>{new Date(appointment?.date).toLocaleDateString()}</span>
        </div>
        <div className="text-[14px] font-[600] flex-1 h-[80px] bg-[#B6B0E7] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Time Slot</h1>
          <span>{appointment?.time}</span>
        </div>
        <div className="text-[14px] font-[600] flex-1 h-[80px] bg-[#A8D297] rounded-[8px] flex flex-col justify-center items-center ">
          <h1>Rate</h1>
          <span> £{appointment?.doctor?.schedule?.rate || ""} /hr</span>
        </div>
      </div>

      <div className="mb-[3rem]">
        <BasicTabs
          doctor_id={appointment?.doctor?.id}
          appointment_id={appointmentId}
          status={appointment?.status}
          loading={loading}
        />
      </div>

      {/* <div className="fixed bottom-0 flex w-full justify-center bg-white pb-5">
        <PrimaryButton
          name="Book Appointment"
          className="w-[244px] "
          fontSize="18px"
          active
          action={ButtonClick}
        />
      </div> */}

      <div className="fixed bottom-10 right-5 ">
        {" "}
        <AppointmentWebsocket item={appointment} />
      </div>
    </div>
  );
}

export default AppointmentDetails;
