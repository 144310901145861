import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";

import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import ResetPng from "@/../../public/assets/images/Reset password.png";
import { BsEyeSlash } from "react-icons/bs";
import { GoChevronLeft } from "react-icons/go";
import { reset_password } from "./store/resetPasswordSlice";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange", // Ensures validation on every change
  });
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");
  const passwordsMatch = newPassword === confirmPassword && newPassword;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error"); // New state for alert severity
  const [loading, setLoading] = useState(false);

  const selectUser = useSelector(
    (state) => state.resetPassword.resetPasswordSlice,
  );
  const query = useQuery();
  const confirmation_code = query.get("confirmation_code");
  const username = query.get("username");

  useEffect(() => {
    if (selectUser?.success) {
      const { $metadata } = selectUser.success;
      setLoading(false);
      if ($metadata.httpStatusCode === 200) {
        setSnackbarOpen(true);
        setSnackbarMessage("Password Changed");
        setAlertSeverity("success"); // Set severity to success

        setTimeout(() => {
          navigate("/signin");
        }, 2000);
      }
    } else if (selectUser?.error) {
      setLoading(false);
      let errorMessage = "Something went wrong";
      if (selectUser.error.name === "ExpiredCodeException") {
        errorMessage = "Code Expired.";
      }
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      setAlertSeverity("error"); // Set severity to error
    }
  }, [selectUser, navigate]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      reset_password({
        username: username,
        code: confirmation_code,
        password: data.newPassword,
      }),
    );
  };

  return (
    <div className="flex flex-col items-center px-8 py-3 w-full">
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <CircularProgress />
        </div>
      )}
      <div className="w-full flex justify-center">
        <img
          className="h-[200px] w-[300px]"
          src={ResetPng}
          alt="Reset password"
          loading="lazy"
        />
      </div>
      <div className="flex flex-col justify-start mt-5 w-full">
        {snackbarOpen && (
          <Alert severity={alertSeverity} className="mt-2" variant="filled">
            {snackbarMessage}
          </Alert>
        )}
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            rules={{ required: "New password is required" }}
            render={({ field }) => (
              <InputField
                label="New Password"
                textSize="text-[16px]"
                placeholder="Enter new password"
                endIcon={<BsEyeSlash size={"24px"} />}
                type="password"
                {...field}
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: "Confirm password is required",
              validate: (value) =>
                value === getValues("newPassword") ||
                "The passwords do not match",
            }}
            render={({ field }) => (
              <InputField
                label="Confirm Password"
                textSize="text-[16px]"
                placeholder="Confirm new password"
                endIcon={<BsEyeSlash size={"24px"} />}
                type="password"
                {...field}
              />
            )}
          />

          <div className="mt-[2rem] mb-[1rem] w-full flex justify-center">
            <PrimaryButton
              active={passwordsMatch && !Object.keys(errors).length} // Only active if passwords match and no errors
              name="Change Password"
              className="w-full h-[55px]"
              action={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
      <Link
        to="/"
        className="absolute top-[2rem] start-[2rem] w-[40px] h-[40px] rounded-full border border-[#00000056] flex items-center justify-center"
      >
        <GoChevronLeft size={26} />
      </Link>
    </div>
  );
}

export default ResetPassword;
