import React from "react";
import bg from "@/../../public/assets/images/videocall/bg.png";
import image1 from "@/../../public/assets/images/videocall/camera.png";
import CallControlDiv from "../Components/CallControlDiv";
function VideoCall() {
  return (
    <div
      className="relative bg-fixed bg-cover bg-center h-screen"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className=" absolute top-[4rem] w-full flex justify-between px-[1rem] py-0 items-start">
        <div className="text-white pt-[1rem]">
          <h1 className="font-[500] text-[24px] ">Carla</h1>{" "}
          <span className="text-[14px]">02:35</span>
        </div>

        <img src={image1} className="w-[150px] h-[150px] object-cover" alt="" />
      </div>

      <CallControlDiv />
    </div>
  );
}

export default VideoCall;
