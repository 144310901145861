import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'; // Import PropTypes

// Assuming heartPng is imported elsewhere in your project
import heartPng from "@/../../public/assets/images/Dashboard/heart.png";

const StyledImage = styled('img')({
  width: 105, // assuming you want to use pixels directly
  height: 113,
});

const BloodPressureComponent = ({ patientData }) => { // Accept patientData as a prop
  return (
    <Box sx={{ width: '100%', px: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%'}}>
        <Typography variant="h6" sx={{ fontSize: 17, fontWeight: 'bold' }}>
          Blood Pressure
        </Typography>
      </Box>

      <Paper
        elevation={3}
        className='bg-softBlue'
        sx={{
          width: '100%',
          display: 'flex',
          p: '1rem 3px',
          borderRadius: '3px',
           boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
        }}
      >
        <StyledImage src={heartPng} alt="" />

        <Box sx={{ fontSize: 14, fontWeight: 400, display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {patientData.sbp} mm Hg
          </Typography>
          <Typography variant="body2">Systolic Blood Pressure (SBP)</Typography>

          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {patientData.pulse} mm Hg
          </Typography>
          <Typography variant="body2">Pulse</Typography>

          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {patientData.dbp} mm Hg
          </Typography>
          <Typography variant="body2">Diastolic Blood Pressure (DBP)</Typography>
        </Box>
      </Paper>
    </Box>
  );
};

// Define PropTypes
BloodPressureComponent.propTypes = {
  patientData: PropTypes.shape({
    sbp: PropTypes.number.isRequired,
    pulse: PropTypes.number.isRequired,
    dbp: PropTypes.number.isRequired,
  }).isRequired,
};

export default BloodPressureComponent;