// src/components/LoadingSkeleton/LoadingSkeleton.js
import React from 'react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';



const LoadingSkeleton = () => {
    return (
        <div className="loading-skeleton">
            {/* Header Skeleton */}
            <Skeleton variant="text" width={200} height={40} />

            {/* Disclaimer Skeleton */}
            <div className="skeleton-section">
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton variant="rectangular" width="100%" height={60} />
            </div>

            {/* Features Skeleton */}
            <div className="skeleton-section">
                <Skeleton variant="text" width={100} height={30} />
                {[...Array(8)].map((_, index) => (
                    <div key={index} className="flex items-center gap-10">
                        <Skeleton variant="circular" width={25} height={25} />
                        <Skeleton variant="text" width="80%" height={20} />
                    </div>
                ))}
            </div>

            {/* Package Options Skeleton */}


            {/* Button Skeleton */}
            <div className='flex  items-center justify-center gap-[1rem] mt-20'>
                <Skeleton variant="rectangular" width={150} height={150} />
                <Skeleton variant="rectangular" width={150} height={150} />
            </div>
        </div>
    );
};

export default LoadingSkeleton;
