import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoTriangle } from "react-icons/io5";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import { CiClock2 } from "react-icons/ci";
import SortTab from "./SortTab";
import { useDispatch } from "react-redux";
import { getPrescriptions } from "../store/medicineSlice";
import { Avatar } from "@mui/material";

// Example of lab prescription data
const prescriptionListData = [
  {
    id: 1,
    code: "HB",
    color: "#86EABA",
    testName: "Hemoglobin",
    normalRange: "Normal 14.0-18.0",
    result: "21.9 g/dL",
    status: "High (+3.9)",
    statusColor: "#F8A221",
    awaiting: false,
    description: "Elevated levels may indicate polycythemia or dehydration.",
  },
  {
    id: 2,
    code: "TG",
    color: "#BEEA86",
    testName: "Triglyceride",
    normalRange: "Normal 0-150",
    result: "",
    status: "Awaiting",
    statusColor: "#F8A221",
    awaiting: true,
    description: "Triglycerides are a type of fat found in your blood, used for energy.",
  },
  {
    id: 3,
    code: "GLU",
    color: "#FFC107",
    testName: "Glucose",
    normalRange: "Normal 70-99 mg/dL",
    result: "125 mg/dL",
    status: "High",
    statusColor: "#D32F2F",
    awaiting: false,
    description: "High glucose levels may indicate diabetes or prediabetes.",
  },
  {
    id: 4,
    code: "CHOL",
    color: "#03A9F4",
    testName: "Cholesterol",
    normalRange: "Normal < 200 mg/dL",
    result: "220 mg/dL",
    status: "High",
    statusColor: "#F44336",
    awaiting: false,
    description: "Elevated cholesterol increases risk for heart disease and stroke.",
  },
  {
    id: 5,
    code: "WBC",
    color: "#8BC34A",
    testName: "WBC Count",
    normalRange: "Normal 4.0-10.0 x10^9/L",
    result: "11.2 x10^9/L",
    status: "High",
    statusColor: "#CDDC39",
    awaiting: false,
    description: "High WBC count may indicate infection or an immune system disorder.",
  },
  {
    id: 6,
    code: "HDL",
    color: "#009688",
    testName: "HDL Cholesterol",
    normalRange: "Normal 40-60 mg/dL",
    result: "35 mg/dL",
    status: "Low",
    statusColor: "#F8A221",
    awaiting: false,
    description: "Low HDL cholesterol may increase the risk of heart disease.",
  },
  {
    id: 7,
    code: "LDL",
    color: "#00BCD4",
    testName: "LDL Cholesterol",
    normalRange: "Normal < 100 mg/dL",
    result: "150 mg/dL",
    status: "High",
    statusColor: "#FF5722",
    awaiting: false,
    description: "High LDL cholesterol can lead to a buildup of plaques in arteries.",
  },
  {
    id: 8,
    code: "HBA1C",
    color: "#9C27B0",
    testName: "HbA1c",
    normalRange: "Normal < 5.7%",
    result: "6.5%",
    status: "High",
    statusColor: "#E91E63",
    awaiting: false,
    description: "An HbA1c level of 6.5% or higher indicates diabetes.",
  },
  {
    id: 9,
    code: "RBC",
    color: "#673AB7",
    testName: "RBC Count",
    normalRange: "Normal 4.7-6.1 x10^12/L",
    result: "4.5 x10^12/L",
    status: "Low",
    statusColor: "#3F51B5",
    awaiting: false,
    description: "Low RBC count may indicate anemia or other blood conditions.",
  },
  {
    id: 10,
    code: "PLT",
    color: "#FF9800",
    testName: "Platelet Count",
    normalRange: "Normal 150-450 x10^9/L",
    result: "100 x10^9/L",
    status: "Low",
    statusColor: "#FFC107",
    awaiting: false,
    description: "Low platelet count can affect blood clotting and lead to bleeding.",
  },
];

function PrescriptionList(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [prescriptionList, setPrescriptionList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPrescriptions()).then((res) => {
      setPrescriptionList(res?.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <HeaderWrapper left_nav title="Prescriptions" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        <div className="flex items-center w-full gap-[1rem]">
          <SearchBar placeholder="Search Here" />
          <SortTab />
        </div>
        <div className="w-full">
          <div className="w-full ps-[2px] pe-[2rem]">
            <h1 className="text-primary font-[500] text-[12px] ">
              Last Updated: January 25, 2023, at 3:30 PM
            </h1>
            <span className="text-[#000000b0] font-[400] text-[12px] ">
              Pull down page to check for new records
            </span>
          </div>
          <hr className="border-[#0000008c]  w-full" />
        </div>
        {prescriptionList.map((prescription) => (
          <div
            key={prescription.id}
            className="relative  min-h-[70px] rounded-[6px] bg-[#FAFAFA] flex flex-col justify-start items-start p-[1rem] gap-[0.5rem] w-full"
          >
            <div className="flex justify-between w-full">
              <div className="flex flex-col w-fit">
                <Avatar src={prescription?.doctor_details?.selfie} />
                <h1 className="text-[16px] font-[500] mt-[0.5rem]">
                  {prescription?.doctor_details?.full_name}
                </h1>
                <span className="text-[12px] font-[400]">
                  {new Date(prescription.created_at).toDateString()}
                </span>
              </div>

              <div className="flex flex-col justify-center">
                <span className="text-[12px] font-[400] text-orange-600">
                  {prescription?.prescription_status || "Pending"}
                </span>
              </div>
            </div>
            <div className="text-[12px] font-[400] text-gray-600 mt-[0.5rem]">
              <p className="">{prescription.description.split(". ")[0] + "."}</p>
            </div>

            <div className="absolute right-[5px] top-[5px] flex flex-col h-full py-[5px] justify-between items-end">
              <CiClock2 />
              <span className="text-[12px]" style={{ color: prescription.statusColor }}>
                {prescription.awaiting ? prescription.status : ""}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PrescriptionList;
