import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import avatar from "@/../../public/assets/images/avatar.png";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

import Alert from "@mui/material/Alert";
import { FaRegEdit } from "react-icons/fa";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import { MdOutlineCalendarToday } from "react-icons/md";
import SelectDialog from "@medoczen/core/SelectDialog/SelectDialog";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";

import {
  getPersonalDetails,
  updatePersonalDetails,
} from "./store/personalDetailsSlice";
import CountriesDropdown from "@medoczen/core/CountriesDropdown/CountriesDropdown";
import { setUser } from "@medoczen/core/MDZsessions";
import SkeletonPage from "./SkeletonPage";
import DropDownSheet from "@medoczen/core/DropDownSheet/DropDownSheet";

function Intro(props) {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const selectUser = useSelector(
    (state) => state.personalDetails.personalDetailsSlice,
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [tempImage, setTempImage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let timer;
    if (snackbarOpen) {
      timer = setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [snackbarOpen]);

  useEffect(() => {
    if (selectUser?.personalDetails_success) {
      setLoading(false);
      setUser(selectUser?.personalDetails_success);
      reset(selectUser?.personalDetails_success);
    } else if (selectUser?.personalDetails_error) {
      setLoading(false);

      if (
        selectUser?.personalDetails_error?.name === "NotAuthorizedException"
      ) {
        setSnackbarMessage("Username/Password is incorrect");
        setSnackbarOpen(true);
      } else if (
        selectUser?.personalDetails_error?.CodeDeliveryDetails?.Destination
      ) {
        // Handle the success scenario of code being sent
        setSnackbarMessage(
          `Code sent to ${selectUser.personalDetails_error.CodeDeliveryDetails.Destination}`,
        );
        setSnackbarOpen(true);
      } else if (
        selectUser.personalDetails_error.name === "LimitExceededException"
      ) {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`,
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  useEffect(() => {
    setLoading(true);
    dispatch(getPersonalDetails());
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(updatePersonalDetails(form)).then(() => {
      setTempImage(null);
    });
  };

  if (loading) {
    return <SkeletonPage />;
  }
  return (
    <>
      <HeaderWrapper title="Personal Details" path={"settings"} />
      <div className="flex flex-col items-center px-4 py-3 w-full">
        {/* PersonalDetails Image Upload */}
        <div className="w-full flex justify-center pb-5">
          <Controller
            name="selfie"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <label className="relative">
                <img
                  src={
                    tempImage
                      ? tempImage
                      : `${form?.selfie
                        ? `${form.selfie}?${new Date().getTime()}`
                        : `${avatar}?${new Date().getTime()}`
                      }`
                  }
                  className="w-[100px] h-[100px] rounded-full"
                />

                <input
                  id="image"
                  name={name}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setTempImage(reader.result);
                        onChange({
                          name: file.name,
                          type: file.type,
                          file: reader.result,
                        });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  onBlur={onBlur}
                  ref={ref}
                />
                <div className="absolute right-0 bottom-0 bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center shadow-md cursor-pointer">
                  <FaRegEdit size={20} />
                </div>
              </label>
            )}
          />
        </div>

        {snackbarOpen && (
          <div>
            <Alert className="mt-2" variant="filled" severity="error">
              {snackbarMessage}
            </Alert>
          </div>
        )}

        <div className="w-full">
          <div className="mb-3">
            <Controller
              name="full_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField {...field} label="Name" className="mb-2" />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={true}
                  label="Email"
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="Phone"
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="address_line1"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="Address 1"
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="mb-3">
            <Controller
              name="address_line2"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputField
                  {...field}
                  type="text"
                  label="Address 2"
                  className="mb-2"
                />
              )}
            />
          </div>

          <div className="grid grid-cols-4 gap-4">
            <div className="mb-3 col-span-2">
              <Controller
                name="city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    type="text"
                    label="City"
                    className="mb-2"
                  />
                )}
              />
            </div>

            <div className="mb-3 col-span-2">
              <Controller
                name="postcode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputField
                    {...field}
                    type="text"
                    label="Postcode"
                    className="mb-2"
                  />
                )}
              />
            </div>
          </div>


          <div className="mb-3">
            <FormControl fullWidth>
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CountriesDropdown
                    label="Country"
                    field={field}
                    setValue={setValue}
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="mb-3">
            <Controller
              name="Birthday"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MDatePicker
                  label="Start date"
                  name="start_date"
                  control={control}
                  icon={<MdOutlineCalendarToday size={24} />}
                  className="mb-2"
                />
              )}
            />
          </div>
          <div className="mb-[7rem]">
            <Controller
              name="gender_id"
              control={control}
              render={({ field }) => (
                <DropDownSheet
                  field={field}
                  label="Gender"
                  options={[
                    { value: '1', label: 'Male' },
                    { value: '2', label: 'Female' },
                    { value: '3', label: 'Other' },
                  ]}
                />
              )}
            />

          </div>
          <div className="fixed bottom-0 bg-white p-2 left-0 w-full flex justify-center z-[99]">
            <PrimaryButton
              active
              name="Save"
              type="submit"
              className="w-full max-w-xs "
              action={onSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;
