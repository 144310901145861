import React from "react";

import { Skeleton } from "@mui/material"; // Import CircularProgress

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";

function SkeletonPage(props) {
  return (
    <>
      <HeaderWrapper title="Personal Details" path={"settings"} />
      <div className="flex flex-col items-center px-8  py-3 w-full">
        <div className="w-full flex justify-center pb-5">
          <Skeleton variant="circular" width={100} height={100} />
        </div>

        <div className="w-full">
          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="grid grid-cols-4 gap-4">
            <div className="mb-5 col-span-2">
              <Skeleton variant="rectangular" width="100%" height={60} />
            </div>

            <div className="mb-5 col-span-2">
              <Skeleton variant="rectangular" width="100%" height={60} />
            </div>
          </div>

          <div className="mb-[20px]">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mb-5">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>

          <div className="mt-4 mb-[30px] w-full flex justify-center">
            <Skeleton variant="rectangular" width="100%" height={60} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SkeletonPage;
