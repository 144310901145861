import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FaCheck, FaRegEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { postAnswer } from "pages/Health-assessment/store/healthAssessmentSlice";
import InputField from "../InputField/InputField";

const Option = ({ id, label, selectedOption, handleOptionChange }) => {
  return (
    <label
      htmlFor={id}
      className={`flex justify-between gap-2 items-center px-4 w-40 h-12 border-2 rounded-lg ${selectedOption === id ? "border-primary" : "border-gray-300"}`}
      onClick={() => handleOptionChange(id)}
    >
      <input
        type="radio"
        id={id}
        name="options"
        checked={selectedOption === id}
        onChange={() => handleOptionChange(id)}
        className="hidden"
      />
      <span className=" font-semibold">{label}</span>
      <span
        className={`w-fit h-fit text-sm text-white rounded-full p-1 ${selectedOption === id ? "bg-primary" : "border border-gray-300"}`}
      >
        <FaCheck />
      </span>
    </label>
  );
};

export default function AnswerUpdateDialog(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleClose = () => {
    setSelectedOption(null);
    setOpen(false);
  };

  const handleSubmit = () => {
    const answer = {
      question_id: props.n.question_id,
      answer: selectedOption,
    };
    dispatch(postAnswer(answer)).then(() => {
      props.fetchData();
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <button onClick={handleClickOpen}>
        <FaRegEdit size={20} />
      </button>
      <Dialog open={open} fullScreen onClose={handleClose}>
        <DialogTitle textAlign={"center"}>Edit Answer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="text-[18px] font-[500] text-[black] mt-[3rem]">
              {" "}
              {props?.n?.question}
            </div>
          </DialogContentText>
          <div className="w-full grid grid-cols-2 place-items-center justify-center gap-4 mt-[3rem]">
            {props?.n?.options.length === 0 ? (
              <div className="w-fit flex justify-center col-span-2">
                <InputField
                  value={selectedOption || ""}
                  onChange={(e) => handleOptionChange(e.target.value)}
                  endIcon={<span>hours</span>}
                  maxLength={2}
                />
              </div>
            ) : (
              props?.n?.options.map((option, index) => (
                <Option
                  key={index}
                  id={option}
                  label={option}
                  selectedOption={selectedOption}
                  handleOptionChange={handleOptionChange}
                />
              ))
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleSubmit()}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
