import React, { useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";

import doctorPng from "@/../../public/assets/images/payment/payment.png";
import avatar1 from "@/../../public/assets/images/Dashboard/Avatar/avatar1.png";
import bloodPng from "@/../../public/assets/images/Dashboard/blood.png";
import temperaturePng from "@/../../public/assets/images/Dashboard/temperature.png";
import weightPng from "@/../../public/assets/images/Dashboard/weight.png";
import Spo2Png from "@/../../public/assets/images/Dashboard/Spo2.png";
import heartPng from "@/../../public/assets/images/Dashboard/heart.png";
import VitaminsList from "./VitaminsList";
import VitaminLevels from "./VitaminLevels";
import CholesterolLevelBox from "./CholesterolLevelBox";
import EyeSideNumbers from "./EyeSideNumbers";
import BloodPressureComponent from "./BloodPressureComponent";
import PulseOximetry from "./PulseOximetry";
import { HiOutlineBellAlert } from "react-icons/hi2";
import UpcomingAppointment from "./UpcomingAppointment";

function Dashboard(props) {
  const vitaminsData = [
    { name: "Vitamin D", level: 25 },
    { name: "Vitamin B12", level: 55 },
    { name: "Vitamin C", level: 70 },
    { name: "Vitamin B6", level: 15 },
    { name: "Vitamin E", level: 90 },
  ];

  // Example Usage:
  const Cholesterol_patientData = {
    totalCholesterol: 180, // Lower than the 200 threshold for total cholesterol
    ldlCholesterol: 90, // Lower than the 100 threshold for LDL cholesterol
    hdlCholesterol: 65, // Higher than the 60 threshold for HDL cholesterol in males
    gender: "male", // Assuming the patient is male
  };

  // Eyeside Numbers:
  const eyeside_numbers = {
    leftEye: {
      sphere: "-2.00",
      cylinder: "-0.75",
      axis: "90",
      add: "+1.25",
    },
    rightEye: {
      sphere: "-2.00",
      cylinder: "-0.75",
      axis: "90",
      add: "+1.25",
    },
  };

  //blood_pressure_sample
  const blood_pressure_sample = {
    sbp: 120,
    pulse: 40,
    dbp: 80,
  };

  //PulseOximetry
  const pulseOximetry_sample = {
    resting: 98,
    activity: 96,
    image: Spo2Png,
  };

  const NavNotification = () => {
    props.navigate("/notifications");
  };

  return (
    <>
      {/* header */}
      <HeaderWrapper
        left_nav={true}
        notifications={true}
        title="Dashboard"
        className="text-[13.9px] font-[400]"
      />

      {/* main div */}
      <div className="flex justify-center items-center w-full h-full ">
        <div className="flex flex-col items-center py-3 w-full h-full gap-[1rem] mb-[3rem] ">
          {/* button */}

          {/* doctor tab */}
          <div className="w-full px-3">
            <UpcomingAppointment />
          </div>

          {/* profile */}
          <div className="w-full px-3  flex flex-col items-center">
            <div className="w-full flex justify-between gap-2 mt-[1rem]">
              <div className="flex-grow h-[98px] min-w-[106px] max-w-[1/3] bg-softBlue rounded-[3px] flex flex-col justify-center items-center shadow-[0_3px_10px_rgba(0,0,0,0.2)]">
                <img src={weightPng} className="w-[25px] h-[25px]" alt="" />
                <h1 className="font-[600] text-[16px]">70(kg)</h1>
                <span className="font-[500] text-[14px]">Weight</span>
              </div>
              <div className="flex-grow h-[98px] min-w-[106px] max-w-[1/3] bg-peach rounded-[3px] flex flex-col justify-center items-center shadow-[0_3px_10px_rgba(0,0,0,0.2)]">
                <img src={temperaturePng} className="w-[25px] h-[25px]" alt="" />
                <h1 className="font-[600] text-[16px]">98.6(F)</h1>
                <span className="font-[500] text-[14px]">Temperature</span>
              </div>
              <div className="flex-grow h-[98px] min-w-[106px] max-w-[1/3] bg-ivory rounded-[3px] flex flex-col justify-center items-center shadow-[0_3px_10px_rgba(0,0,0,0.2)]">
                <img src={bloodPng} className="w-[25px] h-[25px]" alt="" />
                <h1 className="font-[600] text-[16px]">B+</h1>
                <span className="font-[500] text-[14px]">Blood Group</span>
              </div>
            </div>
          </div>

          {/* Pulse oximetry */}
          <PulseOximetry patientData={pulseOximetry_sample} />

          {/* blood pressure */}
          <BloodPressureComponent patientData={blood_pressure_sample} />

          {/* Eye side numbers */}
          <EyeSideNumbers patientData={eyeside_numbers} />

          {/* Cholesterol Levels */}

          <CholesterolLevelBox patientData={Cholesterol_patientData} />

          {/* Fast-soluble Vitamins*/}
          <VitaminLevels vitaminsData={vitaminsData} />

          {/* Water-Soluble Vitamins */}
          <VitaminsList />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
