let userID;
let pwd;

export const getAvatar = (index) => {
  const items = [
    'lorelei',
    'micah',
    'avataaars',
    'personas',
    'open-peeps',
    'notionists',
    'miniavs',
    'adventurer',
    'big-ears',
    'big-smile',
  ];
  return items[index >= items.length ? 0 : index];
};

export const getCurrentUser = () => {
  return { userID, pwd, avatar: getAvatar(0) };
};

export const setCurrentUser = ({ _userID, _pwd }) => {
  userID = _userID;
  pwd = _pwd;
};

export const getRandomString = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export async function downloadFile({ url }) {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  });

  const fileName = getRandomString(10) + '.pdf';
  const blob = await res.blob();

  const newUrl = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = newUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}


export const mimeToExtension = (mimeType) => {
  const mimeMap = {
    // Text formats
    'text/plain': 'txt',
    'text/html': 'html',
    'text/css': 'css',
    'text/javascript': 'js',
    'application/json': 'json',

    // Image formats
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',

    // Video formats
    'video/mp4': 'mp4',
    'video/mpeg': 'mpeg',
    'video/ogg': 'ogv',
    'video/webm': 'webm',

    // Audio formats
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',
    'audio/wav': 'wav',
    'audio/webm': 'webm',

    // Application formats
    'application/zip': 'zip',
    'application/x-zip-compressed': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',

    // Archive formats
    'application/x-tar': 'tar',
    'application/x-7z-compressed': '7z',

    // Binary formats
    'application/octet-stream': 'bin',

    // Other formats
    'application/xml': 'xml',
    'application/xhtml+xml': 'xhtml',
    'application/epub+zip': 'epub',
    'application/ogg': 'ogx',
    'application/x-sh': 'sh',
    'application/x-csh': 'csh',
    'application/x-python-code': 'py',
    'application/x-java-archive': 'jar',

    // Font formats
    'font/ttf': 'ttf',
    'font/otf': 'otf',
    'font/woff': 'woff',
    'font/woff2': 'woff2',

    // Default fallback
    'unknown': 'unknown'
  };

  return mimeMap[mimeType] || 'unknown';
};


export const FormatTime = (timestamp) => {
  const date = new Date(Number(timestamp));
  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};


