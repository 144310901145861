import React, { useState, useEffect } from 'react';
import { Sheet } from 'react-modal-sheet';
import { FaChevronDown } from 'react-icons/fa';
import { FormLabel, TextField, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';

export default function CheckBoxGroupSheet({ field, label, options }) {
    const [isOpen, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Update selected options based on the initial field value
    useEffect(() => {
        if (field.value) {
            const initialSelections = options.filter(option =>
                field.value.includes(option.value)
            );
            setSelectedOptions(initialSelections);
        }
    }, [field.value, options]);

    const handleSelect = (option) => {
        const isSelected = selectedOptions.some(selected => selected.value === option.value);
        let newSelectedOptions;

        if (isSelected) {
            newSelectedOptions = selectedOptions.filter(selected => selected.value !== option.value);
        } else {
            newSelectedOptions = [...selectedOptions, option];
        }

        setSelectedOptions(newSelectedOptions);
        field.onChange(newSelectedOptions.map(option => option.value));
    };

    const selectedLabels = selectedOptions.map(option => option.label).join(', ');

    const textFieldStyles = {
        "& .MuiInputBase-root": {
            paddingRight: "1.5rem",
            background: "#f8f9fa",
            borderRadius: "4px",
            "& input": {
                fontSize: "15px",
                fontWeight: "400",
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ced4da",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#000000",
        },
        marginBottom: "8px",
    };

    return (
        <>
            <FormLabel
                sx={{
                    marginBottom: "3px",
                    color: "#010101",
                    fontSize: "14px",
                    fontWeight: "800",
                    display: "block",
                }}
            >
                {label}
            </FormLabel>

            <TextField
                variant="outlined"
                value={selectedLabels}
                onClick={() => setOpen(true)}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <FaChevronDown />
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
                sx={textFieldStyles}
            />

            <Sheet snapPoints={[400, 300, 0]} isOpen={isOpen} onClose={() => setOpen(false)}>
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="flex flex-col p-4">
                            {options.map(option => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedOptions.some(selected => selected.value === option.value)}
                                            onChange={() => handleSelect(option)}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => setOpen(false)} />
            </Sheet>
        </>
    );
}
