import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import InputField from "@medoczen/core/InputField/InputField";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import ForgotPassPng from "@/../../public/assets/images/Forgot password.png";
import { GoChevronLeft } from "react-icons/go";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

import { forgotPassword } from "./store/forgotPasswordSlice";

function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  const form = watch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const selectUser = useSelector(
    (state) => state.forgotPassword.forgotPasswordSlice,
  );
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    // Assuming `selectUser` might also contain info about successful operations like code delivery
    if (selectUser?.success) {
      const { $metadata } = selectUser.success;
      setLoading(false);

      // Check if the HTTP status code is 200 and the user is not confirmed
      if ($metadata.httpStatusCode === 200) {
        navigate("/verification", {
          state: {
            module: "reset_password",
            email: selectUser.success.CodeDeliveryDetails.Destination,
          },
        });
        setSnackbarOpen(true);
      }
    } else if (selectUser?.error) {
      setLoading(false);

      if (selectUser?.error?.name === "NotAuthorizedException") {
        setSnackbarMessage("Something went wrong");
        setSnackbarOpen(true);
      } else if (selectUser.error.name === "LimitExceededException") {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`,
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      forgotPassword({
        email: form?.email,
      }),
    );
  };

  return (
    <div className="flex flex-col items-center px-8 py-3 w-full h-screen">
      {/* Loading animation */}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <CircularProgress />
        </div>
      )}

      <div className="w-full flex justify-center">
        <img
          className="w-[300px]"
          src={ForgotPassPng}
          alt="Forgot password"
          loading="lazy"
        />
      </div>

      <div className="flex flex-col justify-start mt-5 w-full">
        <div className="mb-5">
          <h1 className="font-[600] mb-2 text-[24px]">Forgot Password</h1>
          <p className="font-[400] text-[14px] opacity-90">
            Do not worry! It happens. Please enter the email to which we will
            send the OTP.
          </p>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <InputField label="Email" placeholder="abc@***.com" {...field} />
            )}
          />

          <div className="mt-[2rem] mb-[1rem] w-full flex justify-center">
            <PrimaryButton
              active
              name="Continue"
              className="w-full h-[55px]"
              action={onSubmit}
            />
          </div>
        </div>
      </div>
      <Link
        to="/"
        className="absolute top-[2rem] start-[2rem] w-[40px] h-[40px] rounded-full border border-[#00000056] flex items-center justify-center"
      >
        <GoChevronLeft size={26} />
      </Link>
    </div>
  );
}

export default ForgetPassword;
