import React, { useEffect, useState } from "react";
import avatar1 from "@/../../public/assets/images/Doctors/avatars/1.png";
import { Link, useParams } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import dayjs from "dayjs";

import { MdOutlineCalendarMonth, MdOutlineMessage } from "react-icons/md";
import BasicDateCalendar from "@medoczen/core/DatePicker/DateCalender";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorProfile,
  getDoctorSchedule,
} from "./store/bookAppointmentSlice";
import { Box, IconButton, Skeleton } from "@mui/material";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";
import { HiHome } from "react-icons/hi2";
import { IoChatbubbleEllipsesOutline, IoHomeOutline, IoVideocam } from "react-icons/io5";
import { BiSolidCalendar, BiSolidMessageRoundedDetail } from "react-icons/bi";
import { CiVideoOn } from "react-icons/ci";
import { GiTwoCoins } from "react-icons/gi";
import TimeButtonGrid from "./TimeButtonGrid";
import TimeButtonGridTab from "./TimeButtonGridTab";

function BookAppointment(props) {
  const [loading, setLoading] = useState(true);
  const [ScheduleLoading, setScheduleLoading] = useState(false);
  const [profile, setProfile] = useState();
  const [appointmentType, setAppointmentType] = useState()
  const [schedules, setSchedules] = useState();
  const { doctorId } = useParams();
  const dispatch = useDispatch();
  const selectData = useSelector(
    (state) => state.bookappointment.bookappointmentSlice,
  );

  useEffect(() => {
    setProfile(selectData.getProfile_success);
  }, [selectData]);

  useEffect(() => {
    setSchedules(selectData?.getDoctorSchedule_success);

  }, [selectData]);

  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));

  const handleDateChange = (newDate) => {
    const localISODate = dayjs(newDate).format("YYYY-MM-DD");
    setCurrentDate(localISODate);
  };

  useEffect(() => {
    getDoctorinfo();
  }, []);

  useEffect(() => {
    // Refresh the doctor's profile to retrieve metadata when the date changes.

    getSchedules();

  }, [currentDate]);

  function getDoctorinfo() {
    dispatch(getDoctorProfile(doctorId)).then(() => {
      // setLoading(false);
    });
  }

  function getSchedules() {
    setScheduleLoading(true);
    dispatch(getDoctorSchedule({ doctorId, date: currentDate })).then(() => {
      setLoading(false);
      setScheduleLoading(false);
    });
  }



  const ButtonClick = (e) => {
    e.preventDefault();
    const state = { profile: profile, schedules: schedules, appointmentType: appointmentType };
    sessionStorage.setItem("payment_state", JSON.stringify(state));
    props.navigate("/payment");
  };



  //countryCode: The country code to fetch the flag for
  const CountryFlag = ({ countryCode }) => {
    // Fetch the flag source based on the country code
    const flagSrc = GetFlagByCountryCode(countryCode);

    // If no flag source is found, return null or a placeholder
    if (!flagSrc) return null; // or some placeholder

    // Render the flag image with appropriate styling
    return (
      <img
        src={flagSrc}
        className="absolute top-[5px] left-[5px] w-[15px] h-[10px]"
      />
    );
  };

  function toLowercaseHyphenateAndLabel(text) {
    return (
      text
        ?.toLowerCase() // Convert all characters to lowercase
        ?.replace(/\s+/g, "-") + // Replace all spaces with hyphens
      "-label"
    ); // Append '-label' at the end
  }

  return (
    <div className="  h-full">
      <div className="inset-0  bg-primary w-full h-[100px]  sticky flex flex-col items-center justify-center z-[99] ">
        <div className="grid grid-cols-[auto_1fr_auto] mt-[-50px] w-full max-w-4xl px-4 items-center">
          {/* Left column for back navigation or sidebar toggle */}
          <div className="flex justify-start">
            <Link
              to={`/${props.path || `doctor-profile/${doctorId}`}`}
              replace={true}
              className="w-[30px] h-[30px] rounded-full border border-[#ffffff56] flex items-center justify-center"
            >
              <GoChevronLeft color="white" size={26} />
            </Link>
          </div>

          {/* Center column for title */}
          <div className="flex justify-center">
            <h1 className="font-[600] text-lg text-white">Book Appointment</h1>
          </div>

          {/* Right column for children and optional notification icon */}
          <div className="flex justify-end min-w-[1rem]"></div>
        </div>

        <div className="absolute top-[3.5rem]">
          {loading ? (
            <Box className="relative   px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <Skeleton variant="rectangular" width={100} height={60} />
              </div>

              <Box className="flex flex-col justify-around gap-3 w-full ml-[8px]">
                <Skeleton variant="body1" width={130} height={10} />

                <Skeleton variant="body1" width={130} height={10} />
                <Skeleton variant="body1" width={130} height={10} />
              </Box>
              <Skeleton variant="rectangular" width={100} height={60} />
            </Box>
          ) : (
            <Box className="relative   px-[3px] py-[3px] rounded-[6px] bg-[#FAFAFA] flex justify-between items-center shadow-[0px_4px_9px_2px_#cbd5e0] w-full">
              <div className="relative flex flex-col w-fit">
                <img
                  src={profile?.selfie}
                  className="w-[130px] rounded-[6px]"
                  alt=""
                />
                <CountryFlag countryCode={profile?.country_code ?? "US"} />
              </div>

              <Box className="flex flex-col justify-center w-full ml-[8px]">
                <span className="text-[16px] font-[600]">
                  {profile?.full_name}
                </span>
                <span
                  className={`text-[11px] font-[700] rounded-[3px] text-white text-left ${profile?.specialties[0]?.name && toLowercaseHyphenateAndLabel(profile?.specialties[0]?.name)}   py-[2px] px-[4px] inline-block w-fit`}
                >
                  {profile?.specialties[0]?.name}
                </span>

                <span className="text-[12px] font-[500] lowercase">
                  {profile?.schedule?.open_time?.hour ? <>  {profile?.schedule?.open_time?.hour}{profile?.schedule?.open_time?.period} -{profile?.schedule?.close_time?.hour}{profile?.schedule?.open_time?.period}</> : "not available"}
                </span>
              </Box>
              <Box className="flex flex-col justify-center text-[#616161]  mr-[20px]">
                {profile?.schedule?.in_home && <HiHome size={15} className="my-[1px]" />}
                {profile?.schedule?.video_call && <IoVideocam size={15} className="my-[1px]" />}
                {profile?.schedule?.chat && <BiSolidMessageRoundedDetail size={15} className="my-[1px]" />}
              </Box>
              <Box className="text-[#616161] flex flex-col items-center">

                <IconButton className=" ps-[2px]">
                  <GiTwoCoins size={30} />
                </IconButton>
                <span className="font-[500] text-[12px] text-black mt-[1px]">
                  £{schedules?.rate || ""} /hr
                </span>
              </Box>
            </Box>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full justify-center mt-[4rem] ">
        <div className="flex justify-center">
          {loading ? (
            <Skeleton
              variant="rounded"
              sx={{ width: "90%", marginBottom: "10px" }}
              height={300}
            />
          ) : (
            <BasicDateCalendar
              date={currentDate}
              onDateChange={handleDateChange}
            />
          )}
        </div>
        <h1 className="text-[18px] ps-[1.5rem] font-semibold ">Appointment Type</h1>
        <div className="flex  justify-around items-center px-3 py-3 w-full  mt-[0rem]">
          <button disabled={!schedules?.in_home} onClick={() => setAppointmentType("in-home")} className={` text-[14px] font-[500] w-[85px] h-[60px] border-2  rounded-[10px] flex flex-col justify-center items-center ${appointmentType === "in-home" ? "border-primary bg-primary text-white " : "border-[#0000005a] "} ${!schedules?.in_home ? " bg-gray-300  text-gray-400 border-0" : ""}`}>
            <HiHome size={25} />
            <span>In-home</span>
          </button>
          <button disabled={!schedules?.video_call} onClick={() => setAppointmentType("video")} className={`text-[14px] font-[500] w-[85px] h-[60px] border-2  rounded-[10px] flex flex-col justify-center items-center ${appointmentType === "video" ? "border-primary bg-primary text-white " : "border-[#0000005a] "} ${!schedules?.video_call ? " bg-gray-300  text-gray-400 border-0" : ""} `}>
            <IoVideocam size={25} />
            <span>Video</span>
          </button>
          <button disabled={!schedules?.chat} onClick={() => setAppointmentType("chat")} className={`text-[14px] font-[500] w-[85px] h-[60px] border-2  rounded-[10px] flex flex-col justify-center items-center ${appointmentType === "chat" ? "border-primary bg-primary text-white " : "border-[#0000005a] "}  ${!schedules?.chat ? " bg-gray-300  text-gray-400 border-0" : ""}`}>
            <BiSolidMessageRoundedDetail size={25} />
            <span>Chat</span>
          </button>
        </div>
        <div className="w-full px-[1.5rem] pb-[2rem] ">
          <h1 className="text-[18px] font-semibold mb-3">Time Available</h1>
          {loading ? (
            <Skeleton variant="rounded" sx={{ width: "100%" }} height={300} />
          ) : (
            <>
              {ScheduleLoading ? (
                <div className="grid grid-cols-3 gap-[1rem] ">
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "102px", height: "32px" }}
                    height={30}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "102px", height: "32px" }}
                    height={30}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "102px", height: "32px" }}
                    height={30}
                  />
                </div>
              ) : (
                <>
                  {schedules?.length <= 0 ? (
                    "not available"
                  ) : (
                    <>
                      {/* <TimeButtonGrid
                        openTime={schedules?.open_time}
                        breakStartTime={schedules?.break_start_time}
                        breakEndTime={schedules?.break_end_time}
                        closeTime={schedules?.close_time}
                        schedules={schedules}
                        setSchedules={setSchedules}
                      /> */}
                      <TimeButtonGridTab
                        openTime={schedules?.open_time}
                        breakStartTime={schedules?.break_start_time}
                        breakEndTime={schedules?.break_end_time}
                        closeTime={schedules?.close_time}
                        schedules={schedules}
                        setSchedules={setSchedules}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="fixed bottom-0 bg-white flex w-full justify-center p-2  ">
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "90%" }} height={60} />
        ) : (
          <PrimaryButton
            name="Go For Payment"
            className="w-[244px] "
            fontSize="18px"
            active={schedules?.time !== undefined && appointmentType !== undefined}
            disabled={schedules?.time === undefined || appointmentType === undefined}
            action={ButtonClick}
          />
        )}
      </div>
    </div>
  );
}

export default BookAppointment;
