import React, { useCallback } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import {
  MdOutlineMessage,
  MdHelpOutline,
  MdBook,
  MdSettingsApplications,
  MdNotifications,
  MdContactMail,
  MdPolicy,
  MdFeedback,
  MdRateReview,
} from "react-icons/md";
import SupportLinks from "./SupportLinks";

// Define sections with titles, links, paths, and icons directly included
const sections = [
  {
    title: "Help & Support",
    links: [
      {
        name: "Live Chat",
        path: "/live-chat",
        icon: <MdOutlineMessage size={24} />,
      },
      { name: "FAQ", path: "/faq", icon: <MdHelpOutline size={24} /> },
      { name: "User Guide", path: "/user-guide", icon: <MdBook size={24} /> },

      {
        name: "Troubleshooting Guide",
        path: "/troubleshooting-guide",
        icon: <MdSettingsApplications size={24} />,
      },
    ],
  },
  {
    title: "Support & About",
    links: [
      {
        name: "Contact us",
        path: "/contact-us",
        icon: <MdContactMail size={24} />,
      },
      {
        name: "Terms and Policies",
        path: "/terms-and-policies",
        icon: <MdPolicy size={24} />,
      },
    ],
  },
  {
    title: "Feedback",
    links: [
      { name: "Feedback", path: "/feedback", icon: <MdFeedback size={24} /> },
      {
        name: "Rate Our App",
        path: "/rate-app",
        icon: <MdRateReview size={24} />,
      },
    ],
  },
];

const SupportSection = ({ title, links }) => (
  <div className="w-full">
    <span className="text-[15px] font-semibold text-primary">{title}</span>
    <div className="w-full h-fit px-[1rem] py-[1rem] rounded-[6px] bg-[#96969a0e] text-[#000000a8] flex flex-col gap-[1.2rem] mx-auto">
      {links.map((link) => (
        <SupportLinks
          key={link.name}
          name={link.name}
          icon={link.icon}
          path={link.path}
        />
      ))}
    </div>
  </div>
);

function HelpSupport(props) {
  return (
    <>
      <HeaderWrapper title="Help & Support" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        {sections.map((section) => (
          <SupportSection
            key={section.title}
            title={section.title}
            links={section.links}
          />
        ))}
      </div>
    </>
  );
}

export default HelpSupport;
