import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { FaClipboardList } from "react-icons/fa";
import { MdEditNote } from "react-icons/md";
import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { Skeleton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestions, postAnswer } from "../store/healthAssessmentSlice";
import InputField from "@medoczen/core/InputField/InputField";

const Option = ({ id, label, selectedOption, handleOptionChange }) => {
  return (
    <label
      htmlFor={id}
      className={`flex justify-between gap-2 items-center px-4 w-40 h-12 border-2 rounded-lg ${selectedOption === id ? "border-primary" : "border-gray-300"}`}
      onClick={() => handleOptionChange(id)}
    >
      <input
        type="radio"
        id={id}
        name="options"
        checked={selectedOption === id}
        onChange={() => handleOptionChange(id)}
        className="hidden"
      />
      <span className=" font-semibold">{label}</span>
      <span
        className={`w-fit h-fit text-sm text-white rounded-full p-1 ${selectedOption === id ? "bg-primary" : "border border-gray-300"}`}
      >
        <FaCheck />
      </span>
    </label>
  );
};

function Question({ question, selectedOption, handleOptionChange }) {
  return (
    <div className="w-full flex flex-col justify-center items-center gap-8">
      <h1 className="font-semibold text-lg w-11/12 text-center">
        {question?.question}
      </h1>
      <div className="w-full grid grid-cols-2 place-items-center justify-center gap-4">
        {question.options.length === 0 ? (
          <div className="w-fit flex justify-center col-span-2">
            <InputField
              value={selectedOption || ""}
              onChange={(e) => handleOptionChange(e.target.value)}
              endIcon={<span>hours</span>}
              maxLength={2}
            />
          </div>
        ) : (
          question.options.map((option, index) => (
            <Option
              key={index}
              id={option}
              label={option}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          ))
        )}
      </div>
    </div>
  );
}

function Skeletons() {
  return (
    <div className="w-full flex flex-col items-center mt-8">
      <div className="w-20 h-20   overflow-hidden mb-4">
        <Skeleton variant="rounded" width={70} height={70} />
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-8">
        <div className="flex flex-col justify-center items-center gap-3">
          <Skeleton variant="rounded" width={300} height={20} />
          <Skeleton variant="rounded" width={150} height={20} />
        </div>
        <div className="w-full flex items-center justify-center gap-4">
          <Skeleton variant="rounded" width={160} height={50} />
          <Skeleton variant="rounded" width={160} height={50} />
        </div>
      </div>
    </div>
  );
}

function Questionnaire(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { medical_category, question_category, progress } = location.state;

  const [selectedOption, setSelectedOption] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const selectQuestions = useSelector(
    (state) => state.healthAssessment.healthAssessmentSlice,
  );

  useEffect(() => {
    if (selectQuestions) {
      setQuestions(selectQuestions.healthAssessment_success);
    }
  }, [selectQuestions]);

  useEffect(() => {
    if (progress === 100) {
      setQuestions([]);
      setLoading(false);
    } else {
      dispatch(getAllQuestions({ question_category, medical_category })).then(
        () => {
          setLoading(false);
        },
      );
    }
  }, []);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setSelectedOption(null);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleReturn = () => {
    setSelectedOption(null);
    props.navigate(`/health-assessment`);
  };

  const handleSubmit = () => {
    setLoading(true);
    const currentQuestion = questions[currentQuestionIndex];
    const answer = {
      question_id: currentQuestion.id,
      answer: selectedOption,
    };
    dispatch(postAnswer(answer)).then(() => {
      handleNextQuestion();
      setLoading(false);
    });
  };

  return (
    <>
      <HeaderWrapper path="health-assessment" title="Questionnaire">
        <div className="mt-[5px]">
          {" "}
          <Link
            to={"/health-assessment/answers"}
            state={{ medical_category, question_category }}
          >
            <MdEditNote color="white" size={30} />
          </Link>
        </div>
      </HeaderWrapper>

      {loading ? (
        <Skeletons />
      ) : (
        <div className="w-full flex flex-col items-center mt-8">
          <div className="w-20 h-20   overflow-hidden mb-4">
            <FaClipboardList size={50} />
          </div>
          {currentQuestionIndex < questions.length ? (
            <Question
              question={questions[currentQuestionIndex]}
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          ) : (
            <div className="w-full flex flex-col items-center justify-center">
              <p className="text-center">
                You have already answered the questions. Return to Health
                Assessment.
              </p>
              <PrimaryButton active action={handleReturn} name="Return" />
            </div>
          )}
          {currentQuestionIndex < questions.length && (
            <div className="mt-[2rem] ">
              <PrimaryButton active action={handleSubmit} name="Next" />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Questionnaire;
