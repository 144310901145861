import React from "react";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { IoMdMicOff } from "react-icons/io";
import { IoCameraReverseOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";

function CallControlDiv() {
  return (
    <div className="fixed w-full min-h-[195px] bottom-0 bg-[black] rounded-t-[26px] backdrop-filter backdrop-blur-md bg-opacity-30 flex items-center justify-around px-[1rem]">
      <div className="flex flex-col items-center gap-2">
        <div className="w-[68px] h-[68px] flex justify-center items-center bg-[#FFFFFF33] rounded-full">
          <BiMessageRoundedDetail size={30} color="white" />
        </div>
        <span className="text-white">Chat</span>
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="w-[68px] h-[68px] flex justify-center items-center bg-[#FFFFFF33] rounded-full">
          <IoMdMicOff size={30} color="white" />
        </div>
        <span className="text-white">Mute</span>
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="w-[68px] h-[68px] flex justify-center items-center bg-[#FFFFFF33] rounded-full">
          <IoCameraReverseOutline size={30} color="white" />
        </div>
        <span className="text-white">Flip</span>
      </div>
      <Link to="/chat" className="flex flex-col items-center gap-2">
        <div className="w-[68px] h-[68px] flex justify-center items-center bg-[#F83D39] rounded-full">
          <RxCross1 size={30} color="white" />
        </div>
        <span className="text-white">End</span>
      </Link>

      <div className=" absolute top-1 w-[50px] h-[5px] bg-[#d8d8d8a0] rounded">
        {" "}
      </div>
    </div>
  );
}

export default CallControlDiv;
