import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddFeedback, getFeedback } from "../store/helpSupportSlice";
import { Controller, useForm } from "react-hook-form";
import { TextField, Button, FormControl, InputLabel, FormHelperText } from "@mui/material";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import InputField from "@medoczen/core/InputField/InputField";
import { showToast } from "pages/Appointments/BookAppointment/store/bookAppointmentSlice";
import Skeletons from "./Skeletons";

function Feedbacks() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Fetch the feedback data
  const fetchData = () => {
    dispatch(getFeedback()).then((res) => {
      reset(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  // Handle form submission
  const onSubmit = (data) => {
    dispatch(AddFeedback(data)).then(() => {
      dispatch(showToast({ message: "Feedback submitted successfully", type: "success" }));
      fetchData();
    });
  };

  return (
    <>
      <HeaderWrapper title="Feedback" path="help-support"></HeaderWrapper>

      {/* Feedback description */}
      {loading ? (
        <Skeletons />
      ) : (
        <>
          <div className="w-full text-center mt-4">
            <h2 className="text-lg font-semibold">Add Your Feedback</h2>
            <p className="text-sm text-gray-500 mt-2">
              We value your feedback! Please fill in the form below to let us know your thoughts and
              suggestions.
            </p>
          </div>

          {/* Feedback form */}
          <div className="flex flex-col items-center w-full px-4 mt-4">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-lg">
              {/* Title field */}
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required" }}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard" error={!!errors.title} className="mb-4">
                    <InputField
                      {...field}
                      id="title-input"
                      required
                      label="Title"
                      variant="outlined"
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                      fullWidth
                    />
                  </FormControl>
                )}
              />

              {/* Reviews field */}
              <Controller
                name="reviews"
                control={control}
                rules={{ required: "Review is required" }}
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={!!errors.reviews}
                    className="mb-4"
                  >
                    <InputField
                      {...field}
                      id="reviews-input"
                      label="Reviews"
                      required
                      multiline
                      rows={5}
                      variant="outlined"
                      error={!!errors.reviews}
                      helperText={errors?.reviews?.message}
                      fullWidth
                    />
                  </FormControl>
                )}
              />

              {/* Submit button */}
              <div className="w-full flex justify-center mt-6">
                <Button type="submit" variant="contained" color="primary">
                  Submit Feedback
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default Feedbacks;
