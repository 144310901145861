import React from "react";
import Rating from "@mui/material/Rating";
import { Box, Divider, Fab, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { getUser } from "@medoczen/core/MDZsessions";


const ReviewComponent = ({ reviews, doctor_id, appoinment_id }) => {
  const navigate = useNavigate()

  const TimeDifference = (Time) => {
    // Convert the given time to a Date object
    const givenDate = new Date(Time);

    // Get the current time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDiff = currentDate - givenDate;

    // Convert the time difference from milliseconds to seconds
    const secondsDiff = Math.floor(timeDiff / 1000);

    // Calculate the hours, minutes, and seconds
    const hours = Math.floor(secondsDiff / 3600);
    const minutes = Math.floor((secondsDiff % 3600) / 60);
    const seconds = secondsDiff % 60;

    // Calculate the days, months, and years
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    return (
      <div>
        {years > 0 && <>{years} {years === 1 ? 'year' : 'years'} ago </>}
        {months > 0 && <>{months} {months === 1 ? 'month' : 'months'} ago </>}
        {days > 0 && <>{days} {days === 1 ? 'day' : 'days'} ago </>}
        {hours <= 0 && minutes <= 0 && seconds <= 0 && <>just now </>}
        {hours <= 0 && minutes <= 0 && seconds > 0 && <>{seconds} seconds ago</>}
        {hours <= 0 && minutes > 0 && <>{minutes} minutes ago</>}
        {hours > 0 && hours <= 24 && <>{hours} hours ago</>}

      </div>
    );
  };


  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="w-full flex flex-col justify-start items-center mb-3">
          {reviews.map((review) => (
            <div key={review.id} className="mb-[2rem] w-full ">
              <div className="flex flex-col w-full  ">
                <div className="flex flex-1">
                  <span className="flex-1 text-[13px] font-[500] text-gray-500">{review?.users?.full_name}</span>
                  <span className="text-[12px] font-[500] flex  items-center text-gray-500">
                    <span className="w-[5px] h-[5px] rounded-full bg-[#0000009a] mx-1"></span> {review.updated_at ? TimeDifference(review.updated_at) : TimeDifference(review.created_at)}</span>
                </div>
                <div className="">
                  <div className="">
                    <Typography fontSize={14} component="legend">Communication skills </Typography>
                    <Rating size="small" name="read-only" value={review?.communication_skills} readOnly />
                  </div>
                  <div className="">
                    <Typography fontSize={14} component="legend">Professionalism and behavior</Typography>
                    <Rating size="small" name="read-only" value={review?.professionalism_and_behavior} readOnly />
                  </div>
                  <div className="">
                    <Typography fontSize={14} component="legend">Outcome and satisfaction</Typography>
                    <Rating size="small" name="read-only" value={review?.outcome_and_satisfaction} readOnly />
                  </div>
                  <div className="">
                    <Typography fontSize={14} component="legend">Digital experience</Typography>
                    <Rating size="small" name="read-only" value={review?.digital_experience} readOnly />
                  </div>
                  {/* <hr className="border-[#00000025]  w-full my-[10px]" /> */}
                  <div className="flex flex-1">

                    <span className="flex-1 text-[14px] font-[500] text-gray-500">{review?.review}</span>

                  </div>
                  <hr className="border-[#00000043]  w-full mt-[13px]" />
                </div>


              </div>

            </div>
          ))}
        </div>

        {!reviews.find((review) => (review.submitted_by_id === Number(getUser().user_id))) && <div className="fixed bottom-[2rem] right-5">
          <Fab onClick={() => navigate('/add-review', { state: { doctor_id: doctor_id, appointment_id: appoinment_id } })} color="" aria-label="edit">
            <EditIcon />
          </Fab>
        </div>}
      </Box>


    </>
  );
};

export default ReviewComponent;
