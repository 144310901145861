import React, { useState, useEffect, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { Sheet } from 'react-modal-sheet';
import { FaChevronDown } from "react-icons/fa";
import { GetFlagByCountryCode } from "@medoczen/core/GetFlagByCountryCode/GetFlagByCountryCode";

const textFieldStyles = {
  "& .MuiInputBase-root": {
    background: "#f8f9fa",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    paddingLeft: "20px",
    "& input": {
      fontSize: "16px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ced4da",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000",
  },
};

const searchFieldStyles = {
  "& .MuiInputBase-root": {
    background: "#ffffff",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    paddingLeft: "20px",
    "& input": {
      fontSize: "16px",
    },
  },
};

function CountryFlag({ countryCode }) {
  const flagSrc = GetFlagByCountryCode(countryCode);
  return flagSrc ? <img src={flagSrc} className="w-[25px] mr-2" alt="flag" /> : null;
}

function CountriesDropdown(props) {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const selectedLabel = props.field.value?.name;

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`/countries/`);
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options;
    return options.filter((country) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, options]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelect = (country) => {
    props.setValue("country_code", country.country_code, {
      shouldValidate: true,
    });
    props.field.onChange(country);
    closeDropdown();
  };

  const openDropdown = () => {
    setSearchQuery("");
    setOpen(true);

  };

  const closeDropdown = () => {
    setOpen(false);
    setSearchQuery("");
  };

  return (
    <>
      {props?.label && (
        <FormLabel
          htmlFor={props?.label}
          sx={{
            marginBottom: "3px",
            color: "#010101",
            fontSize: "14px",
            fontWeight: "800",
            display: "block",
          }}
        >
          {props?.label}
        </FormLabel>
      )}
      <TextField
        variant="outlined"
        value={selectedLabel || ""}
        onClick={openDropdown}
        fullWidth
        InputProps={{
          startAdornment: (
            <CountryFlag
              countryCode={props?.field?.value?.country_code ?? "US"}
            />
          ),
          endAdornment: <FaChevronDown />,
          readOnly: true,
        }}
        sx={textFieldStyles}
      />

      <Sheet isOpen={isOpen} onClose={closeDropdown}>
        <Sheet.Container>
          <Sheet.Header >
            <div className="p-4 w-full">
              <button onClick={closeDropdown} className="text-blue-500 float-start">Cancel</button>
            </div>
            <div className="w-full p-4">
              <TextField
                placeholder="Search Country"
                variant="outlined"
                fullWidth
                autoFocus
                autoCorrect="off"
                onChange={handleInputChange}
                sx={searchFieldStyles}
              />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              <div className="p-4">
                {isLoading ? (
                  <div className="flex justify-center my-4">
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <div className="mt-4">
                    {filteredOptions.map((country) => (
                      <div
                        key={country.country_code}
                        className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSelect(country)}
                      >
                        <CountryFlag countryCode={country.country_code} />
                        <span>{country.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closeDropdown} />
      </Sheet>
    </>
  );
}

export default CountriesDropdown;
