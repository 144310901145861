import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import React, { useEffect, useState } from "react";
import { TbShoppingBag } from "react-icons/tb";
import { CiCirclePlus } from "react-icons/ci";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { getPrescribedMedicines } from "../store/medicineSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#005eb8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// Dummy data for medicines
const rows = [
  { id: 1, medicine: "Medicine 1", dosage: "10mg" },
  { id: 2, medicine: "Medicine 2", dosage: "20mg" },
  { id: 3, medicine: "Medicine 3", dosage: "30mg" },
  { id: 4, medicine: "Medicine 4", dosage: "15mg" },
  { id: 5, medicine: "Medicine 5", dosage: "25mg" },
  // Add more medicines as needed
];

function MedicinesList(props) {
  const [data, setData] = useState([])
  const dispatch = useDispatch();

  const handleClick = (row) => {
    props.navigate("/add-medicine-reminder");
  };








  return (
    <>
      <HeaderWrapper title="Medicines" path="settings">
        <TbShoppingBag color="white" size={24} />
      </HeaderWrapper>
      <div className="flex items-center w-full px-2 mt-[1rem] ">
        <SearchBar placeholder="Search Here" />
      </div>

      <div className="px-2 mt-[1rem]">
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Medicine</StyledTableCell>
                <StyledTableCell align="right">Dosage</StyledTableCell>
                <StyledTableCell align="right">Cart</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.medicine}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.dosage}</StyledTableCell>
                  <StyledTableCell align="right">
                    <button onClick={() => handleClick(row)}>
                      <CiCirclePlus size={24} />
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default MedicinesList;
