import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authServiceInstance from "@services/jwtService/index";

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    username: "",
    code: "",
    password: "",
  },
  reducers: {
    resetPasswordSuccess: (state, action) => {
      // Directly mutate the state properties
      state.success = action.payload;
      state.error = null; // Reset error state on success
    },
    resetPasswordFailure: (state, action) => {
      // Directly mutate the state properties
      state.error = action.payload;
      state.success = null; // Reset success state on failure
    },
  },
});

export const { resetPasswordSuccess, resetPasswordFailure } =
  resetPasswordSlice.actions;

// Asynchronous thunk action for logging in
export const reset_password = (credentials) => async (dispatch) => {
  try {
    const data_resp = await authServiceInstance.resetPassword({
      ...credentials,
    });
    dispatch(resetPasswordSuccess(data_resp));
  } catch (error) {
    dispatch(resetPasswordFailure(error));
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = resetPasswordSlice.name;

export default resetPasswordSlice.reducer;
