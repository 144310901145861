import React, { useEffect, useState } from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import SearchBar from "@medoczen/core/SearchBar/SearchBar";
import DoctorTab from "./DoctorTab";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDoctors } from "./store/DoctorsSlice";
import { Chip, Skeleton } from "@mui/material";
import DocotorsFilterDialog from "./DoctorsFilter";

function DoctorsList(props) {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [doctors, setDoctors] = useState([]);

  // Safely parse sessionStorage values, or default to appropriate initial states
  const getStoredValue = (key, defaultValue) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  const medical_category = location?.state?.medical_category || getStoredValue('medical_category', '');
  const [filterByCountry, setFilterByCountry] = useState(() => getStoredValue('filterByCountry', ''));
  const [filterByRate, setFilterByRate] = useState(() => sessionStorage.getItem('filterByRate') || '');
  const [filterByType, setFilterByType] = useState(() => getStoredValue('filterByType', []));

  const dispatch = useDispatch();
  const [reset, setReset] = useState(false);
  const selectDoctors = useSelector((state) => state.doctors.doctorsSlice);

  useEffect(() => {
    if (selectDoctors) {
      setDoctors(selectDoctors.doctor_success);
    }
  }, [selectDoctors]);

  useEffect(() => {
    fetchData();
    sessionStorage.setItem('medical_category', JSON.stringify(medical_category));
  }, [filterByCountry, filterByRate, filterByType]);

  useEffect(() => {
    sessionStorage.setItem('filterByCountry', JSON.stringify(filterByCountry));
  }, [filterByCountry]);


  useEffect(() => {
    sessionStorage.setItem('filterByRate', filterByRate);
  }, [filterByRate]);

  useEffect(() => {
    sessionStorage.setItem('filterByType', JSON.stringify(filterByType));
  }, [filterByType]);

  function fetchData() {
    setLoading(true);
    const filters = {
      medical_category: medical_category?.id,
      filterByRate,
      filterByCountry: filterByCountry?.country_code,
      in_home: filterByType?.includes(1),
      video_call: filterByType?.includes(2),
      chat: filterByType?.includes(3)
    };
    dispatch(getDoctors(filters))
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching doctors:", error);
        setLoading(false);
      });
  }

  function Reset() {
    setFilterByCountry('');
    setFilterByRate('');
    setFilterByType([]);
    sessionStorage.removeItem('filterByCountry');
    sessionStorage.removeItem('filterByRate');
    sessionStorage.removeItem('filterByType');
    setReset(prev => !prev);
  }

  function handleDelete(item) {
    if (item === 'rate') {
      setFilterByRate('');
      sessionStorage.removeItem('filterByRate');
    } else if (item === 'country') {
      setFilterByCountry('');
      sessionStorage.removeItem('filterByCountry');
    } else if (typeof item === 'number') {
      setFilterByType(prev => {
        const newFilterByType = prev.filter(type => type !== item);
        sessionStorage.setItem('filterByType', JSON.stringify(newFilterByType));
        return newFilterByType;
      });
    }
  }

  return (
    <>
      <HeaderWrapper path="doctor-specialties" title="Doctors list" />
      <div className="flex flex-col items-center w-full h-full bg-[#F3F3F3]">
        <div className="sticky top-[50px] py-[10px] z-[99] bg-[#F3F3F3] px-3 w-full">
          <div className="flex items-center justify-between w-full">
            <SearchBar placeholder="Search Here" />
            <DocotorsFilterDialog
              setFilterByCountry={setFilterByCountry}
              filterByCountry={filterByCountry}
              filterByRate={filterByRate}
              setFilterByType={setFilterByType}
              setFilterByRate={setFilterByRate}
              filterByType={filterByType}
            />
          </div>
          <div className="flex w-full justify-start items-center gap-3 overflow-x-auto">
            {medical_category && <Chip size="small" label={medical_category?.name} />}
            {filterByCountry && <Chip onDelete={() => handleDelete('country')} size="small" label={filterByCountry?.name} />}
            {filterByRate && <Chip onDelete={() => handleDelete('rate')} size="small" label={`£ ${filterByRate}`} />}
            {filterByType?.length > 0 && filterByType?.map((item, index) => (
              <Chip key={index} onDelete={() => handleDelete(item)} size="small" label={item === 1 ? 'In-Home' : item === 2 ? 'Video' : item === 3 ? 'Chat' : ''} />
            ))}
          </div>
        </div>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="w-full px-3">
              <Skeleton variant="rounded" sx={{ width: "100%" }} height={80} />
            </div>
          ))
        ) : (
          <DoctorTab doctors={doctors ?? []} />
        )}
      </div>
    </>
  );
}

export default DoctorsList;
