import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import { Skeleton } from "@mui/material";
import React from "react";
import { FaRegEdit } from "react-icons/fa";

function TabSkeleton() {
  return (
    <>
      {" "}
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-start">
            <div className="flex gap-[10px]">
              <Skeleton variant="circular" width={50} height={50} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="h1" width="100%" height={10} />
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width={200} height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <p className="text-[14px] font-[400] mt-2"></p>
              </div>
            </div>
            <div></div>
          </div>

          {/* Render <hr> conditionally, not for the last item */}
          <hr className="border-[#00000050] w-full my-[10px]" />
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-start">
            <div className="flex gap-[10px]">
              <Skeleton variant="circular" width={50} height={50} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="h1" width="100%" height={10} />
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width={200} height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <p className="text-[14px] font-[400] mt-2"></p>
              </div>
            </div>
            <div></div>
          </div>

          {/* Render <hr> conditionally, not for the last item */}
          <hr className="border-[#00000050] w-full my-[10px]" />
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-start">
            <div className="flex gap-[10px]">
              <Skeleton variant="circular" width={50} height={50} />
              <div className="flex flex-col gap-2">
                <Skeleton variant="h1" width="100%" height={10} />
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width={200} height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <span className="text-[12px] font-[400]">
                  <Skeleton variant="h1" width="100%" height={5} />
                </span>
                <p className="text-[14px] font-[400] mt-2"></p>
              </div>
            </div>
            <div></div>
          </div>

          {/* Render <hr> conditionally, not for the last item */}
          <hr className="border-[#00000050] w-full my-[10px]" />
        </div>

        <div className="w-full flex justify-center my-[1rem]">
          <Skeleton variant="rectangular" width="100%" height={50} />
        </div>
      </div>
    </>
  );
}

export default TabSkeleton;
