import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Card, CardContent, Typography } from "@mui/material";

import Skeleton from "@mui/material/Skeleton";


import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";

import HealthAssessmentBoxTab from "./HealthAssessmentBoxTab";
import { getHealthAssessment } from "./store/healthAssessmentSlice";

// Create an array of 10 items for the skeletons
const skeletons = Array.from({ length: 10 }, (_, index) => index + 1);

function HealthAssessment(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectUser = useSelector(
    (state) => state.healthAssessment.healthAssessmentSlice,
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (selectUser?.healthAssessment_success) {
      setLoading(false);
      setData(selectUser?.healthAssessment_success);
    } else if (selectUser?.healthAssessment_error) {
      setLoading(false);

      if (
        selectUser?.healthAssessment_error?.name === "NotAuthorizedException"
      ) {
        setSnackbarMessage("Username/Password is incorrect");
        setSnackbarOpen(true);
      } else if (
        selectUser?.healthAssessment_error?.CodeDeliveryDetails?.Destination
      ) {
        // Handle the success scenario of code being sent
        setSnackbarMessage(
          `Code sent to ${selectUser.healthAssessment_error.CodeDeliveryDetails.Destination}`,
        );
        setSnackbarOpen(true);
      } else if (
        selectUser.healthAssessment_error.name === "LimitExceededException"
      ) {
        setSnackbarMessage(
          `Request limit exceeded. Try again in 15 minute(s).`,
        );
        setSnackbarOpen(true);
      }
    }
  }, [selectUser, navigate, setSnackbarMessage, setSnackbarOpen]);

  useEffect(() => {
    setLoading(true);
    dispatch(getHealthAssessment());
  }, []);

  const appointmentBoxes = data?.map((specialist) => {

    return (
      <HealthAssessmentBoxTab
        key={specialist.id}
        id={specialist.id}
        image={specialist.icon}
        name={specialist.name}
        totalQuestions={Number(specialist.health_assessment_length)}
        answeredQuestions={Number(specialist.user_health_assessment_length)}
      />
    );
  });

  return (
    <>
      <HeaderWrapper path="dashboard" title="Health assessment" />
      <div className="flex flex-col items-center px-3 py-3 w-full h-full mb-[5rem] bg-[#F3F3F3]">
        <Card sx={{ boxShadow: 1 }}>
          {" "}
          {/* Adjust the styling as needed */}
          <CardContent>
            <Typography variant="body1" component="p">
              Health assessment is a questionnaire set focused on daily health,
              lifestyle, and past medical history across various medical
              categories.
              <Link
                to={"/health-assessment/detailed-description"}
                className="text-primary font-[500] mx-2 "
              >
                Read More
              </Link>
            </Typography>
          </CardContent>
        </Card>

        <div className="flex flex-col justify-start w-full ">
          <div className="grid grid-cols-2 gap-[1rem] mt-[1rem]">
            {/* Loading Skeleton animation */}
            {loading && (
              <>
                {skeletons.map((skeleton, i) => (
                  <div
                    key={i}
                    className="h-[123px] min-w-[170px] rounded-[14px] flex flex-col justify-center items-center shadow-[10px_10px_30px_0px_#cbd5e0]"
                  >
                    <Skeleton
                      style={{ marginBottom: 6 }}
                      variant="circular"
                      width={60}
                      height={60}
                    />
                    <Skeleton variant="rectangular" width={80} height={20} />
                  </div>
                ))}
              </>
            )}

            {appointmentBoxes}
          </div>
        </div>
      </div>
    </>
  );
}

export default HealthAssessment;
