import React from "react";
import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import confirmationPng from "@/../../public/assets/images/payment/confirmation.png";
function Confirmation(props) {
  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/profile");
  };

  return (
    <>
      <HeaderWrapper title="Confirmation" />
      <div className="flex flex-col items-center px-3 py-3 pt-[2rem] w-full h-full gap-[1rem]">
        <h1 className=" font-[600] text-[18px] ">Payment Successful! </h1>
        <span className="text-[16px] font-[500] text-[#A4A9AE] text-center">
          Your appointment has been successfully booked.
        </span>
        <img src={confirmationPng} className="mt-[2rem]" alt="" />
      </div>
    </>
  );
}

export default Confirmation;
