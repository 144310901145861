import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const reviewsSlice = createSlice({
  name: "review",
  initialState: {},
  reducers: {
    getReviewSuccess: (state, action) => {
      state.getReview_success = action.payload;
      state.getReview_error = null;
    },
    getReviewFailure: (state, action) => {
      state.getReview_error = action.payload;
      state.getReview_success = null;
    },
  },
});

export const {
  getReviewSuccess,
  getReviewFailure
} = reviewsSlice.actions;

// Asynchronous thunk action for fetching review data
export const getReviews = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`user-reviews/appointment/${params?.appointment_id}`);
    dispatch(getReviewSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getReviewFailure(errorMessage));
    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching review data
export const getAllReviews = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`user-reviews?doctor_id=${params?.doctor_id}`);
    dispatch(getReviewSuccess(response.data));
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";
    dispatch(getReviewFailure(errorMessage));
    throw errorMessage;
  }
};

export const UpdateReview = (params) => async (dispatch) => {
  try {
    const response = await axios.put(`user-reviews/${params.id}`, params);

    return (response.data)
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};


// Asynchronous thunk action for adding 
export const AddUserReview = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`user-reviews`, params);

    return (response.data)
  } catch (error) {
    const errorMessage =
      error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};




// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = reviewsSlice.name;

export default reviewsSlice.reducer;
