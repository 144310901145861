import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import HeaderWrapper from "@medoczen/core/Header/HeaderWrapper";
import { CiLock, CiClock2 } from "react-icons/ci";
import { IoIosAlarm } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineCalendarToday } from "react-icons/md";

import PrimaryButton from "@medoczen/core/PrimaryButton/PrimaryButton";
import MDatePicker from "@medoczen/core/DatePicker/DatePicker";
import { useDispatch } from "react-redux";
import {
  addSelfAddedMedicine,
  deleteSelfAddedMedicine,
  getPrescribedMedicines,
  getSelfAddedMedicines,
  updateSelfAddedMedicine,
} from "../store/medicineSlice";

// Placeholder image for medications without a specific image
import DefaultMedicinePng from "@/../../public/assets/images/medicines/tablets.png";

// Import images for specific medications if available
import NaproxenPng from "@/../../public/assets/images/medicines/tablets.png";
import IbuprofenPng from "@/../../public/assets/images/medicines/tablets.png";

// Import MUI components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Alert, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import AddMedicineReminder from "../AddMedicineReminder/AddMedicineReminder";
import { BiSolidEdit } from "react-icons/bi";
import { showToast } from "pages/Appointments/BookAppointment/store/bookAppointmentSlice";
function MedicineReminder(props) {
  const { control } = useForm();
  const dispatch = useDispatch();
  const [prescribedmedicines, setPrescribedMedicines] = useState([]);
  const [selfAddedMedicines, setSelfAddedMedicines] = useState([]);

  const [dialogState, setDialogState] = useState({
    open: false,
    edit: false,
    data: null,
  });
  // State for managing selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  function fetchSelfAddedMedicines() {
    dispatch(getSelfAddedMedicines()).then((response) => {
      if (response?.data) {
        setSelfAddedMedicines(response.data);
      }
    });
  }

  function fetchPrescribedMedicines() {
    dispatch(getPrescribedMedicines()).then((response) => {
      if (response?.data) {
        setPrescribedMedicines(response.data);
      }
    });
  }

  useEffect(() => {
    if (selectedTab === 1) {
      fetchSelfAddedMedicines();
    } else {
      fetchPrescribedMedicines();
    }
  }, [dispatch, selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const ButtonClick = (e) => {
    e.preventDefault();
    props.navigate("/add-medicine-reminder");
  };

  // Function to parse time_of_day
  const parseTimeOfDay = (timeOfDayStr) => {
    if (!timeOfDayStr) return [];
    return timeOfDayStr
      .split(/,|and/)
      .map((time) => time.trim())
      .filter((time) => time.length > 0);
  };

  // Function to get image for medication
  const medicationImages = {
    Naproxen: NaproxenPng,
    Ibuprofen: IbuprofenPng,
    // Add more mappings as needed
  };

  const getImageForMedication = (name) => {
    return medicationImages[name] || DefaultMedicinePng;
  };

  function HandleClick(drug) {
    setDialogState({ open: true, edit: true, data: drug });
  }

  const handleUpdateDrug = (data) => {
    dispatch(updateSelfAddedMedicine(data)).then(() => {
      fetchSelfAddedMedicines();
    });
  };

  const HandleRemove = (data) => {
    dispatch(deleteSelfAddedMedicine(data.id)).then(() => {
      fetchSelfAddedMedicines();
      handleDialogClose();
    });
  };

  const handleAddMedicine = (data) => {
    dispatch(addSelfAddedMedicine(data)).then(() => {
      dispatch(showToast({ message: "Drug added successfully", type: "success" }));
      fetchSelfAddedMedicines();
      // props.navigate("/medicine-reminder");
    });
  };

  const handleNewMedicine = () => {
    setDialogState({ open: true, edit: false, data: null });
  };

  const handleDialogClose = () => {
    setDialogState({ open: false, edit: false, data: null });
  };

  return (
    <>
      <HeaderWrapper left_nav title="Medicines Reminder" className="text-[13.9px] font-[400]">
        <div className="relative w-[30px] h-[40px] ">
          <div className="absolute top-[-10px] z-[100] opacity-0">
            <Controller
              name="calendar"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MDatePicker
                  label=""
                  name="calendar"
                  control={control}
                  icon={<MdOutlineCalendarToday size={24} />}
                  className="mb-2"
                />
              )}
            />
          </div>
          <div className="absolute inset-0 bg-primary flex justify-center items-center">
            <FaCalendarAlt size={30} color="white" />
          </div>
        </div>
      </HeaderWrapper>

      {/* Sticky Tabs */}
      <Box
        sx={{
          position: "sticky",
          top: 50,
          backgroundColor: "white",
          zIndex: 99, // Ensures Tabs are above other content
          width: "100%",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Prescribed" />
          <Tab label="Self-Added" />
        </Tabs>
      </Box>

      <div className="flex flex-col items-center px-3 py-3 w-full h-full gap-[1rem]">
        {/* Dynamic date */}

        {/* Medicines list based on selected tab */}
        <div className="w-full flex flex-col items-center gap-[1rem]">
          {selectedTab === 0 && (
            <>
              <div className="w-full ">
                <Alert sx={{ width: "100%", p: 0.2, fontSize: 12 }} severity="info">
                  <p>
                    <b>Note:</b> . Doctor-prescribed medicine.
                  </p>
                </Alert>
              </div>
              {prescribedmedicines.map((medicine) => {
                const times = parseTimeOfDay(medicine.time_of_day);
                return (
                  <div
                    key={medicine.id}
                    className="w-full h-[135px] rounded-[6px] bg-[#FAFAFA] flex justify-center items-center"
                  >
                    <div className="text-[15px] font-semibold text-primary flex flex-col w-full px-[1rem] ">
                      <span className="text-[15px] font-semibold text-black mb-[1rem]">
                        {medicine.medication_name}
                      </span>
                      {times.map((time, idx) => (
                        <div
                          key={idx}
                          className="text-[13px] font-[500] flex items-center mb-[10px]"
                        >
                          <CiClock2 size={15} />
                          <span className="ml-[1rem]">{time}</span>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-center items-center w-full h-full">
                      <img
                        className="w-[70px] h-[70px] object-cover"
                        src={getImageForMedication(medicine.medication_name)}
                        alt={medicine.medication_name}
                      />
                    </div>
                    <div className="flex flex-col h-full justify-between py-[10px] mr-[1rem]">
                      {/* <BiSolidEdit size={24} color="black" /> */}
                      <CiLock size={24} color="black" />
                      <IoIosAlarm size={24} color="#C7C7C782" />
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {/* Placeholder for Self-Added Tab */}
          {selectedTab === 1 && (
            <>
              <div className="w-full ">
                <Alert sx={{ width: "100%", p: 0.2, fontSize: 12 }} severity="info">
                  <p>
                    <b>Note:</b> . User-added medication.
                  </p>
                </Alert>
              </div>
              <div className="w-full flex flex-col items-center gap-[1rem] ">
                {/* Sample data for Self-Added prescribedmedicines */}
                {[...selfAddedMedicines].map((medicine, index) => (
                  <div
                    key={`self-added-${index}`}
                    className="w-full h-[135px] rounded-[6px] bg-[#FAFAFA] flex justify-center items-center"
                  >
                    <div className="text-[15px] font-semibold text-primary flex flex-col w-full px-[1rem] ">
                      <span className="text-[15px] font-semibold text-black mb-[1rem]">
                        {medicine.medication_name}
                      </span>
                      {parseTimeOfDay(medicine.time_of_day).map((time, idx) => (
                        <div
                          key={idx}
                          className="text-[13px] font-[500] flex items-center mb-[10px]"
                        >
                          <CiClock2 size={15} />
                          <span className="ml-[1rem]">{time}</span>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-center items-center w-full h-full">
                      <img
                        className="w-[70px] h-[70px] object-cover"
                        src={getImageForMedication(medicine.medication_name)}
                        alt={medicine.medication_name}
                      />
                    </div>
                    <div className="flex flex-col h-full justify-between py-[10px] mr-[1rem]">
                      <button onClick={() => HandleClick(medicine)}>
                        {" "}
                        <BiSolidEdit size={24} color="black" />
                      </button>
                      {/* <CiLock size={24} color="black" /> */}
                      <IoIosAlarm size={24} color="#C7C7C782" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="absolute bottom-20 right-5 z-[100] ">
                <div onClick={handleNewMedicine}>
                  <Fab className="bg-primary" aria-label="add">
                    <AddIcon className="text-white" />
                  </Fab>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <AddMedicineReminder
        dialogState={dialogState}
        onClose={handleDialogClose}
        handleAddMedicine={handleAddMedicine}
        handleUpdateDrug={handleUpdateDrug}
        handleRemoveDrug={HandleRemove}
      />
    </>
  );
}

export default MedicineReminder;
