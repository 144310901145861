import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const medicineSlice = createSlice({
  name: "medicine",
  initialState: {
    medicine_success: null, // Holds success response or null
    medicine_error: null, // Holds error message or null
  },
  reducers: {
    getMedicineMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    getMedicineMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    addMedicineMethodSuccess: (state, action) => {
      state.success = action.payload;
      state.error = null;
    },
    addaymentMethodFailure: (state, action) => {
      state.error = action.payload;
      state.success = null;
    },
    deleteMedicineMethodSuccess: (state, action) => {
      state.delete_success = action.payload;
      state.delete_error = null;
    },
    deleteMedicineMethodFailure: (state, action) => {
      state.error = action.payload;
      state.delete_success = null;
    },
  },
});

export const {
  getMedicineMethodSuccess,
  getMedicineMethodFailure,
  addMedicineMethodSuccess,
  addMedicineMethodFailure,
  deleteMedicineMethodSuccess,
  deleteMedicineMethodFailure,
} = medicineSlice.actions;

// Asynchronous thunk action for fetching medicine data
export const getPrescribedMedicines = (params) => async (dispatch) => {
  try {
    const response = await axios.get("medical-prescription-medications/prescribed-medication");

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching medicine data
export const getSelfAddedMedicines = (params) => async (dispatch) => {
  try {
    const response = await axios.get("medical-prescription-medications/self-added-medication");

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching medicine data
export const addSelfAddedMedicine = (params) => async (dispatch) => {
  try {
    const response = await axios.post(
      "medical-prescription-medications/self-added-medication",
      params
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching medicine data
export const updateSelfAddedMedicine = (params) => async (dispatch) => {
  try {
    const response = await axios.put(
      "medical-prescription-medications/self-added-medication/" + params.item_id,
      params
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Asynchronous thunk action for fetching medicine data
export const deleteSelfAddedMedicine = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(
      "medical-prescription-medications/self-added-medication/" + id,
      ""
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

export const getPrescriptions = (params) => async (dispatch) => {
  try {
    const response = await axios.get("medical-prescription");

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};
// Asynchronous thunk action for fetching medicine data
export const PurchaseMedicine = (params) => async (dispatch) => {
  try {
    const response = await axios.post("medicines/patient", params);

    return response;
  } catch (error) {
    const errorMessage = error.response?.data || error.message || "An unexpected error occurred";

    throw errorMessage;
  }
};

// Export the name of the slice for use in dynamic importing or combining reducers
export const sliceName = medicineSlice.name;

export default medicineSlice.reducer;
