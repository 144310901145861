import ad from "@medoczen/core/GetFlagByCountryCode/assets/flags/ad.png";
import ae from "@medoczen/core/GetFlagByCountryCode/assets/flags/ae.png";
import af from "@medoczen/core/GetFlagByCountryCode/assets/flags/af.png";
import ag from "@medoczen/core/GetFlagByCountryCode/assets/flags/ag.png";
import ai from "@medoczen/core/GetFlagByCountryCode/assets/flags/ai.png";
import al from "@medoczen/core/GetFlagByCountryCode/assets/flags/al.png";
import am from "@medoczen/core/GetFlagByCountryCode/assets/flags/am.png";
import ao from "@medoczen/core/GetFlagByCountryCode/assets/flags/ao.png";
import aq from "@medoczen/core/GetFlagByCountryCode/assets/flags/aq.png";
import ar from "@medoczen/core/GetFlagByCountryCode/assets/flags/ar.png";
import as from "@medoczen/core/GetFlagByCountryCode/assets/flags/as.png";
import at from "@medoczen/core/GetFlagByCountryCode/assets/flags/at.png";
import au from "@medoczen/core/GetFlagByCountryCode/assets/flags/au.png";
import aw from "@medoczen/core/GetFlagByCountryCode/assets/flags/aw.png";
import ax from "@medoczen/core/GetFlagByCountryCode/assets/flags/ax.png";
import az from "@medoczen/core/GetFlagByCountryCode/assets/flags/az.png";
import ba from "@medoczen/core/GetFlagByCountryCode/assets/flags/ba.png";
import bb from "@medoczen/core/GetFlagByCountryCode/assets/flags/bb.png";
import bd from "@medoczen/core/GetFlagByCountryCode/assets/flags/bd.png";
import be from "@medoczen/core/GetFlagByCountryCode/assets/flags/be.png";
import bf from "@medoczen/core/GetFlagByCountryCode/assets/flags/bf.png";
import bg from "@medoczen/core/GetFlagByCountryCode/assets/flags/bg.png";
import bh from "@medoczen/core/GetFlagByCountryCode/assets/flags/bh.png";
import bi from "@medoczen/core/GetFlagByCountryCode/assets/flags/bi.png";
import bj from "@medoczen/core/GetFlagByCountryCode/assets/flags/bj.png";
import bl from "@medoczen/core/GetFlagByCountryCode/assets/flags/bl.png";
import bm from "@medoczen/core/GetFlagByCountryCode/assets/flags/bm.png";
import bn from "@medoczen/core/GetFlagByCountryCode/assets/flags/bn.png";
import bo from "@medoczen/core/GetFlagByCountryCode/assets/flags/bo.png";
import bq from "@medoczen/core/GetFlagByCountryCode/assets/flags/bq.png";
import br from "@medoczen/core/GetFlagByCountryCode/assets/flags/br.png";
import bs from "@medoczen/core/GetFlagByCountryCode/assets/flags/bs.png";
import bt from "@medoczen/core/GetFlagByCountryCode/assets/flags/bt.png";
import bv from "@medoczen/core/GetFlagByCountryCode/assets/flags/bv.png";
import bw from "@medoczen/core/GetFlagByCountryCode/assets/flags/bw.png";
import by from "@medoczen/core/GetFlagByCountryCode/assets/flags/by.png";
import bz from "@medoczen/core/GetFlagByCountryCode/assets/flags/bz.png";
import ca from "@medoczen/core/GetFlagByCountryCode/assets/flags/ca.png";
import cc from "@medoczen/core/GetFlagByCountryCode/assets/flags/cc.png";
import cd from "@medoczen/core/GetFlagByCountryCode/assets/flags/cd.png";
import cf from "@medoczen/core/GetFlagByCountryCode/assets/flags/cf.png";
import cg from "@medoczen/core/GetFlagByCountryCode/assets/flags/cg.png";
import ch from "@medoczen/core/GetFlagByCountryCode/assets/flags/ch.png";
import ci from "@medoczen/core/GetFlagByCountryCode/assets/flags/ci.png";
import ck from "@medoczen/core/GetFlagByCountryCode/assets/flags/ck.png";
import cl from "@medoczen/core/GetFlagByCountryCode/assets/flags/cl.png";
import cm from "@medoczen/core/GetFlagByCountryCode/assets/flags/cm.png";
import cn from "@medoczen/core/GetFlagByCountryCode/assets/flags/cn.png";
import co from "@medoczen/core/GetFlagByCountryCode/assets/flags/co.png";
import cr from "@medoczen/core/GetFlagByCountryCode/assets/flags/cr.png";
import cu from "@medoczen/core/GetFlagByCountryCode/assets/flags/cu.png";
import cv from "@medoczen/core/GetFlagByCountryCode/assets/flags/cv.png";
import cw from "@medoczen/core/GetFlagByCountryCode/assets/flags/cw.png";
import cx from "@medoczen/core/GetFlagByCountryCode/assets/flags/cx.png";
import cy from "@medoczen/core/GetFlagByCountryCode/assets/flags/cy.png";
import cz from "@medoczen/core/GetFlagByCountryCode/assets/flags/cz.png";
import de from "@medoczen/core/GetFlagByCountryCode/assets/flags/de.png";
import dj from "@medoczen/core/GetFlagByCountryCode/assets/flags/dj.png";
import dk from "@medoczen/core/GetFlagByCountryCode/assets/flags/dk.png";
import dm from "@medoczen/core/GetFlagByCountryCode/assets/flags/dm.png";
import dor from "@medoczen/core/GetFlagByCountryCode/assets/flags/do.png";
import dz from "@medoczen/core/GetFlagByCountryCode/assets/flags/dz.png";
import ec from "@medoczen/core/GetFlagByCountryCode/assets/flags/ec.png";
import ee from "@medoczen/core/GetFlagByCountryCode/assets/flags/ee.png";
import eg from "@medoczen/core/GetFlagByCountryCode/assets/flags/eg.png";
import eh from "@medoczen/core/GetFlagByCountryCode/assets/flags/eh.png";
import er from "@medoczen/core/GetFlagByCountryCode/assets/flags/er.png";
import es from "@medoczen/core/GetFlagByCountryCode/assets/flags/es.png";
import et from "@medoczen/core/GetFlagByCountryCode/assets/flags/et.png";
import fi from "@medoczen/core/GetFlagByCountryCode/assets/flags/fi.png";
import fj from "@medoczen/core/GetFlagByCountryCode/assets/flags/fj.png";
import fk from "@medoczen/core/GetFlagByCountryCode/assets/flags/fk.png";
import fm from "@medoczen/core/GetFlagByCountryCode/assets/flags/fm.png";
import fo from "@medoczen/core/GetFlagByCountryCode/assets/flags/fo.png";
import fr from "@medoczen/core/GetFlagByCountryCode/assets/flags/fr.png";
import ga from "@medoczen/core/GetFlagByCountryCode/assets/flags/ga.png";
import gb from "@medoczen/core/GetFlagByCountryCode/assets/flags/gb.png";
import gd from "@medoczen/core/GetFlagByCountryCode/assets/flags/gd.png";
import ge from "@medoczen/core/GetFlagByCountryCode/assets/flags/ge.png";
import gf from "@medoczen/core/GetFlagByCountryCode/assets/flags/gf.png";
import gg from "@medoczen/core/GetFlagByCountryCode/assets/flags/gg.png";
import gh from "@medoczen/core/GetFlagByCountryCode/assets/flags/gh.png";
import gi from "@medoczen/core/GetFlagByCountryCode/assets/flags/gi.png";
import gl from "@medoczen/core/GetFlagByCountryCode/assets/flags/gl.png";
import gm from "@medoczen/core/GetFlagByCountryCode/assets/flags/gm.png";
import gn from "@medoczen/core/GetFlagByCountryCode/assets/flags/gn.png";
import gp from "@medoczen/core/GetFlagByCountryCode/assets/flags/gp.png";
import gq from "@medoczen/core/GetFlagByCountryCode/assets/flags/gq.png";
import gr from "@medoczen/core/GetFlagByCountryCode/assets/flags/gr.png";
import gs from "@medoczen/core/GetFlagByCountryCode/assets/flags/gs.png";
import gt from "@medoczen/core/GetFlagByCountryCode/assets/flags/gt.png";
import gu from "@medoczen/core/GetFlagByCountryCode/assets/flags/gu.png";
import gw from "@medoczen/core/GetFlagByCountryCode/assets/flags/gw.png";
import gy from "@medoczen/core/GetFlagByCountryCode/assets/flags/gy.png";
import hk from "@medoczen/core/GetFlagByCountryCode/assets/flags/hk.png";
import hm from "@medoczen/core/GetFlagByCountryCode/assets/flags/hm.png";
import hn from "@medoczen/core/GetFlagByCountryCode/assets/flags/hn.png";
import hr from "@medoczen/core/GetFlagByCountryCode/assets/flags/hr.png";
import ht from "@medoczen/core/GetFlagByCountryCode/assets/flags/ht.png";
import hu from "@medoczen/core/GetFlagByCountryCode/assets/flags/hu.png";
import id from "@medoczen/core/GetFlagByCountryCode/assets/flags/id.png";
import ie from "@medoczen/core/GetFlagByCountryCode/assets/flags/ie.png";
import il from "@medoczen/core/GetFlagByCountryCode/assets/flags/il.png";
import im from "@medoczen/core/GetFlagByCountryCode/assets/flags/im.png";
import ind from "@medoczen/core/GetFlagByCountryCode/assets/flags/in.png";
import io from "@medoczen/core/GetFlagByCountryCode/assets/flags/io.png";
import iq from "@medoczen/core/GetFlagByCountryCode/assets/flags/iq.png";
import ir from "@medoczen/core/GetFlagByCountryCode/assets/flags/ir.png";
import is from "@medoczen/core/GetFlagByCountryCode/assets/flags/is.png";
import it from "@medoczen/core/GetFlagByCountryCode/assets/flags/it.png";
import je from "@medoczen/core/GetFlagByCountryCode/assets/flags/je.png";
import jm from "@medoczen/core/GetFlagByCountryCode/assets/flags/jm.png";
import jo from "@medoczen/core/GetFlagByCountryCode/assets/flags/jo.png";
import jp from "@medoczen/core/GetFlagByCountryCode/assets/flags/jp.png";
import ke from "@medoczen/core/GetFlagByCountryCode/assets/flags/ke.png";
import kg from "@medoczen/core/GetFlagByCountryCode/assets/flags/kg.png";
import kh from "@medoczen/core/GetFlagByCountryCode/assets/flags/kh.png";
import ki from "@medoczen/core/GetFlagByCountryCode/assets/flags/ki.png";
import km from "@medoczen/core/GetFlagByCountryCode/assets/flags/km.png";
import kn from "@medoczen/core/GetFlagByCountryCode/assets/flags/kn.png";
import kp from "@medoczen/core/GetFlagByCountryCode/assets/flags/kp.png";
import kr from "@medoczen/core/GetFlagByCountryCode/assets/flags/kr.png";
import kw from "@medoczen/core/GetFlagByCountryCode/assets/flags/kw.png";
import ky from "@medoczen/core/GetFlagByCountryCode/assets/flags/ky.png";
import kz from "@medoczen/core/GetFlagByCountryCode/assets/flags/kz.png";
import la from "@medoczen/core/GetFlagByCountryCode/assets/flags/la.png";
import lb from "@medoczen/core/GetFlagByCountryCode/assets/flags/lb.png";
import lc from "@medoczen/core/GetFlagByCountryCode/assets/flags/lc.png";
import li from "@medoczen/core/GetFlagByCountryCode/assets/flags/li.png";
import lk from "@medoczen/core/GetFlagByCountryCode/assets/flags/lk.png";
import lr from "@medoczen/core/GetFlagByCountryCode/assets/flags/lr.png";
import ls from "@medoczen/core/GetFlagByCountryCode/assets/flags/ls.png";
import lt from "@medoczen/core/GetFlagByCountryCode/assets/flags/lt.png";
import lu from "@medoczen/core/GetFlagByCountryCode/assets/flags/lu.png";
import lv from "@medoczen/core/GetFlagByCountryCode/assets/flags/lv.png";
import ly from "@medoczen/core/GetFlagByCountryCode/assets/flags/ly.png";
import ma from "@medoczen/core/GetFlagByCountryCode/assets/flags/ma.png";
import mc from "@medoczen/core/GetFlagByCountryCode/assets/flags/mc.png";
import md from "@medoczen/core/GetFlagByCountryCode/assets/flags/md.png";
import me from "@medoczen/core/GetFlagByCountryCode/assets/flags/me.png";
import mf from "@medoczen/core/GetFlagByCountryCode/assets/flags/mf.png";
import mg from "@medoczen/core/GetFlagByCountryCode/assets/flags/mg.png";
import mh from "@medoczen/core/GetFlagByCountryCode/assets/flags/mh.png";
import mk from "@medoczen/core/GetFlagByCountryCode/assets/flags/mk.png";
import ml from "@medoczen/core/GetFlagByCountryCode/assets/flags/ml.png";
import mm from "@medoczen/core/GetFlagByCountryCode/assets/flags/mm.png";
import mn from "@medoczen/core/GetFlagByCountryCode/assets/flags/mn.png";
import mo from "@medoczen/core/GetFlagByCountryCode/assets/flags/mo.png";
import mp from "@medoczen/core/GetFlagByCountryCode/assets/flags/mp.png";
import mq from "@medoczen/core/GetFlagByCountryCode/assets/flags/mq.png";
import mr from "@medoczen/core/GetFlagByCountryCode/assets/flags/mr.png";
import ms from "@medoczen/core/GetFlagByCountryCode/assets/flags/ms.png";
import mt from "@medoczen/core/GetFlagByCountryCode/assets/flags/mt.png";
import mu from "@medoczen/core/GetFlagByCountryCode/assets/flags/mu.png";
import mv from "@medoczen/core/GetFlagByCountryCode/assets/flags/mv.png";
import mw from "@medoczen/core/GetFlagByCountryCode/assets/flags/mw.png";
import mx from "@medoczen/core/GetFlagByCountryCode/assets/flags/mx.png";
import my from "@medoczen/core/GetFlagByCountryCode/assets/flags/my.png";
import mz from "@medoczen/core/GetFlagByCountryCode/assets/flags/mz.png";
import na from "@medoczen/core/GetFlagByCountryCode/assets/flags/na.png";
import nc from "@medoczen/core/GetFlagByCountryCode/assets/flags/nc.png";
import ne from "@medoczen/core/GetFlagByCountryCode/assets/flags/ne.png";
import nf from "@medoczen/core/GetFlagByCountryCode/assets/flags/nf.png";
import ng from "@medoczen/core/GetFlagByCountryCode/assets/flags/ng.png";
import ni from "@medoczen/core/GetFlagByCountryCode/assets/flags/ni.png";
import nl from "@medoczen/core/GetFlagByCountryCode/assets/flags/nl.png";
import no from "@medoczen/core/GetFlagByCountryCode/assets/flags/no.png";
import np from "@medoczen/core/GetFlagByCountryCode/assets/flags/np.png";
import nr from "@medoczen/core/GetFlagByCountryCode/assets/flags/nr.png";
import nu from "@medoczen/core/GetFlagByCountryCode/assets/flags/nu.png";
import nz from "@medoczen/core/GetFlagByCountryCode/assets/flags/nz.png";
import om from "@medoczen/core/GetFlagByCountryCode/assets/flags/om.png";
import pa from "@medoczen/core/GetFlagByCountryCode/assets/flags/pa.png";
import pe from "@medoczen/core/GetFlagByCountryCode/assets/flags/pe.png";
import pf from "@medoczen/core/GetFlagByCountryCode/assets/flags/pf.png";
import pg from "@medoczen/core/GetFlagByCountryCode/assets/flags/pg.png";
import ph from "@medoczen/core/GetFlagByCountryCode/assets/flags/ph.png";
import pk from "@medoczen/core/GetFlagByCountryCode/assets/flags/pk.png";
import pl from "@medoczen/core/GetFlagByCountryCode/assets/flags/pl.png";
import pm from "@medoczen/core/GetFlagByCountryCode/assets/flags/pm.png";
import pn from "@medoczen/core/GetFlagByCountryCode/assets/flags/pn.png";
import pr from "@medoczen/core/GetFlagByCountryCode/assets/flags/pr.png";
import ps from "@medoczen/core/GetFlagByCountryCode/assets/flags/ps.png";
import pt from "@medoczen/core/GetFlagByCountryCode/assets/flags/pt.png";
import pw from "@medoczen/core/GetFlagByCountryCode/assets/flags/pw.png";
import py from "@medoczen/core/GetFlagByCountryCode/assets/flags/py.png";
import qa from "@medoczen/core/GetFlagByCountryCode/assets/flags/qa.png";
import re from "@medoczen/core/GetFlagByCountryCode/assets/flags/re.png";
import ro from "@medoczen/core/GetFlagByCountryCode/assets/flags/ro.png";
import rs from "@medoczen/core/GetFlagByCountryCode/assets/flags/rs.png";
import ru from "@medoczen/core/GetFlagByCountryCode/assets/flags/ru.png";
import rw from "@medoczen/core/GetFlagByCountryCode/assets/flags/rw.png";
import sa from "@medoczen/core/GetFlagByCountryCode/assets/flags/sa.png";
import sb from "@medoczen/core/GetFlagByCountryCode/assets/flags/sb.png";
import sc from "@medoczen/core/GetFlagByCountryCode/assets/flags/sc.png";
import sd from "@medoczen/core/GetFlagByCountryCode/assets/flags/sd.png";
import se from "@medoczen/core/GetFlagByCountryCode/assets/flags/se.png";
import sg from "@medoczen/core/GetFlagByCountryCode/assets/flags/sg.png";
import sh from "@medoczen/core/GetFlagByCountryCode/assets/flags/sh.png";
import si from "@medoczen/core/GetFlagByCountryCode/assets/flags/si.png";
import sj from "@medoczen/core/GetFlagByCountryCode/assets/flags/sj.png";
import sk from "@medoczen/core/GetFlagByCountryCode/assets/flags/sk.png";
import sl from "@medoczen/core/GetFlagByCountryCode/assets/flags/sl.png";
import sm from "@medoczen/core/GetFlagByCountryCode/assets/flags/sm.png";
import sn from "@medoczen/core/GetFlagByCountryCode/assets/flags/sn.png";
import so from "@medoczen/core/GetFlagByCountryCode/assets/flags/so.png";
import sr from "@medoczen/core/GetFlagByCountryCode/assets/flags/sr.png";
import ss from "@medoczen/core/GetFlagByCountryCode/assets/flags/ss.png";
import st from "@medoczen/core/GetFlagByCountryCode/assets/flags/st.png";
import sv from "@medoczen/core/GetFlagByCountryCode/assets/flags/sv.png";
import sx from "@medoczen/core/GetFlagByCountryCode/assets/flags/sx.png";
import sy from "@medoczen/core/GetFlagByCountryCode/assets/flags/sy.png";
import sz from "@medoczen/core/GetFlagByCountryCode/assets/flags/sz.png";
import tc from "@medoczen/core/GetFlagByCountryCode/assets/flags/tc.png";
import td from "@medoczen/core/GetFlagByCountryCode/assets/flags/td.png";
import tf from "@medoczen/core/GetFlagByCountryCode/assets/flags/tf.png";
import tg from "@medoczen/core/GetFlagByCountryCode/assets/flags/tg.png";
import th from "@medoczen/core/GetFlagByCountryCode/assets/flags/th.png";
import tj from "@medoczen/core/GetFlagByCountryCode/assets/flags/tj.png";
import tk from "@medoczen/core/GetFlagByCountryCode/assets/flags/tk.png";
import tl from "@medoczen/core/GetFlagByCountryCode/assets/flags/tl.png";
import tm from "@medoczen/core/GetFlagByCountryCode/assets/flags/tm.png";
import tn from "@medoczen/core/GetFlagByCountryCode/assets/flags/tn.png";
import to from "@medoczen/core/GetFlagByCountryCode/assets/flags/to.png";
import tr from "@medoczen/core/GetFlagByCountryCode/assets/flags/tr.png";
import tt from "@medoczen/core/GetFlagByCountryCode/assets/flags/tt.png";
import tv from "@medoczen/core/GetFlagByCountryCode/assets/flags/tv.png";
import tw from "@medoczen/core/GetFlagByCountryCode/assets/flags/tw.png";
import tz from "@medoczen/core/GetFlagByCountryCode/assets/flags/tz.png";
import ua from "@medoczen/core/GetFlagByCountryCode/assets/flags/ua.png";
import ug from "@medoczen/core/GetFlagByCountryCode/assets/flags/ug.png";
import um from "@medoczen/core/GetFlagByCountryCode/assets/flags/um.png";
import us from "@medoczen/core/GetFlagByCountryCode/assets/flags/us.png";
import uy from "@medoczen/core/GetFlagByCountryCode/assets/flags/uy.png";
import uz from "@medoczen/core/GetFlagByCountryCode/assets/flags/uz.png";
import va from "@medoczen/core/GetFlagByCountryCode/assets/flags/va.png";
import vc from "@medoczen/core/GetFlagByCountryCode/assets/flags/vc.png";
import ve from "@medoczen/core/GetFlagByCountryCode/assets/flags/ve.png";
import vg from "@medoczen/core/GetFlagByCountryCode/assets/flags/vg.png";
import vi from "@medoczen/core/GetFlagByCountryCode/assets/flags/vi.png";
import vn from "@medoczen/core/GetFlagByCountryCode/assets/flags/vn.png";
import vu from "@medoczen/core/GetFlagByCountryCode/assets/flags/vu.png";
import wf from "@medoczen/core/GetFlagByCountryCode/assets/flags/wf.png";
import ws from "@medoczen/core/GetFlagByCountryCode/assets/flags/ws.png";
import ye from "@medoczen/core/GetFlagByCountryCode/assets/flags/ye.png";
import yt from "@medoczen/core/GetFlagByCountryCode/assets/flags/yt.png";
import za from "@medoczen/core/GetFlagByCountryCode/assets/flags/za.png";
import zm from "@medoczen/core/GetFlagByCountryCode/assets/flags/zm.png";
import zw from "@medoczen/core/GetFlagByCountryCode/assets/flags/zw.png";

// Mapping country codes to their flag SVG imports
const flagMap = {
  ad, // Andorra
  ae, // United Arab Emirates
  af, // Afghanistan
  ag, // Antigua and Barbuda
  ai, // Anguilla
  al, // Albania
  am, // Armenia
  ao, // Angola
  aq, // Antarctica
  ar, // Argentina
  as, // American Samoa
  at, // Austria
  au, // Australia
  aw, // Aruba
  ax, // Åland Islands
  az, // Azerbaijan
  ba, // Bosnia and Herzegovina
  bb, // Barbados
  bd, // Bangladesh
  be, // Belgium
  bf, // Burkina Faso
  bg, // Bulgaria
  bh, // Bahrain
  bi, // Burundi
  bj, // Benin
  bl, // Saint Barthélemy
  bm, // Bermuda
  bn, // Brunei Darussalam
  bo, // Bolivia (Plurinational State of)
  bq, // Bonaire, Sint Eustatius and Saba
  br, // Brazil
  bs, // Bahamas
  bt, // Bhutan
  bv, // Bouvet Island
  bw, // Botswana
  by, // Belarus
  bz, // Belize
  ca, // Canada
  cc, // Cocos (Keeling) Islands
  cd, // Congo (Democratic Republic of the)
  cf, // Central African Republic
  cg, // Congo
  ch, // Switzerland
  ci, // Côte d'Ivoire
  ck, // Cook Islands
  cl, // Chile
  cm, // Cameroon
  cn, // China
  co, // Colombia
  cr, // Costa Rica
  cu, // Cuba
  cv, // Cabo Verde
  cw, // Curaçao
  cx, // Christmas Island
  cy, // Cyprus
  cz, // Czechia
  de, // Germany
  dj, // Djibouti
  dk, // Denmark
  dm, // Dominica
  dor, // Dominican Republic
  dz, // Algeria
  ec, // Ecuador
  ee, // Estonia
  eg, // Egypt
  eh, // Western Sahara
  er, // Eritrea
  es, // Spain
  et, // Ethiopia
  fi, // Finland
  fj, // Fiji
  fk, // Falkland Islands (Malvinas)
  fm, // Micronesia (Federated States of)
  fo, // Faroe Islands
  fr, // France
  ga, // Gabon
  gb, // United Kingdom of Great Britain and Northern Ireland
  gd, // Grenada
  ge, // Georgia
  gf, // French Guiana
  gg, // Guernsey
  gh, // Ghana
  gi, // Gibraltar
  gl, // Greenland
  gm, // Gambia
  gn, // Guinea
  gp, // Guadeloupe
  gq, // Equatorial Guinea
  gr, // Greece
  gs, // South Georgia and the South Sandwich Islands
  gt, // Guatemala
  gu, // Guam
  gw, // Guinea-Bissau
  gy, // Guyana
  hk, // Hong Kong
  hm, // Heard Island and McDonald Islands
  hn, // Honduras
  hr, // Croatia
  ht, // Haiti
  hu, // Hungary
  id, // Indonesia
  ie, // Ireland
  il, // Israel
  im, // Isle of Man
  ind, // India
  io, // British Indian Ocean Territory
  iq, // Iraq
  ir, // Iran (Islamic Republic of)
  is, // Iceland
  it, // Italy
  je, // Jersey
  jm, // Jamaica
  jo, // Jordan
  jp, // Japan
  ke, // Kenya
  kg, // Kyrgyzstan
  kh, // Cambodia
  ki, // Kiribati
  km, // Comoros
  kn, // Saint Kitts and Nevis
  kp, // Korea (Democratic People's Republic of)
  kr, // Korea, Republic of
  kw, // Kuwait
  ky, // Cayman Islands
  kz, // Kazakhstan
  la, // Lao People's Democratic Republic
  lb, // Lebanon
  lc, // Saint Lucia
  li, // Liechtenstein
  lk, // Sri Lanka
  lr, // Liberia
  ls, // Lesotho
  lt, // Lithuania
  lu, // Luxembourg
  lv, // Latvia
  ly, // Libya
  ma, // Morocco
  mc, // Monaco
  md, // Moldova, Republic of
  me, // Montenegro
  mf, // Saint Martin (French part)
  mg, // Madagascar
  mh, // Marshall Islands
  mk, // North Macedonia
  ml, // Mali
  mm, // Myanmar
  mn, // Mongolia
  mo, // Macao
  mp, // Northern Mariana Islands
  mq, // Martinique
  mr, // Mauritania
  ms, // Montserrat
  mt, // Malta
  mu, // Mauritius
  mv, // Maldives
  mw, // Malawi
  mx, // Mexico
  my, // Malaysia
  mz, // Mozambique
  na, // Namibia
  nc, // New Caledonia
  ne, // Niger
  nf, // Norfolk Island
  ng, // Nigeria
  ni, // Nicaragua
  nl, // Netherlands
  no, // Norway
  np, // Nepal
  nr, // Nauru
  nu, // Niue
  nz, // New Zealand
  om, // Oman
  pa, // Panama
  pe, // Peru
  pf, // French Polynesia
  pg, // Papua New Guinea
  ph, // Philippines
  pk, // Pakistan
  pl, // Poland
  pm, // Saint Pierre and Miquelon
  pn, // Pitcairn
  pr, // Puerto Rico
  ps, // Palestine, State of
  pt, // Portugal
  pw, // Palau
  py, // Paraguay
  qa, // Qatar
  re, // Réunion
  ro, // Romania
  rs, // Serbia
  ru, // Russian Federation
  rw, // Rwanda
  sa, // Saudi Arabia
  sb, // Solomon Islands
  sc, // Seychelles
  sd, // Sudan
  se, // Sweden
  sg, // Singapore
  sh, // Saint Helena, Ascension and Tristan da Cunha
  si, // Slovenia
  sj, // Svalbard and Jan Mayen
  sk, // Slovakia
  sl, // Sierra Leone
  sm, // San Marino
  sn, // Senegal
  so, // Somalia
  sr, // Suriname
  ss, // South Sudan
  st, // Sao Tome and Principe
  sv, // El Salvador
  sx, // Sint Maarten (Dutch part)
  sy, // Syrian Arab Republic
  sz, // Eswatini
  tc, // Turks and Caicos Islands
  td, // Chad
  tf, // French Southern Territories
  tg, // Togo
  th, // Thailand
  tj, // Tajikistan
  tk, // Tokelau
  tl, // Timor-Leste
  tm, // Turkmenistan
  tn, // Tunisia
  to, // Tonga
  tr, // Turkey
  tt, // Trinidad and Tobago
  tv, // Tuvalu
  tw, // Taiwan, Province of China
  tz, // Tanzania, United Republic of
  ua, // Ukraine
  ug, // Uganda
  um, // United States Minor Outlying Islands
  us, // United States of America
  uy, // Uruguay
  uz, // Uzbekistan
  va, // Holy See
  vc, // Saint Vincent and the Grenadines
  ve, // Venezuela (Bolivarian Republic of)
  vg, // Virgin Islands (British)
  vi, // Virgin Islands (U.S.)
  vn, // Viet Nam
  vu, // Vanuatu
  wf, // Wallis and Futuna
  ws, // Samoa
  ye, // Yemen
  yt, // Mayotte
  za, // South Africa
  zm, // Zambia
  zw, // Zimbabwe
};

export const GetFlagByCountryCode = (country_code) => {
  if (!country_code) return null;
  const flag = flagMap[country_code?.toLowerCase()];
  return flag || null; // Return null if the flag does not exist
};
