import React from "react";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function HealthAssessmentBoxTab(props) {
  const { totalQuestions = 100, answeredQuestions = 100, image, name } = props;

  // Calculate progress as a percentage
  const progressValue =
    totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;

  // Determine color based on progressValue
  const getColorForProgress = (value) => {
    if (value === 100) return "#98cc5e"; // Green
    if (value >= 30) return "orange";

    return "#dae3dc"; // Grey for 0 or any other lower value
  };

  const progressColor = getColorForProgress(progressValue);

  const medical_category = name?.trim()?.toLowerCase().replace(/\s+/g, "-"); // name to lowercase and replace space with -


  return (
    <Link
      to={"/health-assessment/questionnaire-type"}
      state={{ medical_category, progress: progressValue }}
      className="relative h-[123px] min-w-[170px] rounded-[14px] flex flex-col justify-center items-center shadow-[10px_10px_30px_0px_#cbd5e0]"
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size={80}
          thickness={3}
          variant="determinate"
          value={progressValue}
          sx={{
            color: progressColor, // Use the dynamic color
            position: "absolute",
            borderRadius: "50%",
            boxShadow: "inset 0 0 0 5.5px #d1d1d1",
          }}
        />
        <img
          src={image}
          alt={name}
          style={{
            width: 60,
            height: 60,
            borderRadius: "50%",
            position: "relative",
          }}
        />
      </Box>
      <span className="font-[700] text-[14px] mt-4">{name}</span>
    </Link>
  );
}

export default HealthAssessmentBoxTab;
